import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class MaterialDashboardService {
  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  baseURL_2 = 'https://localhost:44386/api';

  constructor(private http: HttpClient) { }

  ViewStudentCount(YearCode: number, ShiftId: number, CsCode1: number, CsCode2: number) {
    return this.http.get(this.baseURL + '/ViewsStudentCount?YearCode=' + YearCode + '&&ShiftId=' + ShiftId + '&&CsCode1=' + CsCode1 + '&&CsCode2=' + CsCode2)
      .pipe(retry(1), catchError(this.handleError));
  }

  ViewAmountReceivedMonthWise(YearCode: number, ShiftId: number) {
    return this.http.get(this.baseURL + '/ViewAmountReceivedMonthWise?YearCode=' + YearCode + '&&ShiftId=' + ShiftId)
      .pipe(retry(1), catchError(this.handleError));
  }

  GetPaymodeFeeReceived(ShiftId: number, Yearcode: number, PMode: string): Observable<any> {
    sessionStorage.setItem('FirstInt', (parseInt(sessionStorage.getItem('FirstInt')) + 1).toString());
    return this.http.get<any>(this.baseURL + '/GetPaymodeFeeReceived?ShiftId=' + ShiftId + '&&YearCode=' + Yearcode + '&&PMode=' + PMode)
      .pipe(retry(1), catchError(this.handleError));
  }

  GetPaymodeFeeReceivedTT(ShiftId: number, Yearcode: number, PMode: string): Observable<any> {
    debugger;
    sessionStorage.setItem('FirstInt', (parseInt(sessionStorage.getItem('FirstInt')) + 1).toString());
    return this.http.get<any>(this.baseURL + '/GetPaymodeFeeReceivedTT?ShiftId=' + ShiftId + '&&YearCode=' + Yearcode + '&&PMode=' + PMode)
      .pipe(retry(1), catchError(this.handleError));
  }

  ViewStudFeeDetailsDetAllSumChart(YearCode: number, ShiftId: number) {
    return this.http.get(this.baseURL + '/ViewStudFeeDetailsDetAllSumChart?YearCode=' + YearCode + '&&ShiftId=' + ShiftId)
      .pipe(retry(1), catchError(this.handleError));
  }

  

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }  
}
