<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  <title></title>
  <!--<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" />-->
</head>
<body>
  <div class="container">
    <form [formGroup] ="UserForm" (ngSubmit)="onFormSubmit()">
      <div class="row">
        <div class="col-md-10 offset=md-1">
          <div class="row">
            <div class="col-md-7 register-right">
              <h3>User Login</h3>
              <p>Please verify your Authentication</p>
              <div class="register-form">
                <div class="form-group">
                  <input type="text" [(ngModel)]="UserName" formControlName="UserName" class="form-control" placeholder="User Name" />
                </div>
                <br />
                <div class="form-group">
                  <input type="password" [(ngModel)]="UserPassword" formControlName="UserPassword" class="form-control" placeholder="User Password" />
                </div>
                <div class="form-group">
                  <input type="number" [(ngModel)]="Otpverified" formControlName="Otpverified" class="form-control" placeholder="Enter Verify Code" />
                </div>
                <p>Verify Code:  <span style="font-size:large;font-weight:bold">{{randomstringstr}}</span></p>
                <button type="submit" class="btn btn-primary">Login</button>
              </div>
            </div>
          </div>
        </div>        
        <!--<re-captcha (resolved)="resolved($event)" siteKey="6LcOuyYTAAAAAHTjFuqhA52fmfJ_j5iFk5PsfXaU"></re-captcha>-->
      </div>
    </form>
  </div>
</body>
</html>
