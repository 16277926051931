<h3>Student Name Search {{GLBappFormStarEnd}}</h3>
<div class="container">
  <div *ngIf="loading!=''" class="cssload-container">
    <div class="cssload-speeding-wheel"></div>
  </div>
  <div *ngIf="loading!=''" class='fullscreenDiv'>
    <div class="center">Loading...Please Wait</div>
  </div>
  <mat-form-field appearance="standard">
    <label><b>Search by Student Name</b></label>
    <input matInput style="font-size:20px;font-weight:bold" (change)="applyFilterSN($event)" placeholder="" #input>
  </mat-form-field>
  <br />
  <mat-form-field appearance="standard">
    <label><b>Search by Enrolment No.</b></label>
    <input matInput style="font-size:20px;font-weight:bold" (change)="applyFilterSNE($event)" placeholder="" #input>
  </mat-form-field>
  <!--<mat-form-field appearance="standard">
    <label>Search by Enrolment#</label>
    <input matInput (change)="applyFilterEN($event)" placeholder="" #input>
  </mat-form-field>-->
  <div class="mat-elevation-z8">
    <div class="cf" id="pdfTable" #pdfTable>
      <table id="ExampleTable" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="admno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Admno </th>
          <td style="font-size:16px;font-weight:bold" mat-cell *matCellDef="let element"> {{element.admno}} </td>
        </ng-container>

        <!-- Title Column -->
        <ng-container matColumnDef="studentName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 400px"> Student Name </th>
          <td style="font-size:16px;font-weight:bold" mat-cell *matCellDef="let element">
            {{element.studentName}} <br />
            <div *ngIf="element.studRank>=1">
              <h5 style="font-size:16px;color:blue;font-weight:bold;">(Rank: {{element.studRank}})</h5>
            </div>
            <div *ngIf="element.tcFlagn==1">
              <h5 style="font-size:16px;color:red;font-weight:bold;">(Left On: {{element.leftdt | date: 'dd-MM-yyyy'}})</h5>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="gender">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
          <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
        </ng-container>
        <ng-container matColumnDef="fathername">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Father Name </th>
          <td mat-cell *matCellDef="let element"> {{element.fatherName}} </td>
        </ng-container>

        <!-- Category Column -->
        <ng-container matColumnDef="course">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Course </th>
          <td mat-cell *matCellDef="let element"> {{element.course}} </td>
        </ng-container>
        <ng-container matColumnDef="admdt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Adm Dt. </th>
          <td mat-cell *matCellDef="let element"> {{element.admdt | date: 'dd/MM/yyyy' }} </td>
        </ng-container>
        <ng-container matColumnDef="leftdt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Left Dt. </th>
          <td mat-cell *matCellDef="let element"> {{element.leftdt | date: 'dd/MM/yyyy' }} </td>
        </ng-container>
        <ng-container matColumnDef="shift">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Shift </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.shiftId == 1;else conditionNotMet">
              <span style="color:darkred;font-size:20px;font-weight:bold">Morning</span>
            </ng-container>
          </td>
          <ng-template #conditionNotMet>
            <span style="color:darkblue;font-size:20px;font-weight:bold">Evening</span>
          </ng-template>
        </ng-container>
        <ng-container matColumnDef="feepaid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Paid </th>
          <td mat-cell *matCellDef="let element"> {{element.paidAmt}} </td>
        </ng-container>
        <ng-container matColumnDef="copy1">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Copy Enrolment#</th>
          <td mat-cell *matCellDef="let element" (click)="CopyAdmno(element.admno)"><a mat-fab (click)="CopyAdmno(element.admno)">Copy</a></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <!--<tr mat-row [ngClass]="{'make-gold': row.sno == 'Subtotal:'}" *matRowDef="let row; columns: displayedColumns;"></tr>-->
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <br /><br />
    <p span="text-align:center">After clicking the Copy button, simultaneously press the Ctrl+V keyboard keys to Paste the copied Enrolment No. where you want to paste it. </p>
    <!--<mat-paginator [pageSizeOptions]="[15, 45, 60, 75, 90, 105, 120]" showFirstLastButtons></mat-paginator>-->
  </div>
</div>
