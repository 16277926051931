import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})


export class StudentMobileDetpdfService {
  //baseURL = 'https://localhost:5001/api/NStud';
  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  constructor(private http: HttpClient) { }

  //ViewStudLedgerDet(Admno: string, YearCode: Number): Observable<any> {
  ViewStudMobileDet(ShiftId: number, YearCode: number, CSCode: string): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/ViewStudMobileDet?ShiftId=' + ShiftId + '&&YearCode=' + YearCode + '&&CSCode=' + CSCode)
      .pipe(retry(1), catchError(this.handleError));
  }

  GetCourses(): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/GetCourses?YearCode=' + parseInt(sessionStorage.getItem('YearCode')))
      .pipe(retry(1), catchError(this.handleError));
  }


  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
