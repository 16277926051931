<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<head>
  <script type="text/javascript">
    const input = document.querySelector('input');
    input.valueAsDate = new Date();
  </script>
  <style>
    table, th, td {
      border: 1px solid grey;
      border-collapse: collapse;
      padding: 5px;
    }
   /* .mat-radio-button.mat-accent .mat-radio-inner-circle {
      background-color: rgb(66, 134, 244);
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
      border-color: rgb(66, 134, 244);
    }

    .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
      background-color: rgba(66, 134, 244,.26);
    }*/

    /*:host ::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle, .mat-radio-outer-circle {
      border-color: blue !important;*/
      /* Sets outer circle & click effect color */
    /*}

    :host ::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle {
      background-color: blue !important;*/
      /* Sets inner circle color */
    /*}*/
    /*table tr:last-child {
        font-weight: bold;
      }*/

    /*table {
      table-layout: auto;
      width: auto !important;
      font-size: small;
    }*/
  </style>
  <!--<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.2.0/css/bootstrap.min.css" />
  <link rel="stylesheet" href="https://cdn.datatables.net/1.13.4/css/dataTables.bootstrap5.min.css" />-->
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@3.0.0-alpha2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp" crossorigin="anonymous" />
</head>
<body>
  <div class="container">
    <div id="pdfContent" #pdfContent>
      <form [formGroup]="UserForm">
        <h3>Fee Collection Date Wise Summary ({{ShiftStr}}) A/Y {{GLBappFormStarEnd}}</h3>
        <!--<div class="form-check form-check-inline">
    <input mdbRadio=mdbRadio
           class="form-check-input"
           type="radio"
           name="ShiftSelect"
           id="Clear"
           value="9"
           formControlName="ShiftSelect"
           checked={{firstChecked}}
           (change)="radioSelectedN($event.target.value)" />
    <label class="form-check-label" for="Clear"><b> Clear</b></label>
  </div>
  <div class="form-check form-check-inline">
    <input mdbRadio=mdbRadio
           class="form-check-input"
           type="radio"
           name="ShiftSelect"
           id="Old"
           value="1"
           [checked]="false"
           formControlName="ShiftSelect"
           (change)="radioSelectedN($event.target.value)" />
    <label class="form-check-label" for="Morning"><b> Morning</b></label>
  </div>
  <div class="form-check form-check-inline">
    <input mdbRadio=mdbRadio
           class="form-check-input"
           type="radio"
           name="ShiftSelect"
           id="Evening"
           value="2"
           [checked]="false"
           formControlName="ShiftSelect"
           (change)="radioSelectedN($event.target.value)" />
    <label class="form-check-label" for="Evening"><b> Evening</b></label>
  </div>-->
        <!--<mat-radio-group [(ngModel)]="selectedRow" (change)="radioSelected($event)">
    <mat-radio-button [value]="9" checked=checked>Clear</mat-radio-button>
    <mat-radio-button [value]="1">Morning</mat-radio-button>
    <mat-radio-button [value]="2">Evening</mat-radio-button>
  </mat-radio-group>-->
        <!--<div class="form-check form-switch">
    <input mdbCheckbox=mdbCheckbox
           class="form-check-input"
           type="checkbox"
           id="flexSwitchCheckChecked"
           [checked]="true" />
    <label class="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label>
  </div>-->
        <!--<input type="radio" name="gender" value="male" id="male" /><label for="male">Male</label>
        <input type="radio" name="gender" value="female" id="female" /><label for="female"> Female</label>
        <input type="radio" name="gender" value="other" id="other" checked/><label for="other"> Other</label>-->


        <!--<input type="radio" id="clear" name="ShiftSelect" value="9" (change)="radioSelectedN($event.target.value)" checked/>
        <label for="clear">Clear</label>
        <input type="radio" id="morning" name="ShiftSelect" value="1" (change)="radioSelectedN($event.target.value)" />
        <label for="morning">Morning</label>
        <input type="radio" id="evening" name="ShiftSelect" value="2" (change)="radioSelectedN($event.target.value)" />
        <label for="evening">Evening</label>-->
      <div class="radio">
        <input id="clear" type="radio" name="ShiftSelect" value="9" (change)="radioSelectedN($event.target.value)" [checked]="true" />
        <label for="clear">Clear&nbsp;&nbsp;&nbsp;&nbsp;</label>
        <input id="morning" type="radio" name="ShiftSelect" value="1" (change)="radioSelectedN($event.target.value)" />
        <label for="morning">Morning&nbsp;&nbsp;&nbsp;&nbsp;</label>
        <input id="evening" type="radio" name="ShiftSelect" value="2" (change)="radioSelectedN($event.target.value)" />
        <label for="evening">Evening</label>
        <!--<input type="radio" name="gender" value="male" id="male" /><label for="male">Male</label>
  <input type="radio" name="gender" value="female" id="female" /><label for="female"> Female</label>
  <input type="radio" name="gender" value="other" id="other" /><label for="other"> Other</label>-->
      </div>
  <!--<mat-radio-group name="radioOpt1" [(ngModel)]="selectedRowS" (change)="radioSelected($event)">
    <mat-radio-button [checked]="true" [value]="9">Clear </mat-radio-button>
    <mat-radio-button [value]="1">Morning </mat-radio-button>
    <mat-radio-button [value]="2">Evening</mat-radio-button>
  </mat-radio-group>-->
        <br />
        <mat-radio-group name="radioOpt2" [(ngModel)]="selectedOrder" (change)="radioSelectedOrder($event)">
          <mat-radio-button [value]="1">Date Ascending </mat-radio-button>
          <mat-radio-button [checked]="true" [value]="2">Date Descending</mat-radio-button>
        </mat-radio-group>
        <br />
        <mat-radio-group name="radioOpt3" [(ngModel)]="selectedPay" (change)="radioSelectedPay($event)">
          <mat-radio-button [checked]="true" [value]="0">All</mat-radio-button>
          <mat-radio-button [value]="10">SBC Portal</mat-radio-button>
          <mat-radio-button [value]="2">Cash</mat-radio-button>
          <mat-radio-button [value]="1">Cheque</mat-radio-button>
          <mat-radio-button [value]="4">Draft</mat-radio-button>
          <mat-radio-button [value]="3">UPI</mat-radio-button>
          <mat-radio-button [value]="5">RGTS/IMPS/NEFT</mat-radio-button>
        </mat-radio-group>
        <br />
        <mat-radio-group name="radioOpt4" [(ngModel)]="selectedBank" (change)="radioSelectedBank($event)">
          <mat-radio-button [checked]="true" [value]="0">ALL (@SBI+@PNB)</mat-radio-button>
          <mat-radio-button [value]="1">@SBI</mat-radio-button>
          <mat-radio-button [value]="2">@PNB</mat-radio-button>
          <mat-radio-button [value]="9">Cash@GTBIT</mat-radio-button>
        </mat-radio-group>
        <div class="row">
          <!-- Grid column -->
          <div class="col">
            <!-- Default input -->
            Start Date
            <input tabindex="1" type="date" value="{{StartDate}}" [(ngModel)]="StartDate" formControlName="StartDate" class="form-control" required=required style="font-weight: bold;" />
          </div>
          <!-- Grid column -->
          <div class="col">
            <!-- Default input -->
            End Date
            <input tabindex="2" type="date" value="{{EndDate}}" [(ngModel)]="EndDate" formControlName="EndDate" class="form-control" required=required style="font-weight: bold;" />
          </div>
          <div class="col">
            <br />
            <button tabindex="3" type="submit" name="searchfee" (click)="getFeeCollectionDWise()">Search</button>
          </div>
        </div>

        <input type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search..." />
        <!--<img src="https://www.freepnglogos.com/uploads/peacock-png/peacock-png-design-deviantart-2.png">-->
        <table id="data" class="table table-bordered" cellspacing="0" style="width:100%">
          <tr>
            <th> S.No</th>
            <th> Date</th>
            <th> Amount</th>
            <th> View</th>
            <th> Summary</th>
          </tr>
          <tr *ngFor="let feedet of feedetails | filter:searchText | paginate: { itemsPerPage: 16, currentPage: p, totalItems: total }; let i=index">
            <td>{{i+1}}</td>
            <td>{{ feedet.trnno }}<br />{{ feedet.trndt | date:'dd-MM-yyyy'}}</td>
            <td>{{ feedet.paidamt }}</td>
            <td *ngIf="feedet.trndt != NULL"><button style="background-color: green; color: white; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;" tabindex="3" type="submit" name="viewfee" (click)="getFeeCollectionDetail(feedet.trndt)">Detail View</button></td>
            <td *ngIf="feedet.trndt != NULL"><button style="background-color: green; color: white; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;" tabindex="3" type="submit" name="viewfee" (click)="getFeeCollectionSummary(feedet.trndt)">Summary</button></td>
          </tr>
        </table>
      </form></div>      
    </div>    
    <div class="col-md-4">
      <button type="button" class="btn btn-secondary btn-lg btn-block"
              (click)="generatePDF('download')">
        Download Report
      </button>
        <button type="button" class="btn btn-secondary btn-lg btn-block"
                (click)="generatePDF('print')">
          Print Report
        </button>
        <button type="submit" class="btn btn-secondary btn-lg btn-block"
                (click)="generatePDF('open')">
          Open Report
        </button>
      </div>
      <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
    <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js" integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV" crossorigin="anonymous"></script>
</body>
</html>
