<h3>Fee Re-Concile (Opening Dues) ({{GLBappFormStarEnd}})</h3>
<div class="container">
  <div *ngIf="loading!=''" class="cssload-container">
    <div class="cssload-speeding-wheel"></div>
  </div>
  <div *ngIf="loading!=''" class='fullscreenDiv'>
    <div class="center">Loading...Please Wait</div>
  </div>  
  <mat-radio-group [(ngModel)]="selectedRow" (change)="radioSelected()">
    <mat-radio-button [value]="1">Morning</mat-radio-button>
    <mat-radio-button [value]="2">Evening</mat-radio-button>
  </mat-radio-group>  
  <div class="export-container">    
    <button mat-raised-button color="primary" (click)="CreateExcelExport()">Export (Excel)</button>    
  </div>  
</div>
<table id="PrevDuesDet" mat-table [dataSource]="dataSource">
  <b>Fee Re-Concile (Opening Dues) ({{GLBappFormStarEnd}}) {{WhichShift}}</b>
  <!--<mat-form-field appearance="standard">
    <mat-label><b>Fee Re-Concile (Opening Dues) (2023-2024) {{WhichShift}}</b></mat-label>
  </mat-form-field>-->
  <!-- Position Column -->
  <ng-container matColumnDef="sno">
    <th mat-header-cell *matHeaderCellDef>CNo.</th>
    <td mat-cell *matCellDef="let element">{{element.rNo}}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="course">
    <th mat-header-cell *matHeaderCellDef>Course</th>
    <td mat-cell *matCellDef="let element">{{element.course}}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="headname">
    <th mat-header-cell *matHeaderCellDef>Head Name</th>
    <td mat-cell *matCellDef="let element">{{element.headName}}</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="duespending">
    <th mat-header-cell *matHeaderCellDef>Dues Pending</th>
    <td mat-cell *matCellDef="let element">{{element.duesPending}}</td>
  </ng-container>

  <ng-container matColumnDef="paidamt">
    <th mat-header-cell *matHeaderCellDef>Received Amt.</th>
    <td mat-cell *matCellDef="let element">{{element.paidAmt}}</td>
  </ng-container>

  <ng-container matColumnDef="feeadjusted">
    <th mat-header-cell *matHeaderCellDef>Adjusted Amt.</th>
    <td mat-cell *matCellDef="let element">{{element.feeAdjusted}}</td>
  </ng-container>

  <ng-container matColumnDef="balanceamt">
    <th mat-header-cell *matHeaderCellDef>Balance Amt.</th>
    <td mat-cell *matCellDef="let element">{{element.balanceAmt}}</td>
  </ng-container>  
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row [ngClass]="{'make-gold': row.sno == 'Subtotal:'}" *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[150, 300, 450, 600, 750, 900, 1050]" showFirstLastButtons></mat-paginator>

<!--<div class="table">
  <button mat-raised-button color="primary" (click)="CreateExcelExport()">Export (Excel)</button>
  <div id="tblexpt" [innerHTML]="stringhtmlNew"></div>
</div>-->

