import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { LoginUserService } from './login.service';
import { Pipe } from '@angular/core';
import { formatDate, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Observable } from 'ag-charts-community/dist/esm/es6/util/observable';


@Component({
  selector: 'loginuser',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'], 
})

export class LoginUserComponent implements OnInit {
  pipe = new DatePipe('en-US');
  now = Date.now();
  //public getFyYear: any;
  randomstringstr: string;
  todayDate: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  UserForm: any;
  UserName: string = "";
  UserPassword: string = "";
  Otpverified: string = "";
  TodaysDate: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  //resolved(captchaResponse: string) {
  //  console.log(`Resolved captcha with response: ${captchaResponse}`);
  //}
  constructor(private LoginService: LoginUserService, private router: Router) {
    //this.UserName = "Admin01";
    //this.UserPassword = "TKaur1699";
    sessionStorage.setItem('incrementDisplay', '0');
    sessionStorage.setItem('firsttime', "2");
    //sessionStorage.setItem('NbaseURLs', 'https://localhost:5001/api/NStud');
    sessionStorage.setItem('NbaseURLs', 'https://www.eduinst.in/api/NStud');
    sessionStorage.setItem('NbaseURLMail', 'https://www.eduinst.in/api/Mail');
    sessionStorage.setItem('yesPDChave', "0");
  }
  ngOnInit(): void {
    //this.randomstringstr = this.randomString(6, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    this.randomstringstr = this.randomString(6, '0123456789');
    this.UserForm = new FormGroup({
      UserName: new FormControl(null, Validators.required),
      UserPassword: new FormControl(null, Validators.required),
      Otpverified: new FormControl(null, Validators.required),
    });

    this.LoginService.GetLastTranDate().subscribe((TrnDt) => {
      //console.log(UserDet);
      //sessionStorage.setItem('currentdate', TrnDt);
      //console.log(UserDet);
      //console.log("Date Set");
    }, (error) => {
      //console.log(error);
      //console.log("Date Error");
    });

    //debugger;
    //this.LoginService.LoginCheck(this.UserName, this.UserPassword).subscribe((response: any) => {
    //  debugger;
    //  console.log(response);
    //  if (response.fullname != "") {
    //    sessionStorage.setItem('usercode', response[0].userId);
    //    sessionStorage.setItem('uname', response[0].fullname);
    //    sessionStorage.setItem('currentdate', this.todayDate);
    //    //sessionStorage.setItem('uname', 'R Singh');
    //    sessionStorage.setItem('instname', 'Guru Tegh Bahadur Institute of Technology');
    //    sessionStorage.setItem('formname', 'Student Master');
    //    //sessionStorage.setItem('ipaddress', '0.0.0.0');
    //    //sessionStorage.setItem('usercode', '1');
    //    sessionStorage.setItem('YCode', '14');
    //    sessionStorage.setItem('YearCode', '2024');
    //    sessionStorage.setItem('ClientId', '1');
    //    sessionStorage.setItem('iShiftId', '1');
    //    sessionStorage.setItem('ShiftStr', 'Morning');
    //    //window.alert("Login Success");
    //    this.router.navigate(['/material-dashboard'])
    //      .then(() => {
    //        window.location.reload();
    //      });
    //  } else {
    //    //console.log(error);
    //    window.alert("Login Failed Name");
    //  }
    //}, (error) => {
    //  console.log(error);
    //  window.alert("Login Failed");

    //});

    this.LoginService.GetIPAddressPC().subscribe((ipaddress) => {
      //this.IPAddress = ipaddress;
      sessionStorage.setItem('ipaddress', ipaddress);
      sessionStorage.setItem('ipaddress2', ipaddress);      
    }, (error) => {
      console.log(error);
    });
    this.LoginService.ViewPDCTranDet(this.TodaysDate, parseInt(sessionStorage.getItem('YearCode'))).subscribe((PDCretval: any) => {
      if (PDCretval.length == 0) {
        sessionStorage.setItem('yesPDChave', "0");
      } else {
        sessionStorage.setItem('yesPDChave', "1");
      }
    }, (error) => {
      console.log(error);
    });

    //this.LoginService.GetFYDet().subscribe((getFydetails: any) => {
    //  this.getFyYear = getFydetails;
    //}, (error) => {
    //  console.log(error);
    //});
  }



  randomString(length, chars) {
  var result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}


  onFormSubmit() {
    debugger;
    sessionStorage.setItem('usercode', '0');
    sessionStorage.setItem('uname', '');
    const studlogin = this.UserForm.value;
    studlogin.UserName = this.UserName;
    studlogin.UserPassword = this.UserPassword;
    studlogin.Otpverified = this.Otpverified;
    sessionStorage.setItem('firsttime', "2");
    if (this.Otpverified != this.randomstringstr) {
      alert("Verification Code not matched");
      return false;
    }
    this.LoginService.LoginCheck(this.UserName, this.UserPassword, sessionStorage.getItem('ipaddress').toString()).subscribe((response: any) => {
      debugger;
      //console.log(response);
      if (response.fullname != "") {
        //GetDateSetChanged
        //this.LoginService.GetDateSetChanged().subscribe((TrnDt) => {
        //  //console.log(UserDet);
        //  sessionStorage.setItem('currentdate', TrnDt);
        //  //console.log(UserDet);
        //  //console.log("Date Set");
        //}, (error) => {
        //  //console.log(error);
        //  //console.log("Date Error");
        //});
        
        //sessionStorage.setItem('currentdate', response[0].setcurrentDate);
        sessionStorage.setItem('currentdate', this.pipe.transform(response[0].setcurrentDate, 'yyyy-MM-dd'));
        sessionStorage.setItem('usercode', response[0].userId);
        sessionStorage.setItem('uname', response[0].fullname);
        //sessionStorage.setItem('currentdate', this.todayDate);
        //sessionStorage.setItem('uname', 'R Singh');
        sessionStorage.setItem('instname', 'Guru Tegh Bahadur Institute of Technology');
        sessionStorage.setItem('formname', 'Student Master');
        //sessionStorage.setItem('ipaddress', '0.0.0.0');
        //sessionStorage.setItem('usercode', '1');
        sessionStorage.setItem('incrementDisplay', '1');
        sessionStorage.setItem('YCode', '15');
        sessionStorage.setItem('YearCode', '2024');
        sessionStorage.setItem('ClientId', '1');
        sessionStorage.setItem('Startfyr', '2024-04-01');
        sessionStorage.setItem('Endfyr', '2025-03-31');
        sessionStorage.setItem('iShiftId', '1');
        sessionStorage.setItem('ShiftStr', 'Morning');
        //window.alert("Login Success");
        //this.router.navigate(['/pdctransdisp'])
        //  .then(() => {
        //    window.location.reload();
        //  });
        this.router.navigate(['/pdctransdisp'])
          .then(() => {
            //window.location.reload();
          });
      } else {
        window.alert("Login Failed");
      }
    }, (error) => {
      window.alert("Login Failed");
      console.log(error);
    });    
  }

}
