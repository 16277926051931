<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<head>
  <style>
    table, th, td {
      border: 1px solid grey;
      border-collapse: collapse;
      padding: 5px;
    }

      /*table tr:last-child {
        font-weight: bold;
      }*/

    table {
      table-layout: auto;
      width: auto !important;
      font-size: small;
    }
  </style>  
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@3.0.0-alpha2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp" crossorigin="anonymous" />
</head>
<body>
  <div class="container">
    <div id="pdfContent" #pdfContent>
      <h3>Fee Adjusted(By DSGMC) Detail ({{ShiftStr}}) A/Y {{GLBappFormStarEnd}}</h3>
      <mat-radio-group [(ngModel)]="selectedRow" (change)="radioSelected($event)">
        <mat-radio-button [value]="1">Morning</mat-radio-button>
        <mat-radio-button [value]="2">Evening</mat-radio-button>
      </mat-radio-group>
      <input type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search..." />
      <!--<img src="https://www.freepnglogos.com/uploads/peacock-png/peacock-png-design-deviantart-2.png">-->
      <table id="data" class="table table-bordered" cellspacing="0" style="width:100%">
        <tr>
          <th> Enrolment No.</th>
          <th> Student Name</th>
          <th> Father's' Name</th>
          <th> Course</th>
          <th> Trn. No.</th>
          <th> Trn.Date</th>
          <th> Adjusted Amt.</th>
          <th> Remarks</th>
          <th> Action</th>
        </tr>
        <tr *ngFor="let feedet of duesdetails | filter:searchText | paginate: { itemsPerPage: 16, currentPage: p, totalItems: total }; let i=index">
          <td>{{feedet.admno}}</td>
          <td> {{ feedet.studentName }}</td>
          <td> {{ feedet.fatherName }}</td>
          <td>{{ feedet.course }}</td>
          <td>{{ feedet.bankNo }}</td>
          <td>{{ feedet.trnDt | date:'dd-MM-yyyy'}}</td>
          <td>{{ feedet.adjustedAmt }}</td>
          <td>{{ feedet.feeAdjustReason }}</td>
          <td *ngIf="feedet.admno != NULL"><button style="background-color: red; color: white; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;" tabindex="3" type="submit" name="deletefee" (click)="DeleteFeeAdjustedCreated(feedet.bankNo,feedet.yearCode,feedet.admno)">Delete</button></td>
        </tr>
      </table>
    </div>
    <div class="col-md-4">
      <button type="button" class="btn btn-secondary btn-lg btn-block"
              (click)="generatePDF('download')">
        Download Report
      </button>
      <button type="button" class="btn btn-secondary btn-lg btn-block"
              (click)="generatePDF('print')">
        Print Report
      </button>
      <button type="submit" class="btn btn-secondary btn-lg btn-block"
              (click)="generatePDF('open')">
        Open Report
      </button>
    </div>
    <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
    <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js" integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV" crossorigin="anonymous"></script>
  </div>  
</body>
</html>
