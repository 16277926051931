import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FeeReConcileService {

  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  baseURL_2 = 'https://localhost:44386/api';

  constructor(private httpClient: HttpClient) { }

  feereConciliationDetail(YearCode: number, ShiftId: number,SelectW: number) {
    debugger;
    return this.httpClient.get(this.baseURL + '/feereConciliationDetail?YearCode=' + YearCode + '&&ShiftId=' + ShiftId + '&&SelectW=' + SelectW);
  }  
  
}
