import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PdcTransPdfService {

  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  baseURL_2 = 'https://localhost:44386/api';

  constructor(private httpClient: HttpClient) {
    //sessionStorage.setItem('NbaseURLs', 'https://localhost:5001/api/NStud');
    sessionStorage.setItem('NbaseURLs', 'https://www.eduinst.in/api/NStud');
  }
  //sessionStorage.setItem('Paymode', 'T');
  //      sessionStorage.setItem('Paymodestr', 'RGTS/IMPS/NEFT');
  ViewPDCTranDetPdf(YearCode: number) {
    debugger;
    return this.httpClient.get(this.baseURL + '/ViewPDCTranDetPdf?YearCode=' + YearCode);
  }

  DeletePDCCreated(TId: string, Yearcode: number, Admno: string, CodeChk: number)
  {
    debugger;
    return this.httpClient.delete(this.baseURL + '/DeletePDCCreated?TId=' + TId + '&&YearCode=' + Yearcode + '&&Admno=' + Admno + '&&CodeChk=' + CodeChk);
  }

}
