import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OpenDuesCreatedPdfService {

  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  baseURL_2 = 'https://localhost:44386/api';

  constructor(private httpClient: HttpClient) { }
  //sessionStorage.setItem('Paymode', 'T');
  //      sessionStorage.setItem('Paymodestr', 'RGTS/IMPS/NEFT');
  GetDuesCreatedEN(Admno:string) {
    debugger;
    return this.httpClient.get(this.baseURL + '/GetDuesCreatedEN?Admno=' + Admno + '&&YearCode=' + parseInt(sessionStorage.getItem('YearCode')));
  }

  DeleteDuesCreated(TId: number, Yearcode: number, Admno: string)
  {
    debugger;
    return this.httpClient.delete(this.baseURL + '/DeleteDuesCreated?TId=' + TId + '&&YearCode=' + parseInt(sessionStorage.getItem('YearCode')) + '&&Admno=' + Admno);
  }

}
