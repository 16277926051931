<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<head>
  <style>
    table, th, td {
      border: 1px solid grey;
      border-collapse: collapse;
      padding: 5px;
    }

    /*table tr:last-child {
        font-weight: bold;
      }*/

    table {
      table-layout: auto;
      width: auto !important;
      font-size: small;
    }
  </style>
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@3.0.0-alpha2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp" crossorigin="anonymous" />
</head>
<body>
  <div class="container">
    <div id="pdfContent" #pdfContent>
      <h3>PDC Pending (Both Shift)  A/Y {{GLBappFormStarEnd}}</h3>
      <div class="modal-body">
        <table id="data" class="table table-bordered" cellspacing="0" style="width:100%">
          <tr>
            <th>PDC#</th>
            <th>Ch/DD Date</th>
            <th>Ch/DD#</th>
            <th>Amount</th>
            <th>Bank Name</th>            
          </tr>
          <tr *ngFor="let item of PDCTranDet">
            <td style="font-weight:bold;font-size:18px;">{{item.pdcNo}}</td>
            <td *ngIf="item.todayDateYN===0" style="font-weight:bold;font-size:16px;color:green">{{item.chequeDate | date: 'dd/MM/yyyy' }}</td>
            <td *ngIf="item.todayDateYN===1" style="font-weight:bold;font-size:16px;color:red">{{item.chequeDate | date: 'dd/MM/yyyy' }}</td>
            <!--<td style="font-weight:bold;font-size:16px;color:green">{{item.chequeDate | date: 'dd/MM/yyyy' }}</td>-->
            <td style="font-weight:bold;font-size:14px;">{{item.chequeNo}}</td>
            <td align="right" style="font-weight:bold;font-size:14px;">{{item.chequeAmt}}</td>
            <td style="font-weight:bold;font-size:14px;">{{item.bankName}}</td>            
          </tr>
        </table>
        <!--<div class="modal-footer">-->
          <button type="button" class="btn btn-danger"
                  (click)="closePopup()">
            Close
          </button>
        <!--</div>-->
      </div>
    </div>    
    <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js" integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV" crossorigin="anonymous"></script>
  </div>
</body>
</html>



<!-- Default form grid -->
<!--<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  <style>
    .tableNS {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td.tableNS {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr.tableNS {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

      tr.tableNS:nth-child(even) {
        background-color: #dddddd;
      }
  </style>
</head>
<body>
  <div class="container">-->
    <!--<div class="modal"
       tabindex="-1"
       role="dialog"
       [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">PDC Dues</h4>
        </div>-->
    <!--<div class="modal-body">
      <table width="100%" border="1">
        <thead>
          <tr>
            <th>PDC#</th>
            <th>Ch/DD Date</th>
            <th>Ch/DD#</th>
            <th align="right">Amount</th>
            <th>Bank Name</th>
            <th>DD/Cheque</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of PDCTranDet">
            <td>{{item.pdcNo}}</td>
            <td>{{item.chequeDate | date: 'dd/MM/yyyy' }}</td>
            <td>{{item.chequeNo}}</td>
            <td align="right">{{item.chequeAmt}}</td>
            <td>{{item.bankName}}</td>
            <td *ngIf="item.ddYes===0">Cheque</td>
            <td *ngIf="item.ddYes===1">DD</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger"
              (click)="closePopup()">
        Close
      </button>
    </div>-->
    <!--</div>
      </div>
    </div>-->
  <!--</div>
</body>
</html>-->
