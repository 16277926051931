import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
//import { isNull } from '@angular/compiler/src/output/output_ast';


@Injectable({
  providedIn: 'root'
})

export class StudentChangeService {
  userData: string[][];
  //formData: Student = new Student();
  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  baseURL_2 = 'https://localhost:44386/api';
  //constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token'
    }),
  };
  constructor(private formBuilder: FormBuilder,private http: HttpClient) { }

  GetAllStudent() {
        return this.http.get(this.baseURL + '/GetAllStudents');
  }

  getData() {
    return sessionStorage.getItem('uname');
  }

  //////////////GetEmployeesID() {
  //////////////  return this.http.get(this.baseURL_2 + '/Employees/GetEmployeesID');
  //////////////}

  //GetStudentFeeDET(admno: string): Observable<any> {
  //  const TransferObject = JSON.stringify(admno);
  //  return this.http.post<any>(this.baseURL + '/GetStudentFeeDet?Admno=' + admno, TransferObject, this.httpOptions);
  //}

  GetStudentDET(admno: any, YearCode: number, ShiftId: number): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/GetStudentDet?Admno=' + admno + '&&YearCode=' + YearCode + '&&ShiftId=' + ShiftId)
      .pipe(retry(1), catchError(this.handleError));
  }

  GetCourses(): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/GetCourses?YearCode=' + parseInt(sessionStorage.getItem('YearCode')))
      .pipe(retry(1), catchError(this.handleError));
  }

  GetCategory(): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/GetCategory')
      .pipe(retry(1), catchError(this.handleError));
  }

  GetStudentFeeDETAdmnoDues(admno: any): Observable<any> {
    return this.http.get<any>(this.baseURL + '/GetStudentFeeDetAdmnoDues?Admno=' + admno)
      .pipe(retry(1), catchError(this.handleError));
  }

  GetStudentFeeDETAdmnoH(admno: any): Observable<any> {
    return this.http.get<any>(this.baseURL + '/GetStudentFeeDetAdmnoH?Admno=' + admno)
      .pipe(retry(1), catchError(this.handleError));
  }

  OGetStudentFeeDetAdmno(admno: any): Observable<any> {
    return this.http.get<any>(this.baseURL + '/OGetStudentFeeDetAdmno')
      .pipe(retry(1), catchError(this.handleError));
  }

  GetLastTranAdded(): Observable<any> {
    return this.http.get<any>(this.baseURL + '/GetLastStatus')
      .pipe(retry(1), catchError(this.handleError));
  }

  //AddStduentTranFeesDet(_formdata: any[]): Observable<any> {
  //  return this.http.put<any>(this.baseURL_2 + '/PutEmployee111', _formdata, this.httpOptions)
  //}
  //formdata,string[] userInput,string[] userInputW, string UserCodeN, string FormType


  AddStudentMaster(UserForm: any): Observable<any> {
    debugger;
    return this.http.post<any>(this.baseURL + '/AddStudentMaster', UserForm);
  }

  AddStduentTranFeesDet(_formdata: any[], userInput: any[], userInputW: any[], _formtype: string, _usercode: string): Observable<any> {

    return this.http.get<any>(this.baseURL + '/AddStduentTranFeesDet?userInput=' + userInput.toString())
    //return this.http.post<any>(this.baseURL + '/AddStduentTranFeesDet?userInput=' + userInput + '&&userInputW=' + userInputW + '&&UserCodeN=' + _usercode + '&&FormType=' + _formtype, _formdata, this.httpOptions)
    //return this.http.post<any>(this.baseURL + '/AddStduentTranFeesDet?formdata=' + _formdata + '&&userInput=' + userInput + '&&userInputW=' + userInputW + '&&UserCodeN=' + _usercode + '&&FormType=' + _formtype, _formdata, this.httpOptions)
    //return this.http.put<any>(this.baseURL_2 + '/PutStudentFeeDet', _formdata, this.httpOptions)
  }

  CheckEnrolExistYN(AdmNoNew: string, YearCode: number, ShiftId: number): Observable<any> {
    return this.http.get<any>(this.baseURL + '/CheckEnrolExistYN?AdmNoNew=' + AdmNoNew.toString() + '&&YearCode=' + YearCode + '&&ShiftId=' + ShiftId)
  }

  SPChangeStudAdmno(AdmNoOld: string, AdmNoNew: string, IpAddress: string, YearCode: number, ShiftId: number): Observable<any> {
    return this.http.get<any>(this.baseURL + '/SPChangeStudAdmno?AdmNoOld=' + AdmNoOld + '&&AdmNoNew=' + AdmNoNew.toString() + '&&IpAddressN=' + IpAddress.toString() + '&&YearCode=' + YearCode + '&&ShiftId=' + ShiftId)
  }

  GetChangeAllStudents() {
    return this.http.get(this.baseURL + '/GetChangeAllStudents');
  }

  //////////////GetEmployeesDET(id: any): Observable<Employee> {
  //////////////  return this.http.get<Employee>(this.baseURL_2 + '/Employees/GetEmployeesDET?id=' + id)
  //////////////    .pipe(retry(1), catchError(this.handleError));
  //////////////}

  //updateEmployeeDET(id: any, _employee: any): Observable<Employee> {
  //  return this.http.put<Employee>(this.baseURL_2 + '/Employees/PutEmployee?id=' + id, _employee, this.httpOptions)
  //}

  //public updateEmployee(postData: Object) {
  //  let endPoints = "/posts/1"
  //  this.http.put(this.baseURL_2 + '/Employees/PutEmployee?id=' + 1, postData).subscribe(data => {
  //    console.log(data);
  //  });
  //}


  ////////////AddEmployeeDET(updatedData: any[]): Observable<Employee> {
  ////////////  const transferObject = {
  ////////////    "name": "Yogesh Sharma", "age": 29, "designation": "Clerk", "mobileNumber": "7777423659", "c_Code": "C"
  ////////////  }

  ////////////  //const TransferObject = JSON.stringify(updatedData);

  ////////////  return this.http.post<Employee>(this.baseURL_2 + '/Employees/PostEmployee', transferObject, this.httpOptions)
  ////////////    .pipe(
  ////////////      catchError(this.handleError)
  ////////////    )
  ////////////}


  //////////////updateEmployeeDET(id: any, updatedData: any[]): Observable<Employee> {
  //////////////  //const transferObject = {
  //////////////  //  "id": 1, "name": "Mahesh Krs", "age": 19, "designation": "Clerk", "mobileNumber": "9854520000", "c_Code": "D"
  //////////////  //}

  //////////////  const TransferObject = JSON.stringify(updatedData);

  //////////////  return this.http.put<Employee>(this.baseURL_2 + '/Employees/PutEmployee?id2=' + id, TransferObject, this.httpOptions)
  //////////////    .pipe(
  //////////////      catchError(this.handleError)
  //////////////    )
  //////////////}





  //updateEmployeeDET(id: any, _employee: any): Observable<Employee> {
  //  return this.http.put<Employee>(this.baseURL_2 + '/Employees/PutEmployee?id=' + id,
  //  JSON.stringify(_employee),
  //  this.httpOptions
  //  )
  //  .pipe(retry(1), catchError(this.handleError));
  //}

  //updateEmployee(id: any,employee: any): Observable<Employee> {
  //  return this.http
  //    .put<Employee>(this.baseURL_2 + '/Employees/PutEmployee?id=' + id,
  //      JSON.stringify(employee),
  //      this.httpOptions
  //    )
  //    .pipe(retry(1), catchError(this.handleError));
  //}

  //updateEmployee(id: any, employee: any): Observable<Employee> {
  //  return this.http
  //    .put<Employee>(this.baseURL_2 + '/Employees/PutEmployee?id=' + id)     
  //    .pipe(retry(1), catchError(this.handleError));
  //}

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
  //  GetEmployeeDet() {
  //  return this.http.get(this.baseURL_2 + '/Employees/GetAll');
  //}

  //GetEmployeeDet() {
  //  return this.http.get(this.baseURL_2 + '/Employees/Index');

  //  return this.http.get<CategoryService>(this.baseURL_2 + '/Employees/Index');
  //}

  //GetEmployeeDet(): Observable<any[]> {
  //  return this.http.get<any[]>(this.baseURL_2 + '/Employees/GetAll');
  //}


  //GetAllCategory(): Observable<any[]> {
  //  /*return this.http.get<any>(this.baseURL + '/GetAllCategory')*/
  //  return this.http.get(this.baseURL + '/GetAllCategory')
  //    .pipe(map((resp: any) => resp.json()),
  //      //catchError(error => this.throwError(error))
  //    )
  //}
  //throwError(error: any) {
  //  console.error(error);
  //  return Observable.throw(error.json().error || 'Server error');
  //}
  }
