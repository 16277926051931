import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class LoginUserService {
  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  //resolved(captchaResponse: string) {
  //  console.log(`Resolved captcha with response: ${captchaResponse}`);
  //}
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token'
    }),
  };
  constructor(private formBuilder: FormBuilder, private http: HttpClient) {
    //sessionStorage.setItem('NbaseURLs', 'https://localhost:5001/api/NStud');
    sessionStorage.setItem('NbaseURLs', 'https://www.eduinst.in/api/NStud');
  }

  //GetFYDet(): Observable<any> {
  //  debugger;
  //  return this.http.get<any>(this.baseURL + '/GetFYDet')
  //    .pipe(retry(1), catchError(this.handleError));
  //}

  LoginCheck(UserName: string, UserPassword: string, IpAddress: string) {
    debugger;
    return this.http.get(this.baseURL + '/LoginCheck?UserName=' + UserName + '&&UserPassword=' + UserPassword + '&&IpAddress=' + IpAddress);
      //.pipe(retry(1), catchError(this.handleError));
  }

  ViewPDCTranDet(TodayDate: Date, YearCode: number): Observable<any> {
    return this.http.get<any>(this.baseURL + '/ViewPDCTranDet?TodayDate=' + TodayDate + '&&YearCode=' + YearCode)
      .pipe(retry(1), catchError(this.handleError));
  }

  GetDateSetChanged() {
    debugger;
    return this.http.get<any>(this.baseURL + '/GetDateSetChanged');
  }

  GetIPAddressPC(): Observable<any> {
    //debugger;
    return this.http.get<any>(this.baseURL + '/GetIPAddressPC');
  }

  GetLastTranDate() {
    debugger;
    return this.http.get<any>(this.baseURL + '/GetLastTranDate');
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
