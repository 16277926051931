import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FeecollectionService {

  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  baseURL_2 = 'https://localhost:44386/api';

  constructor(private httpClient: HttpClient) { }

  getFeeCollectionDWise() {
    debugger;
    return this.httpClient.get(this.baseURL + '/GetFeeCollectionDateWise');
  }  
}
