import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { OpenDuesCreatedPdfService } from './openduescreatedpdf.service';
import jsPDF from 'jspdf';
import { Router } from '@angular/router';
import { Pipe } from '@angular/core';
import { PageSize } from 'pdfmake/interfaces';
import { formatDate, DatePipe } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'openduescreatedpdf',
  templateUrl: './openduescreatedpdf.component.html'  
})

export class OpenDuesCreatedPdfComponent implements OnInit {
  @ViewChild('pdfContent') pdfContent: ElementRef;
  pipe = new DatePipe('en-US');
  now = Date.now();
  duesdetails: any;
  SearchName: string;
  selectedRow: number;
  htmlArray: any[];
  searchText: string = '';
  FileNameStr: string = '';
  //countArray: number;
  totalcolumnssum: Array<9>;
  p: number = 1;
  total: number = 0;
  total1: number = 0;
  forDate: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  forPrintDate: any = this.pipe.transform(this.now, 'dd-MM-yyyy');
  forDates: any = this.pipe.transform(this.now, 'dd-MM-yyyy');
  stringhtml: any;
  stringhtml2: any;
  strhtml: any[];
  strhtml2: any[];
  ShiftId: number = parseInt(sessionStorage.getItem('iShiftId'));
  ShiftStr: string = sessionStorage.getItem('ShiftStr');
  YearCode: number = parseInt(sessionStorage.getItem('YearCode'));
  YearCodeC: number = Number(this.YearCode) + 1;
  strHead: string = '';
  constructor(private service: OpenDuesCreatedPdfService, private router: Router) {
    this.total1 = 0;
    this.selectedRow = 1;
  }

  DeleteDuesCreated(TId: number, YearCode: number, Admno: string)
  {
    debugger;
    if (parseInt(sessionStorage.getItem('YesTran')) == 9) {
      Swal.fire({
        html: "<strong>Do some Action? Please change to current F/Year.</strong>",
        icon: 'info',
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
          //this.router.navigate(['/createdues'])
          //  .then(() => {
          //    window.location.reload();
          //  });
          //this.ngOnInit();
        }
      })
      return false;
    }
    Swal.fire({
      //position: 'top-end',        
      html: "<strong><H3>Are sure you want to delete this Dues Created?</H3></strong>",
      showCancelButton: true,
      icon: 'question',
      width: 400,
      padding: 50,
      //className: "green-bg",
      confirmButtonColor: "#338921",
      confirmButtonText: 'Yes',
      cancelButtonColor: "#F6122E",
      cancelButtonText: 'No'
    }).then((result: { value: any; }) => {
      if (result.value) {
        Swal.fire({
          html: "<strong><H3>Final Confirmation Dues Created Deletion?</H3></strong>",
          showCancelButton: true,
          icon: 'question',
          width: 400,
          padding: 50,
          //className: "green-bg",
          confirmButtonColor: "#338921",
          confirmButtonText: 'Yes',
          cancelButtonColor: "#F6122E",
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.value) {
            this.service.DeleteDuesCreated(TId, YearCode, Admno).subscribe((response: any) => {
              Swal.fire({
                html: "<strong>Deleted successfully</strong>",
                icon: 'info',
                showConfirmButton: true,
                confirmButtonColor: "#338921",
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.value) {
                  this.GetDuesDetails();
                }
              })
            }, (error) => {
              console.log(error);
            });
          }
        })
      }
    });

    //if (window.confirm('Are sure you want to delete this Dues Created?')) {
    //  if (window.confirm('Final Confirmation Dues Created Deletion?')) {
    //    this.service.DeleteDuesCreated(TId, YearCode, Admno).subscribe((response: any) => {
    //      this.GetDuesDetails();
    //    }, (error) => {
    //      console.log(error);
    //    });
    //  }
    //}
  }

  //radioSelected(event: any) {
  //  this.selectedRow = event.value;
  //  sessionStorage.setItem('ShiftStr', 'Morning');
  //  if (event.value == 2) { sessionStorage.setItem('ShiftStr', 'Evening'); }
  //  sessionStorage.setItem('iShiftId', event.value);
  //  this.ShiftStr = sessionStorage.getItem('ShiftStr');
  //  this.ngOnInit();
  //}

  ngOnInit() {
    debugger;
    if (parseInt(sessionStorage.getItem('usercode')) == 0 || sessionStorage.getItem('usercode') == null) {
      this.router.navigate(['/logoutuser'])
        .then(() => {
          window.location.reload();
        });
      return;
    }
    
    //this.GetDuesCreated();

  }

  applyFilterSNE(event: Event) {
    debugger;
    const filterValue = (event.target as HTMLInputElement).value;
    this.SearchName = filterValue.trim();
    this.GetDuesDetails();
  }

  GetDuesDetails()
  {
    this.service.GetDuesCreatedEN(this.SearchName).subscribe((response) => {
      this.duesdetails = response;
    }, (error) => {
      console.log(error);
    });
  }

  //GetDuesCreated() {
  //  debugger;
  //  this.service.GetDuesCreatedEN(this.YearCode, this.selectedRow).subscribe((response) => {
  //    this.duesdetails = response;
  //    //console.log(StudentDD);      
  //    //  this.studService.GetLastTranAdded().subscribe((StudentLastTran) => {
  //    //    this.StudentLastTran = StudentLastTran;
  //    //    //console.log('StudentLastTran', this.StudentLastTran);
  //    //  }, (error) => {
  //    //    //console.log(error);
  //    //  });
  //  }, (error) => {
  //    console.log(error);
  //  });
    
  //}

  //pageChangeEvent(event: number) {
  //  this.p = event;
  //  this.GetDuesCreated();
  //}
}
