<!DOCTYPE HTML>
<html>
<head>
  <script src="https://cdn.canvasjs.com/canvasjs.min.js"></script>
</head>
<body>
  <div class="grid-container">
    <div class="cards-list">
      <div class="card 1">
        <div class="card_image">
          <img src="{{Image1}}" />
        </div>
        <div class="card_title title-white">
          <p *ngFor="let item1 of GetStudentCount1; let i=index">
            {{ item1.course }}: {{ item1.studentCount}}<br />
          </p>
            Total:{{TotalYear1}}
        </div>
      </div>      
      <div class="card 3">
        <div class="card_image">
          <img src="{{Image2}}" />
        </div>
        <!--https://mta.sa/wp-content/uploads/2017/09/SeattelComputerGuyTEST400.gif-->
        <div class="card_title title-white">          
            <p *ngFor="let item2 of GetStudentCount2; let i=index">
              {{ item2.course }}: {{ item2.studentCount}}<br />
            </p>
            Total:{{TotalYear2}}          
        </div>
      </div>

      <div class="card 4">
        <div class="card_image">
          <img src="{{Image3}}" />
        </div>
        <div class="card_title title-white">          
            <p *ngFor="let item3 of GetStudentCount3; let i=index">
              {{ item3.course }}: {{ item3.studentCount}}<br />
            </p>
            Total:{{TotalYear3}}          
        </div>
      </div>
      <div class="card 5">
        <div class="card_image">
          <img src="{{Image4}}" />
        </div>
        <div class="card_title title-white">
          <p *ngFor="let item4 of GetStudentCount4; let i=index">
            {{ item4.course }}: {{ item4.studentCount}}<br />
          </p>
          Total:{{TotalYear4}}
        </div>
      </div>      
    </div>
    <div class="cards-list">      
      <div class="card 2">
        <div class="card_image">
          <img src="{{Image5}}" />
        </div>
        <div class="card_title title-white">
          <p *ngFor="let itemLE2 of GetStudentCountLE2; let i=index">
            {{ itemLE2.course }}: {{ itemLE2.studentCount}}<br />
          </p>
          Total:{{TotalYearLE2}}
        </div>
      </div>

      <div class="card 3">
        <div class="card_image">
          <img src="{{Image6}}" />
        </div>
        <div class="card_title title-white">
          <p *ngFor="let itemLE3 of GetStudentCountLE3; let i=index">
            {{ itemLE3.course }}: {{ itemLE3.studentCount}}<br />
          </p>
          Total:{{TotalYearLE3}}
        </div>
      </div>
      <div class="card 4">
        <div class="card_image">
          <img src="{{Image7}}" />
        </div>
        <div class="card_title title-white">
          <p *ngFor="let itemLE4 of GetStudentCountLE4; let i=index">
            {{ itemLE4.course }}: {{ itemLE4.studentCount}}<br />
          </p>
          Total:{{TotalYearLE4}}
        </div>
      </div>
    </div>
    <!--<p style="font-size:20px;color:purple;font-weight:bold">Total Students:{{GetStudentCount1[0].studentCount+GetStudentCount1[1].studentCount+GetStudentCount1[2].studentCount+GetStudentCount1[3].studentCount+GetStudentCount1[28].studentCount+GetStudentCount1[29].studentCount+GetStudentCount1[40].studentCount+GetStudentCount1[41].studentCount+GetStudentCount1[4].studentCount+GetStudentCount1[5].studentCount+GetStudentCount1[6].studentCount+GetStudentCount1[7].studentCount+GetStudentCount1[30].studentCount+GetStudentCount1[31].studentCount+GetStudentCount1[8].studentCount+GetStudentCount1[9].studentCount+GetStudentCount1[10].studentCount+GetStudentCount1[11].studentCount+GetStudentCount1[32].studentCount+GetStudentCount1[33].studentCount+GetStudentCount1[12].studentCount+GetStudentCount1[13].studentCount+GetStudentCount1[14].studentCount+GetStudentCount1[15].studentCount+GetStudentCount1[34].studentCount+GetStudentCount1[35].studentCount+GetStudentCount1[16].studentCount+GetStudentCount1[17].studentCount+GetStudentCount1[18].studentCount+GetStudentCount1[19].studentCount+GetStudentCount1[36].studentCount+GetStudentCount1[37].studentCount+GetStudentCount1[20].studentCount+GetStudentCount1[21].studentCount+GetStudentCount1[38].studentCount+GetStudentCount1[39].studentCount+GetStudentCount1[24].studentCount+GetStudentCount1[25].studentCount+GetStudentCount1[26].studentCount+GetStudentCount1[27].studentCount}}</p>-->
    <mat-grid-list cols="2" colHeight="450px" rowHeight="450px">
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <mat-card class="dashboard-card">
          <mat-card-content class="dashboard-card-content">
            <!--<div>Card Content Here</div>-->
            <div [@.disabled]="true">
              <mat-tab-group>
                <mat-tab label="">
                  <ng-template matTabContent>
                    <div id="chartContainer" style="height: 370px; width: 100%; margin:auto;"></div>
                    <!--<canvasjs-chart [options]="columnChartOptions"
                    [styles]="{ width: '100%', height: '360px' }"></canvasjs-chart>-->
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <mat-card class="dashboard-card">
          <mat-card-content class="dashboard-card-content">
            <!--<div>Card Content Here</div>-->
            <div [@.disabled]="false">
              <mat-tab-group>
                <mat-tab label="">
                  <ng-template matTabContent>
                    <div id="PiechartContainer" style="height: 370px; width: 100%; margin:auto;"></div>
                    <canvasjs-chart [options]="pieChartOptions"
                    [styles]="{ width: '100%', height: '360px' }"></canvasjs-chart>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <ag-charts-angular style="height: 50%" [options]="options"></ag-charts-angular>
  <mat-radio-group [(ngModel)]="selectedRow" (change)="radioSelected()">
    <mat-radio-button [value]="1">Morning</mat-radio-button>
    <mat-radio-button [value]="2">Evening</mat-radio-button>
  </mat-radio-group>  
  <div class="form-group">
    <button type="button" name="buttonrefresh" id="buttonrefresh" (click)="OnRefreshChart()" class="btn btn-primary btn-block"> Refresh</button>
  </div> <!-- form-group// -->
</body>
</html>
