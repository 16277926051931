import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
/*import { FormBuilder } from '@angular/forms';*/


@Injectable({
  providedIn: 'root'
})
export class PdcTransDispService {
  userData: string[][];
  //formData: Student = new Student();
  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  baseURL_2 = 'https://localhost:44386/api';
  //constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token'
    }),
  };
  constructor(private formBuilder: FormBuilder, private http: HttpClient) {
    sessionStorage.setItem('NbaseURLs', 'https://www.eduinst.in/api/NStud');
    //sessionStorage.setItem('NbaseURLs', 'https://localhost:5001/api/NStud');
    //this.baseURL = sessionStorage.getItem('NbaseURLs');
  }

  ViewPDCTranDet(TodayDate: Date, YearCode: number): Observable<any> {
    return this.http.get<any>(this.baseURL + '/ViewPDCTranDet?TodayDate=' + TodayDate + '&&YearCode=' + YearCode)
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }  
}
