import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { GlobalComponent } from 'global-component';


@Injectable({
  providedIn: 'root'
})

export class AppService {
  GlobalComponent = new GlobalComponent();  

  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  baseURL_2 = 'https://localhost:44386/api';
  //constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token'
    }),
  };
  constructor(private formBuilder: FormBuilder, private http: HttpClient) {
    //sessionStorage.setItem('NbaseURLs', 'https://localhost:5001/api/NStud');
    sessionStorage.setItem('NbaseURLs', 'https://www.eduinst.in/api/NStud');
    sessionStorage.setItem('NbaseURLMail', 'https://www.eduinst.in/api/Mail');
  }


  GetFYDet(): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/GetFYDet');      
  }

  GetIPAddressPC(): Observable<any> {
    //debugger;
    return this.http.get<any>(this.baseURL + '/GetIPAddressPC');
  }

  //GetLastTranDate() {
  //  debugger;
  //  return this.http.get<any>(this.baseURL + '/GetLastTranDate');
  //}

  //ngOnInit() {
  //  //GlobalComponent.GLBappUserName = sessionStorage.getItem('uname');
  //  //GlobalComponent.GLBappInstName = sessionStorage.getItem('instname');
  //  sessionStorage.setItem('uname', 'R Singh');
  //  sessionStorage.setItem('instname', 'Guru Tegh Bahadur Institute of Technology');
  //  //sessionStorage.setItem('formtype', 'Master Name');

  //  //window.sessionStorage.setItem('user', angular.toJson(user));
  //  //this.selectedEnvironments = JSON.parse(sessionStroage.getItem('envValues');

  //  //let myObj = { uname: 'R Singh', fyear: '2022-2023', instname: 'Guru Tegh Bahadur Institute of Technology' };

  //  //localStorage.setItem(this.KeyAny, JSON.stringify(myObj));
  //}

  //SetComplexDataS(): Observable<GlobalComponent> {
  //  return this.http.get<GlobalComponent>(this.baseURL + '/SetComplexDataW')
  //    .pipe(retry(1));
  //}

  //GetComplexDataS(): Observable<GlobalComponent> {
  //  return this.http.get<GlobalComponent>(this.baseURL + '/GetComplexDataW')
  //    .pipe(retry(1));
  //}
}
