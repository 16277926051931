import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class IndividualStudentLedgerpdfService {
  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  constructor(private http: HttpClient) { }

  //ViewStudLedgerDet(Admno: string, YearCode: Number): Observable<any> {
  ViewStudLedgerDetIndividual(YearCode: number, Admno: string): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/ViewStudFeeDetailsDetAllsIndividual?YearCode=' + YearCode + '&&Admno=' + Admno)
    .pipe(retry(1), catchError(this.handleError));
  }

  DeleteStudentFeeTranAll(MaxRNo: string, Yearcode: number) {
    return this.http.delete(this.baseURL + '/DeleteStudentFeeTranAll?Admno=' + MaxRNo + '&&Yearcode=' + Yearcode);
  }

  ViewStudLedgerDetSumIndividual(YearCode: number, Admno: string): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/ViewStudFeeDetailsDetAllSumIndividual?YearCode=' + YearCode + '&&Admno=' + Admno)
      .pipe(retry(1), catchError(this.handleError));
  }

  GetCourses(): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/GetCourses?YearCode=' + parseInt(sessionStorage.getItem('YearCode')))
      .pipe(retry(1), catchError(this.handleError));
  }

  ViewStudFeeDetailsDet(Admno: string,YearCode: Number): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/ViewStudFeeDetailsDet?Admno=' + Admno + '&&YearCode=' + YearCode);
  }
  ViewStudFeeDetailsDetAll(): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/ViewStudFeeDetailsDetAll')
    .pipe(retry(1), catchError(this.handleError));
  }
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
