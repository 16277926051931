import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateDuesService } from '../createdues/createdues.service';
import { formatDate, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Pipe } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js'

//@Pipe({
//  name: 'dateFormatPipe',
//})
//import { FormBuilder, Validators } from '@angular/forms';
//import { MatRadioModule } from '@angular/material/radio';
/*import { Student } from './shared/student';*/

@Component({
  selector: 'createdues',
  templateUrl: `./createdues.component.html`,
  styleUrls: ['css/dp-light.css', 'createdues.component.css'],
})
export class CreateDuesComponent implements OnInit {
  @ViewChild('noofinstallments') inputName;
  pipe = new DatePipe('en-US');
  now = Date.now();
  admno: any;
  leftOn: string;
  DrawnAt: string = '';
  ChequeNo: string;
  BankAddress: string = 'NEW DELHI';
  //noofdays: number = 0;
  //noofinstallments: number = 0;
  //ArrearTotal: Number;
  //FeesTotal: Number;
  todayDate: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  StudentDD: any;
  EmployeeDD: any;
  Student: any = [];
  StudentH: any;
  StudentShift: any ;
  StudentH2: any;
  AllBoxes: number = 0;
  StudentLastTran: any;
  WaivedOffAmt: number = 0;
  WaivedOffAmtD: number = 0;
  LessPaidAmt: number = 0;
  LessPaidAmtD: number = 0;
  LessPaidAmtF: number = 0;
  WaivedOffAmtF: number = 0;
  LessAmtHeadWise: string;
  LessAmtHeadWiseD: string;
  WaivedAmtHeadWise: string;
  WaivedAmtHeadWiseD: string;
  StudentDues: any;
  UserData01: any[];
  UserForm: FormGroup;
  userData: string[] = new Array();
  userData3: any[] = new Array();
  userData2: any[];
  userInputDues: any[];
  userInput: any[]; // = new Array(15);
  userInputW: any[] = new Array(15);
  userInputD: any[]; // = new Array(15);
  userInputWD: any[] = new Array(15);
  //userInput = Array<{ id: number; name: string }>;
  lastkeydown1: number = 0;
  subscription: any;
  IndexR: number = 0;
  AmountToPay: number = 0;
  AmountToPayC: number = 0;
  AmountToPayCC: number = 0;
  AmountToPayCM: number = 0;
  AmountLFine: number = 0;
  userList1: any[15];
  stringforward: any[];
  STUDADMNO: string = "";
  GLBappFormType: string = "";
  GLBappUserCode: string = "";
  UserName: any;
  public EmployeeList: Array<any> = [];
  date: string = '';
  GLBappFormName: string = "";
  GLBappFormStarEnd: string = "";
  isChecked: boolean = false;
  //NextDate1: any[];
  YesOpDueBal: boolean = false;
  public NextDate1: Array<any> = [];
  public NextPayment1: Array<any> = [];
  NextDateString: string = "";
  MISB: number;
  isOnlyArrear: boolean = false;
  isFeeAndArrear: boolean = false;
  LessPaitAmtInstall: number;
  InstallmentDateString: string;
  InstallmentAmtString: string;
  ByPaymentMode: number = 0;
  IPAddress: string = '';
  BankTransfer: string = '';
  iHiddenOpBal: boolean; true;
  ChDts: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  constructor(private studService: CreateDuesService, private fb: FormBuilder, private router: Router) {
    this.todayDate = this.pipe.transform(sessionStorage.getItem('currentdate'), 'yyyy-MM-dd');
    this.ChDts = this.pipe.transform(sessionStorage.getItem('currentdate'), 'yyyy-MM-dd');
    this.StudentShift = "";
    this.iHiddenOpBal = true;
    this.LessPaidAmtF = 0;
    this.WaivedOffAmtF = 0;
    this.LessPaidAmt = 0;
    this.leftOn = "";
    this.AllBoxes = 0;
    this.YesOpDueBal = false;
    this.LessPaidAmtD = 0;
    this.WaivedAmtHeadWise = "";
    this.WaivedAmtHeadWiseD = "";
    this.STUDADMNO = "";
    this.ByPaymentMode = 10;
    this.LessAmtHeadWise = "";
    this.LessAmtHeadWiseD = "";
    this.isOnlyArrear = false;
    this.InstallmentDateString = "";
    this.InstallmentAmtString = "";    
    this.IPAddress = sessionStorage.getItem('ipaddress2');
    this.MISB = 1;
  }

  onClickedAtBank(event: any) {
    debugger;
    this.MISB = 1;
    switch (event) {
      case 'atpnb':
        {
          this.MISB = 2;
          break;
        }
      case 'bycashgtbit':
        {
          this.MISB = 9;
          break;
        }
      default:
        this.MISB = 1;
        break;
    }
  }


  onClickedPayment(event: any) {    
    this.ByPaymentMode = 10;
    this.iHiddenOpBal = true;
    switch (event) {
      case 'noreceipt':
        {
          this.iHiddenOpBal = false;
          this.ByPaymentMode = 0;
          this.BankTransfer = '';
          this.DrawnAt = '';
          this.BankAddress = 'New Delhi';
          this.ChDts = this.todayDate;
          break;
        }
      case 'bysbcportal':
        {
          this.ByPaymentMode = 10;
          this.BankTransfer = 'DUL';
          this.DrawnAt = '';
          this.BankAddress = 'New Delhi';
          this.ChDts = this.todayDate;
          break;
        }
      case 'bycashgtbit':
        {
          this.ByPaymentMode = 9;
          this.BankTransfer = '';
          this.DrawnAt = 'Cash@GTBIT';
          this.BankAddress = 'New Delhi';
          this.ChDts = this.todayDate;
          break;
        }
      case 'bycash':
        {
          this.ByPaymentMode = 2;
          this.BankTransfer = '';
          this.DrawnAt = 'PNB-Cash';
          this.BankAddress = 'New Delhi';
          this.ChDts = this.todayDate;
          break;
        }
      case 'byupi':
        {
          this.ByPaymentMode = 3;
          this.ChDts = this.todayDate;
          this.BankTransfer = 'UPI';
          this.DrawnAt = 'SBI';
          this.BankAddress = 'New Delhi';
          break;
        }
      case 'bydraft':
        {
          this.ByPaymentMode = 4;
          this.BankTransfer = ''
          this.ChDts = this.todayDate;
          this.BankAddress = 'New Delhi';
          break;
        }
      case 'bytransfer':
        {
          this.BankTransfer = 'NEFT';
          this.ByPaymentMode = 5;
          this.ChDts = this.todayDate;
          this.DrawnAt = 'SBIC';
          this.BankAddress = 'New Delhi';
          break;
        }
      default:
        this.ByPaymentMode = 1;
        //this.BankTransfer = '';
        this.BankAddress = 'New Delhi';
        this.ChDts = this.todayDate;
        break;
    }
  }

  

  ngOnInit() {
    //debugger;
    if (parseInt(sessionStorage.getItem('usercode')) == 0 || sessionStorage.getItem('usercode') == null) {
      this.router.navigate(['/logoutuser'])
        .then(() => {
          window.location.reload();
        });
      return;
    }
    this.LessPaidAmt = 0;
    this.LessPaidAmtD = 0;
    this.ByPaymentMode = 10;
    this.MISB = 1;
    this.UserForm = new FormGroup({
      AId: new FormControl(null, Validators.required),
      TodayDate: new FormControl(null, Validators.required),
      Admno: new FormControl(null, Validators.required),
      ByPamentMode: new FormControl(1, Validators.required),
      MISB: new FormControl(1, Validators.required),
      BankNo: new FormControl(null, Validators.required),
      LateFine: new FormControl(0, Validators.required),
      TotalAmt: new FormControl(0, Validators.required),
      ChNo: new FormControl(null, Validators.required),
      ChDt: new FormControl(null, Validators.required),
      BankName: new FormControl(null, Validators.required),
      DrawnAt: new FormControl(null, Validators.required),
      BankAddress: new FormControl(null, Validators.required),
      OnlyArrear: new FormControl(false, Validators.required),
      FeeInstallment: new FormControl(null, Validators.required),
      FeeInstallmentAmt: new FormControl(null, Validators.required),
      LessHeadWiseAmount: new FormControl(null, Validators.required),
      WaviedHeadWiseAmount: new FormControl(null, Validators.required),
      LessHeadWiseDuesAmount: new FormControl(null, Validators.required),
      WaviedHeadWiseDuesAmount: new FormControl(null, Validators.required),
      IpAddress: new FormControl(null, Validators.required),
      YesOpDueBal: new FormControl(false, Validators.required),      
    });
    sessionStorage.setItem('formtype', '1');
    this.GLBappFormType = sessionStorage.getItem('formtype');
    sessionStorage.setItem('formname', 'Fast Tr/CDues Tr');
    this.GLBappFormName = sessionStorage.getItem('formname');
    this.GLBappUserCode = sessionStorage.getItem('usercode');
    this.GLBappFormStarEnd = sessionStorage.getItem('FYYear');

    //this.studService.GetAllStudent().subscribe((StudentDD) => {
    //  this.StudentDD = StudentDD;      
      
    //}, (error) => {
    //  //console.log(error);
    //});

    this.studService.GetLastTranAdded().subscribe((StudentLastTran) => {
      this.StudentLastTran = StudentLastTran;
      //console.log('StudentLastTran', this.StudentLastTran);
    }, (error) => {
      //console.log(error);
    });

  }

  onFormSubmit() {
    //Admno: 431915
    debugger;
    if (this.AmountToPayC == 0) {
      Swal.fire({
        html: "<strong>No Amount entered for Dues</strong>",
        icon: 'info',
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {

        }
      })
      //window.alert("No Amount entered for Dues");
      return false;
    }
    this.LessAmtHeadWise = "";
    const studfee = this.UserForm.value;
    studfee.AId = Number(this.Student[0].aid);
    studfee.Admno = this.STUDADMNO;
    studfee.ByPamentMode = this.ByPaymentMode;
    studfee.LessHeadWiseAmount = this.LessAmtHeadWise;
    studfee.WaviedHeadWiseAmount = this.WaivedAmtHeadWise;
    studfee.LessHeadWiseDuesAmount = this.LessAmtHeadWiseD;
    studfee.WaviedHeadWiseDuesAmount = this.WaivedAmtHeadWiseD;
    studfee.OnlyArrear = this.isOnlyArrear;
    studfee.FeeInstallment = this.InstallmentDateString;
    studfee.FeeInstallmentAmt = this.InstallmentAmtString;
    studfee.IpAddress = this.IPAddress;
    studfee.MISB = this.MISB;
    studfee.YesOpDueBal = this.YesOpDueBal;

    if (parseInt(sessionStorage.getItem('YesTran')) == 9) {
      Swal.fire({
        html: "<strong>Do some Action? Please change to current F/Year.</strong>",
        icon: 'info',
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'        
      }).then((result) => {
        if (result.value) {
          //this.router.navigate(['/createdues'])
          //  .then(() => {
          //    window.location.reload();
          //  });
          //this.ngOnInit();
        }
      })
      return false;
    }

    Swal.fire({
      //position: 'top-end',        
      html: "<strong><H3>Do you want to Save the Record?</H3></strong>",
      showCancelButton: true,
      icon: 'question',
      //iconHtml: '<img src="https://picsum.photos/100/100">',
      width: 400,
      padding: 50,
      //className: "green-bg",
      confirmButtonColor: "#338921",
      confirmButtonText: 'Yes',
      cancelButtonColor: "#F6122E",
      cancelButtonText: 'No'
    }).then((result: { value: any; }) => {
      if (result.value) {
        //window.alert("hello");          
        this.studService.AddStduentTranFeesDues(studfee).subscribe(() => {
          Swal.fire({
            html: "<strong>Submitted successfully</strong>",
            icon: 'success',
            showConfirmButton: true,
            confirmButtonColor: "#338921",
            confirmButtonText: 'OK'
            //background: '#fff url(//bit.ly/1Nqn9HU)',
            //timer: 300000
          }).then((result) => {
            if (result.value) {
              this.router.navigate(['/createdues'])
                .then(() => {
                  window.location.reload();
                });
              this.ngOnInit();
            }
          })         
        }, (error) => {
          Swal.fire('Some error:' + error, 'error');
          //console.log(error);
        });
      }
    });


    //this.studService.AddStduentTranFeesDues(studfee).subscribe(() => {            
    //  //window.alert('Submitted successfully');
    //  Swal.fire({
    //    html: "<strong>Submitted successfully</strong>",
    //    icon: 'success',
    //    showConfirmButton: true,
    //    confirmButtonColor: "#338921",
    //    confirmButtonText: 'OK'
    //  }).then((result) => {
    //    if (result.value) {
    //      this.router.navigate(['/createdues'])
    //        .then(() => {
    //          window.location.reload();
    //        });
    //      this.ngOnInit();   
    //    }
    //  })
    //}, (error) => {
    //  console.log(error);
    //});



  }

  onCheckboxChange(event: Event) {
    // get the checked property of the event target
    this.YesOpDueBal = false;
    const checked = event.target['checked'];
    if (checked == true) {
      this.YesOpDueBal = true;
      //window.alert("This will create Dues as April Opening");
      Swal.fire({
        html: "<strong>This will create Dues as April Opening</strong>",
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {

        }
      })
    }
    
    // do something with the checked value
  }


  //DuesCreatedList() {
  //  this.router.navigate(['/duescreatedpdf'])
  //    .then(() => {
  //      window.location.reload();
  //    });
  //}

  public SetGetNullValueStr(parametername: string) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return "NULL";
    }
  }

  public SetGetNullValueInt(parametername: any) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return Number(0);
    }
  }

  public SetGetNullValueDate(parametername: any) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return '1900-01-01';
    }
  }

  onChange(event: any) {
    debugger;
    this.StudentShift = "";
    this.leftOn = "";
    this.ByPaymentMode = 10;
    this.NextDateString = "";
    this.isOnlyArrear = false;
    this.isFeeAndArrear = false;
    this.STUDADMNO = event.target.value;
    //sessionStorage.setItem('YesTran',
    if (this.STUDADMNO.length != 0) {
      this.studService.CreateDuesFeeDet(this.STUDADMNO, parseInt(sessionStorage.getItem('YearCode')), this.todayDate).subscribe((Student) => {
        this.studService.GetStudentFeeDETAdmno(this.STUDADMNO).subscribe((Student) => {
          this.Student = Student;
          if (Number(this.Student[0].iShiftId) == 1) {
            this.StudentShift = "Morning Student";
          } else {
            this.StudentShift = "Evening Student";
          }
          if (Number(this.Student[0].ctr10) == 1) {
            this.leftOn = "(Left On: " + this.pipe.transform(this.Student[0].cDate8, 'dd-MM-yyyy') + ")";
          }
          this.studService.GetStudentFeeDETAdmnoDues(this.STUDADMNO, parseInt(sessionStorage.getItem('YearCode'))).subscribe((StudentDs: any) => {
            this.StudentDues = StudentDs;
            let StudentDDues: any[] = this.StudentDues;
            let count = StudentDDues.length;
            this.userInputDues = new Array(count);
            //for (let n = 0; n < this.userInputDues.length; n++) {
            //  this.userInputDues[n] = 0;
            //}
          }, (error) => {
            console.log(error);
          });
        }, (error) => {
          console.log(error);
        });
      }, (error) => {
        console.log(error);
      });
    }
  }

  keyPresseditamtD(index: any, event: any, event02: any, event03: any, event04: any, event05: any) {
    debugger;
    this.AmountToPayC = 0;
    this.LessAmtHeadWise = "";
    //this.userInputDues[index] = Number(event02.target.value);
    this.IndexR = Number(event05);
    this.studService.UpdateTemps03(this.Student[0].aid, Number(event05), Number(event02.target.value)).subscribe((sumofamount) => {
      this.AmountToPayC = sumofamount.sumduesamt;
      //console.log("Done");
    });

    ////for (let n = 0; n < this.StudentDues.length; n++)
    ////{
    ////  this.lastkeydown1 = Number(this.StudentDues[n].headcode);
    ////  this.AmountToPayC = Number(this.AmountToPayC) + Number(this.userInputDues[n]);
    ////  if (Number(this.lastkeydown1) == Number(this.IndexR)) {
    ////    this.StudentDues[n].headamount = Number(event02.target.value);
    ////    //this.LessAmtHeadWise = this.LessAmtHeadWise + '(' + this.StudentDues[n].headcode + ')' + Number(event02.target.value) + ",";
    ////  }
    ////}    
    return true;
  }  
}
