import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Article } from './article';


//const All_ARTICLES: Article[] = [
//  { id: 1, title: 'Angular 2 Tutorial', category: 'Angular', writer: 'Krishna' },
//  { id: 2, title: 'Angular 6 Tutorial', category: 'Angular', writer: 'Mahesh' },
//  { id: 3, title: 'Spring MVC tutorial', category: 'Spring', writer: 'Aman' },
//  { id: 4, title: 'Spring Boot tutorial', category: 'Spring', writer: 'Suraj' },
//  { id: 5, title: 'FreeMarker Tutorial', category: 'FreeMarker', writer: 'Krishna' },
//  { id: 6, title: 'Thymeleaf Tutorial', category: 'Thymeleaf', writer: 'Mahesh' },
//  { id: 7, title: 'Java 8 Tutorial', category: 'Java', writer: 'Aman' },
//  { id: 8, title: 'Java 9 Tutorial', category: 'Java', writer: 'Suraj' },
//  { id: 9, title: 'Spring MVC tutorial', category: 'Spring', writer: 'Aman' },
//  { id: 10, title: 'Spring Boot tutorial', category: 'Spring', writer: 'Suraj' },
//  { id: 11, title: 'FreeMarker Tutorial', category: 'FreeMarker', writer: 'Krishna' },
//  { id: 12, title: 'Thymeleaf Tutorial', category: 'Thymeleaf', writer: 'Mahesh' },
//  { id: 13, title: 'Java 8 Tutorial', category: 'Java', writer: 'Aman' },
//  { id: 14, title: 'Java 9 Tutorial', category: 'Java', writer: 'Suraj' }
//];

@Injectable({
  providedIn: 'root'
})
export class DuesPaidPendingService {
  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  constructor(private http: HttpClient) { }

  //getAllArticles() {
  //  return All_ARTICLES;
  //ViewStudBalAmtEve}

  ViewStudBalAmt(YearCode: Number): Observable<any> {
    debugger;    
    return this.http.get<any>(this.baseURL + '/ViewStudBalAmt?YearCode=' + YearCode);    
  }
  ViewStudBalAmtEve(YearCode: Number): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/ViewStudBalAmtEve?YearCode=' + YearCode);
  }
}
