<!-- Default form grid -->
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  <script type="text/javascript">
    const input = document.querySelector('input');
    input.valueAsDate = new Date();
  </script>
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>
  <script src="./js/Script.js"></script>
  <script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/angularjs/1.3.9/angular.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.all.min.js"></script>
  <link href="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.min.css" rel="stylesheet" />
  <script type="text/javascript">
    function keyPresseditamt() {
      for (i = 0; i < event.length; i++) {
        window.alert(event[i]);
      }
    }
    function keyPresseditamtD() {
      for (i = 0; i < event.length; i++) {
        window.alert(event[i]);
      }
    }
  </script>
  <style>
    @media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    }

    .div1 {
      width: 60px;
      height: 30px;
      border: 1px solid blue;
    }
  </style>
</head>
<body>
  <div class="container">
    <form [formGroup]="UserForm" (ngSubmit)="onFormSubmit()">
      <div class="row">
        <div class="col">
          <h3>{{GLBappFormName}} {{GLBappFormStarEnd}}</h3>
        </div>
      </div>
      <div class="row">
        <!-- Grid column -->
        <!--<div class="col">-->
          <!-- Default input -->
          <!--Date
          <input tabindex="1" type="date" [(ngModel)]="EntryDate" formControlName="EntryDate" class="form-control" style="font-weight: bold;" disabled=disabled />
        </div>-->
        <!-- Grid column -->
        <div class="col">
          <!-- Default input -->
          Enrol/Admn No.
          <input class="form-control" tabindex="2" type="text" name="admno" id="userIdFirstWay" placeholder="Enrol/Admn No." (change)="onChangeEnrolNo($event)" required=required [(ngModel)]="Student[0].admno" style="font-weight: bold;" readonly="{{Admnoinputdisabled}}" />
          <h5 style="color:purple;font-weight:bold;">{{StudentShift}}</h5><button style="background-color: #4CAF50;color:white;border-radius: 8px; font-size: 14px; height: 30px; width: 70px " (click)="RefreshPage()">Refresh</button>
          <h5 style="color: red;font-weight:bold;">{{leftOn}}</h5>
        </div>
        <div class="col">
          <!-- Default input -->
          Student Name
          <input style="font-weight:bold" value="{{Student[0].name}}" readonly="readonly" />
        </div>
        <div class="col">
          <!-- Default input -->
          Fee Period
          <input style="font-weight:bold" value="A/Y {{GLBappFormStarEnd}}" readonly="readonly" />
        </div>
        <div class="col">
          <!-- Default input -->
          Receipt No.
          <input style="font-weight:bold" value="{{Student[0].maxRno}}" />
        </div>
        <!-- Grid column -->
      </div>
      <br />
      <div class="row">
        <div class="col">
          <!-- Default input -->
          Student Type
          <input style="font-weight:bold" value="{{Student[0].oldnew}}" readonly="readonly" />
        </div>
        <div class="col">
          <!-- Default input -->
          Gender
          <input style="font-weight:bold" value="{{Student[0].gender}}" readonly="readonly" />
        </div>
        <!-- Grid column -->
        <div class="col">
          <!-- Default input -->
          Course/Class Name
          <input style="font-weight:bold" value="{{Student[0].clsname}}" readonly="readonly" />
        </div>
        <div class="col">
          <!-- Default input -->
          Category
          <input style="font-weight:bold" value="{{Student[0].catdesc}}" readonly="readonly" />
        </div>
        <div class="col">
          <!-- Default input -->
          Previous Amount
          <input style="font-weight:bold" value="{{Student[0].duesbalance}}" readonly="readonly" />
        </div>
        <!-- Grid column -->
      </div>
      <br />
      <div class="row">
        <div class="col">
          Cheque/Draft Drawn at
          <input tabindex="2" type="text" class="form-control" [(ngModel)]="DrawnAt" list="dynmicUserIds" placeholder="" formControlName="DrawnAt" style="font-weight: bold;" />
          <datalist id="dynmicUserIds">
            <option *ngFor="let bank of BankDD" [value]="bank.bankdesc" style="font-weight: bold;">{{bank.bankCode}}</option>
          </datalist>
        </div>
        <div class="col">
          Cheque/Draft Date
          <input tabindex="3" type="date" [(ngModel)]="ChDts" formControlName="ChDt" class="form-control" style="font-weight: bold;" />
        </div>
        <div class="col">
          Cheque/Draft No.
          <input tabindex="4" type="text" class="form-control" placeholder="" [(ngModel)]="ChequeNo" formControlName="ChNo" style="font-weight: bold;" />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col">
          Branch Address
          <input tabindex="5" type="text" class="form-control" placeholder="" [(ngModel)]="BankAddress" formControlName="BankAddress" style="font-weight: bold;" />
        </div>
        <div class="col">
          Cheque/Draft Amount
          <input tabindex="6" type="text" class="form-control" placeholder="" [(ngModel)]="ChequeAmt" formControlName="ChequeAmt" style="font-weight: bold;" />
        </div>
      </div>
      <br />
      <div class="form-check form-check-inline">
        <input mdbRadio=mdbRadio
               class="form-check-input"
               type="radio"
               name="flexRadioDefault1"
               id="flexRadioDefault1"
               checked="checked"
               (click)="onClickedArrear('0')" />
        <label class="form-check-label" for="flexRadioDefault6"><b>Cheque</b></label>
      </div>
      <!--<div class="form-check form-check-inline">
        <input mdbRadio=mdbRadio
               class="form-check-input"
               type="radio"
               name="flexRadioDefault1"
               id="flexRadioDefault2"               
               (click)="onClickedArrear('1')" />
        <label class="form-check-label" for="flexRadioDefault6"><b>Bank Draft</b></label>
      </div>-->
      <br />
      <div class="col">
        <div class="form-group">
          <h5 style="color:purple;font-weight:bold;">{{StudentShift}}</h5>
          <h5 style="color: red;font-weight:bold;">{{leftOn}}</h5>
          <div *ngIf="FeeMessage.length == 0 ">
            <input type="submit" name="submit" value="Submit" class="btn btn-success btn-lg btn-block" />
          </div>
        </div>
      </div>
      <br />
      <div class="form-group">
        <button class="btn btn-success btn-lg btn-block" (click)="RefreshPage()">Refresh</button>
      </div>
    </form>
    <script type="text/javascript">
      $(document).on('keypress', 'input,select', function (e) {
        if (e.which == 13) {
          e.preventDefault();
          var $next = $('[tabIndex=' + (+this.tabIndex + 1) + ']');
          if (!$next.length) {
            $next = $('[tabIndex=1]');
          }
          $next.focus().click();
        }
      });
    </script>
  </div>
</body>
</html>
