//04176803121
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TransFeesService } from '../transfees/transfees.service'
import { formatDate, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Pipe } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { GlobalComponent } from 'global-component';
import { isEmpty } from 'rxjs';
import { Clipboard } from "@angular/cdk/clipboard";
//import Swal from 'sweetalert2/dist/sweetalert2.js';
import Swal from 'sweetalert2/dist/sweetalert2.js'
//import 'sweetalert2/src/sweetalert2.scss'

@Component({
  selector: 'transfees',
  templateUrl: './transfees.component.html',
  styleUrls: ['css/dp-light.css', 'student.component.css'],
})

export class TransFeesComponent implements OnInit {
  @ViewChild('noofinstallments') inputName: ElementRef;
  //@ViewChild('IntAdmno') inputAdmno: ElementRef;
  element: HTMLElement;
  GLBappFormStarEnd: string = "";
  pipe = new DatePipe('en-US');
  now = Date.now();
  admno: any;
  chequeChecked: boolean;
  PDCTranDet: any;
  MISB: number;
  iHiddenOpBal: boolean; true;
  DataReced: number = 0;
  ShiftIdN: number = 1;
  DrawnAt: string = '';
  RefreshClicked: boolean; false;
  BankAddress: string = 'NEW DELHI';
  StudentShift: any;
  //PDCTranDet: any;
  //noofdays: number = 0;
  //noofinstallments: number = 0;
  //ArrearTotal: Number;
  //FeesTotal: Number;
  todayDate: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  StudentDD: any;
  leftOn: string;
  StudentLastFive: any;
  EmployeeDD: any;
  Student: any = [];
  StudInstallDet: any;
  StudentH: any;
  //IncreVal: number;
  StudentH2: any;
  ChequeNo: string;
  FeeMessage: string = '';
  BankTransfer: string = '';
  Amtinputdisabled: string = '';
  Admnoinputdisabled: string = '';
  AllBoxes: number = 0;
  ChkHeadCodes: number = 0;
  ChkHeadCode2: number = 0;
  StudentLastTran: any;
  WaivedOffAmt: number = 0;
  WaivedOffAmtD: number = 0;
  LessPaidAmt: number = 0;
  LessPaidAmtD: number = 0;
  LessPaidAmtF: number = 0;
  WaivedOffAmtF: number = 0;
  LessAmtHeadWise: string;
  LessAmtHeadWiseD: string;
  WaivedAmtHeadWise: string;
  WaivedAmtHeadWiseD: string;
  StudentDues: any;
  UserData01: any[];
  UserForm: FormGroup;
  userData: string[] = new Array();
  userData3: any[] = new Array();
  userData2: any[];
  userInput: any[]; // = new Array(15);
  userInputW: any[] = new Array(15);
  userInputD: any[]; // = new Array(15);
  userInputWD: any[] = new Array(15);
  //userInput = Array<{ id: number; name: string }>;
  lastkeydown1: number = 0;
  subscription: any;
  IndexR: number = 0;
  AmountToPay: number = 0;
  AmountToPayC: number = 0;
  AmountToPayCC: number = 0;
  AmountToPayCM: number = 0;
  AmountToPayCD: number = 0;
  AmountLFine: number = 0;
  userList1: any[15];
  stringforward: any[];
  STUDADMNO: string = "";
  GLBappFormType: string = "";
  GLBappUserCode: string = "";
  UserName: any;
  public EmployeeList: Array<any> = [];
  date: string = '';
  GLBappFormName: string = "";
  isChecked: boolean = false;
  //NextDate1: any[];
  public NextDate1: Array<any> = [];
  public NextPayment1: Array<any> = [];
  NextDateString: string = "";
  isOnlyArrear: boolean = false;
  isFeeAndArrear: boolean = false;
  LessPaitAmtInstall: number;
  LessAmoutPaidBool: boolean = false;
  InstallmentDateString: string;
  InstallmentAmtString: string;
  ByPaymentMode: number = 0;
  IPAddress: string = "";
  YesOpDueBal: boolean = false;
  ChDts: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  TodaysDate: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  constructor(public clipboard: Clipboard, private tranService: TransFeesService, private fb: FormBuilder, private router: Router) {
    this.todayDate = this.pipe.transform(sessionStorage.getItem('currentdate'), 'yyyy-MM-dd');
    this.ChDts = this.pipe.transform(sessionStorage.getItem('currentdate'), 'yyyy-MM-dd');
    this.chequeChecked = false;
    this.ShiftIdN = 1;
    this.DataReced = 0;
    this.leftOn = "";
    //this.IncreVal = 0;
    this.iHiddenOpBal = true;
    this.YesOpDueBal = false;
    this.StudentShift = "";
    this.LessPaidAmtF = 0;
    this.WaivedOffAmtF = 0;
    this.LessPaidAmt = 0;
    this.AllBoxes = 0;
    this.MISB = 1;
    this.admno = '';
    this.LessPaidAmtD = 0;
    this.StudInstallDet = "";
    this.WaivedAmtHeadWise = "";
    this.WaivedAmtHeadWiseD = "";
    this.STUDADMNO = "";
    this.ByPaymentMode = 0;
    this.LessAmtHeadWise = "";
    this.LessAmtHeadWiseD = "";
    this.isOnlyArrear = false;
    this.InstallmentDateString = "";
    this.InstallmentAmtString = "";
    this.RefreshClicked == false;
    this.displayStyle = "none";
    this.IPAddress = sessionStorage.getItem('ipaddress');
    //if (this.IncreVal <= Number(sessionStorage.getItem('incrementDisplay'))) { this.IncreVal = Number(sessionStorage.getItem('incrementDisplay')); }
    //if (this.IncreVal <= 100) {
    //  this.displayStyle = "block";
    //  this.tranService.ViewPDCTranDet(this.TodaysDate, parseInt(sessionStorage.getItem('YearCode'))).subscribe((PDCretval: any) => {
    //    this.PDCTranDet = PDCretval;
    //  }, (error) => {
    //    console.log(error);
    //  });
    //}
    //this.IncreVal = this.IncreVal + 1;
    //sessionStorage.setItem('incrementDisplay', this.IncreVal.toString());
  }

  RefreshPage() {
    this.RefreshClicked = true;
    this.router.navigate(['/transfees'])
      .then(() => {
        window.location.reload();
      });
    this.Admnoinputdisabled = "readonly";
    this.ngOnInit();
  }

  onClickedArrear(event: Event) {
    debugger;
    this.AllBoxes = 0;
    this.LessPaidAmt = 0;
    //if (this.inputName.nativeElement == 'undefined') { }
    //this.inputName.nativeElement.value = 0;
    this.clipboard.copy(this.STUDADMNO);
    if (this.InstallmentAmtString != "") { this.RefreshPage(); return true; }
    this.InstallmentAmtString = "";
    this.InstallmentDateString = "";
    this.NextDateString = "";
    this.LessAmtHeadWise = '';
    this.WaivedAmtHeadWise = '';
    this.InstallmentDateString = '';
    this.InstallmentAmtString = '';
    let StudentHHH: any[] = this.StudentH;
    this.StudentH = new Array();
    let count = StudentHHH.length;
    this.userInput = new Array(count);
    const isChecked: boolean = event.target['checked'];
    this.isOnlyArrear = true;
    this.isFeeAndArrear = false;
    this.AmountToPayCM = Number(this.Student[0].amt8);
    this.AmountToPayCD = this.AmountToPayC - this.AmountToPayCM
    this.AmountToPayC = this.AmountToPayCD;
    this.AmountToPayCM = 0;
    if (isChecked == true) { this.isOnlyArrear = true; }
  }

  displayStyle = "block";

  openPopup() {
    if (sessionStorage.getItem('incrementDisplay') == "1") { this.displayStyle = "block" };
    sessionStorage.setItem('incrementDisplay', '2');
  }
  closePopup() {
    this.displayStyle = "none";
  }   

  onClickedPayment(event: any) {
    debugger;
    this.ByPaymentMode = 0;
    //const paymentchecked = event.returnValue;
    // FOR CHEQUE (1), FOR CASH (2), FOR UPI (3), FOR DRAFT (4), FOR TRANSFER (5)
    this.DrawnAt = '';
    this.BankAddress = '';
    this.BankTransfer = 'DUL';
    switch (event) {
      case 'bysbcportal':
        {
          this.ByPaymentMode = 10;
          this.BankTransfer = 'DUL';
          this.DrawnAt = 'SBI';
          this.BankAddress = 'New Delhi';
          this.ChDts = this.todayDate;
          break;
        }
      case 'bycashgtbit':
        {
          this.ByPaymentMode = 9;
          this.BankTransfer = '';
          this.DrawnAt = 'Cash@GTBIT';
          this.BankAddress = 'New Delhi';
          this.ChDts = this.todayDate;
          break;
        }
      case 'bycash':
        {
          this.ByPaymentMode = 2;
          this.BankTransfer = '';
          this.DrawnAt = 'PNB-Cash';
          this.BankAddress = 'New Delhi';
          this.ChDts = this.todayDate;
          break;
        }
      case 'byupi':
        {
          this.ByPaymentMode = 3;
          this.ChDts = this.todayDate;
          this.BankTransfer = 'UPI';
          this.DrawnAt = 'SBI';
          this.BankAddress = 'New Delhi';
          break;
        }
      case 'bydraft':
        {
          this.ByPaymentMode = 4;
          this.BankTransfer = '';
          this.ChDts = this.todayDate;
          this.BankAddress = 'New Delhi';
          break;
        }
      case 'bytransfer':
        {
          this.BankTransfer = 'NEFT';
          this.ByPaymentMode = 5;
          this.ChDts = this.todayDate;
          this.DrawnAt = 'SBIC';
          this.BankAddress = 'New Delhi';
          break;
        }
      default:
        this.ByPaymentMode = 1;
        //this.BankTransfer = '';
        this.BankAddress = 'New Delhi';
        this.ChDts = this.todayDate;
        break;
    }
  }

  onClickedAtBank(event: any) {
    debugger;
    this.MISB = 1;
    switch (event) {
      case 'atpnb':
        {
          this.MISB = 2;
          break;
        }
      case 'bycashgtbit':
        {
          this.MISB = 9;
          break;
        }
      default:
        this.MISB = 1;
        break;
    }
  }

  onClickedFeeArrear(event: Event) {
    debugger;
    this.StudentH = this.StudentH2;
    this.AmountToPayCM = this.Student[0].amt8;
    this.AmountToPayC = this.AmountToPay;
    this.AmountToPayCD = this.AmountToPay;
    const isChecked: boolean = event.target['checked'];
    this.isOnlyArrear = false;
    this.isFeeAndArrear = true;
    if (isChecked == true) { this.isFeeAndArrear = true; }
  }

  onChangeDate(event: any) {
    //this.pipe.transform(this.now, 'yyyy-MM-dd');
    //sessionStorage.setItem('currentdate', this.pipe.transform(event.target.value, 'yyyy-MM-dd'));
    //sessionStorage.setItem('currentdate', formatDate(event.target.value, 'dd-MM-yyyy', 'en-US'));
    this.ChDts = formatDate(event.target.value, 'dd-MM-yyyy', 'en-US');
  }

  onChangeNoofdays(evt1: number, evt2: number) {
    debugger;
    this.isChecked = false;
    this.NextDateString = "";
    this.InstallmentDateString = "";
    this.InstallmentAmtString = "";
    this.LessPaitAmtInstall = this.LessPaidAmt / evt2;
    let NextDate2: Date = new Date();
    for (let n = 0; n < evt2; ++n) {
      //date.setDate(date.getDate() + 27);
      //NextDate2.setDate(NextDate2.getDate() + evt1);
      //this.InstallmentDateString = "";
      //this.InstallmentAmtString = "";
      NextDate2 = new Date(NextDate2.getTime() + (evt1 * 24 * 60 * 60 * 1000));
      this.date = formatDate(NextDate2, 'dd-MM-yyyy', 'en-US');
      this.NextDate1[n] = this.date.toString();
      this.NextPayment1[n] = this.LessPaitAmtInstall;
      this.NextDateString = this.NextDateString + this.date.toString() + "(Rs." + this.LessPaitAmtInstall + "), ";
      this.InstallmentDateString = this.InstallmentDateString + this.date.toString() + ",";
      this.InstallmentAmtString = this.InstallmentAmtString + this.LessPaitAmtInstall.toString() + ",";
      //NextDate2.setDate(this.date.toString());
    }
    if (this.NextDateString.length != 0) {
      this.NextDateString = "--- Installments Date: " + this.NextDateString;
      this.InstallmentDateString = this.NextDateString + "###" + this.ChkHeadCode2;
    }
  }

  onChangeInstallments(evt1: number, evt2: number) {
    debugger;
    this.isChecked = false;
    this.NextDateString = "";
    this.InstallmentDateString = "";
    this.InstallmentAmtString = "";
    this.LessPaitAmtInstall = this.LessPaidAmt / evt2;
    let NextDate2: Date = new Date();
    for (let n = 0; n < evt2; ++n) {
      //date.setDate(date.getDate() + 27);   
      //NextDate2.setDate(NextDate2.getDate() + evt1);
      NextDate2 = new Date(NextDate2.getTime() + (evt1 * 24 * 60 * 60 * 1000));
      this.date = formatDate(NextDate2, 'dd-MM-yyyy', 'en-US');
      this.NextDate1[n] = this.date.toString();
      this.NextPayment1[n] = this.LessPaitAmtInstall;
      this.NextDateString = this.NextDateString + this.date.toString() + "(Rs." + this.LessPaitAmtInstall + "), ";
      this.InstallmentDateString = this.InstallmentDateString + this.date.toString() + ",";
      this.InstallmentAmtString = this.InstallmentAmtString + this.LessPaitAmtInstall.toString() + ",";
      //NextDate2.setDate(this.date.toString());
    }
    if (this.NextDateString.length != 0) {
      this.NextDateString = "--- Installments Date: " + this.NextDateString;
      this.InstallmentDateString = this.NextDateString + "###" + this.ChkHeadCode2;
    }
  }

  //onCheckboxChange(evt1: number, evt2: number,event: Event) {
  //  debugger;
  //  const isChecked: boolean = event.target['checked'];
  //  //console.log(evt1);
  //  //console.log(evt2);

  //  //pipe = new DatePipe('en-US');
  //  this.NextDateString = "";
  //  if (isChecked == true) {
  //    this.LessPaitAmtInstall = this.LessPaidAmt / evt2;
  //    let NextDate2: Date = new Date();
  //    for (let n = 0; n < evt2; ++n) {
  //      //date.setDate(date.getDate() + 27);   
  //      //NextDate2.setDate(NextDate2.getDate() + evt1);
  //      NextDate2 = new Date(NextDate2.getTime() + (evt1 * 24 * 60 * 60 * 1000));
  //      this.date = formatDate(NextDate2, 'dd-MM-yyyy', 'en-US');
  //      this.NextDate1[n] = this.date.toString();
  //      this.NextDateString = this.NextDateString + this.date.toString() + "(Rs." + this.LessPaitAmtInstall + "), ";
  //      //NextDate2.setDate(this.date.toString());
  //    }
  //    //console.log(this.NextDate1);
  //  }
  //}

  ngOnInit() {
    this.GLBappFormStarEnd = sessionStorage.getItem('FYYear');
    //const swalWithBootstrapButtons = Swal.mixin({
    //  customClass: {
    //    confirmButton: "btn btn-success",
    //    cancelButton: "btn btn-danger"
    //  },
    //  buttonsStyling: false
    //});
    //swalWithBootstrapButtons.fire({
    //  title: "Are you sure?",
    //  text: "You won't be able to revert this!",
    //  icon: "warning",
    //  showCancelButton: true,
    //  confirmButtonText: "Yes, delete it!",
    //  cancelButtonText: "No, cancel!",
    //  reverseButtons: true
    //}).then((result) => {
    //  if (result.isConfirmed) {
    //    swalWithBootstrapButtons.fire({
    //      title: "Deleted!",
    //      text: "Your file has been deleted.",
    //      icon: "success"
    //    });
    //  } else if (
    //    /* Read more about handling dismissals below */
    //    result.dismiss === Swal.DismissReason.cancel
    //  ) {
    //    swalWithBootstrapButtons.fire({
    //      title: "Cancelled",
    //      text: "Your imaginary file is safe :)",
    //      icon: "error"
    //    });
    //  }
    //});

    //Swal.fire({
    //  title: "Do you want to save the changes?",
    //  showDenyButton: true,
    //  showCancelButton: true,
    //  confirmButtonText: "Save",
    //  denyButtonText: `Don't save`
    //}).then((result) => {
    //  /* Read more about isConfirmed, isDenied below */
    //  if (result.isConfirmed) {
    //    Swal.fire("Saved!", "", "success");
    //  } else if (result.isDenied) {
    //    Swal.fire("Changes are not saved", "", "info");
    //  }
    //});

    //This is OK
    //Swal.fire({
    //  //position: 'top-end',
    //  text: '<strong>Do you want to Save the Record?</strong>',
    //  showCancelButton: true,
    //  icon: 'question',      
    //  confirmButtonText: 'Yes, delete it!',
    //  cancelButtonText: 'No, keep it'
    //}).then((result: { value: any; }) => {
    //  if (result.value) {
    //    window.alert("hello");
    //    //this.router.navigate(['/blog'])
    //    //  .then(() => {
    //    //    //window.location.reload();
    //    //  });
    //  }
    //});

    //window.alert(parseInt("32121321"));
    //window.alert(sessionStorage.getItem('usercode'));
    //if (parseInt(sessionStorage.getItem('usercode')) == 0 || sessionStorage.getItem('usercode') == null) {
    //  this.router.navigate(['/logoutuser'])
    //    .then(() => {
    //      window.location.reload();
    //    });
    //  return;
    //}
    debugger;
    this.displayStyle = "none";
    if (parseInt(sessionStorage.getItem('usercode')) == 0 || sessionStorage.getItem('usercode') == null) {
      this.router.navigate(['/logoutuser'])
        .then(() => {
          window.location.reload();
        });
      return;
    }
    this.LessPaidAmt = 0;
    this.LessPaidAmtD = 0;
    this.UserForm = new FormGroup({
      AId: new FormControl(null, Validators.required),
      TodayDate: new FormControl(null, Validators.required),
      Admno: new FormControl(null, Validators.required),
      ByPamentMode: new FormControl(1, Validators.required),
      MISB: new FormControl(1, Validators.required),
      BankNo: new FormControl(null, Validators.required),
      LateFine: new FormControl(0, Validators.required),
      TotalAmt: new FormControl(0, Validators.required),
      ChNo: new FormControl(null, Validators.required),
      ChDt: new FormControl(null, Validators.required),
      BankName: new FormControl(null, Validators.required),
      DrawnAt: new FormControl(null, Validators.required),
      BankAddress: new FormControl(null, Validators.required),
      OnlyArrear: new FormControl(false, Validators.required),
      FeeInstallment: new FormControl(null, Validators.required),
      FeeInstallmentAmt: new FormControl(null, Validators.required),
      LessHeadWiseAmount: new FormControl(null, Validators.required),
      WaviedHeadWiseAmount: new FormControl(null, Validators.required),
      LessHeadWiseDuesAmount: new FormControl(null, Validators.required),
      WaviedHeadWiseDuesAmount: new FormControl(null, Validators.required),
      IpAddress: new FormControl(null, Validators.required),
      YesOpDueBal: new FormControl(false, Validators.required),
    });
    sessionStorage.setItem('formtype', '1');
    this.GLBappFormType = sessionStorage.getItem('formtype');
    sessionStorage.setItem('formname', 'An. Fee Tr');
    this.GLBappFormName = sessionStorage.getItem('formname');
    this.GLBappUserCode = sessionStorage.getItem('usercode');
    //this.tranService.GetAllStudent().subscribe((StudentDD) => {
    //  this.StudentDD = StudentDD;      
    //}, (error) => {
    //  console.log(error);
    //});
    this.RefreshClicked == false;
    this.GetLastFiveTransactions();

  }
  
  onFormSubmit() {
    //Admno: 431915
    debugger;
    if (parseInt(sessionStorage.getItem('YesTran')) == 9) {      
      Swal.fire({
        html: "<strong>Do some Action? Please change to current F/Year.</strong>",
        icon: 'info',
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
         // window.location.reload();
        }
      })
      return false;
    }
    
    const studfee = this.UserForm.value;
    studfee.AId = Number(this.Student[0].aid);
    studfee.Admno = this.STUDADMNO;
    studfee.ByPamentMode = this.ByPaymentMode;
    //console.log(studfee.AId);
    //this.NextDateString
    //studfee.ChNo = this.ChequeNo;
    studfee.DrawnAt = this.DrawnAt;
    studfee.LessHeadWiseAmount = this.LessAmtHeadWise;
    studfee.WaviedHeadWiseAmount = this.WaivedAmtHeadWise;
    studfee.LessHeadWiseDuesAmount = this.LessAmtHeadWiseD;
    studfee.WaviedHeadWiseDuesAmount = this.WaivedAmtHeadWiseD;
    studfee.OnlyArrear = this.isOnlyArrear;
    studfee.FeeInstallment = this.InstallmentDateString;
    studfee.FeeInstallmentAmt = this.InstallmentAmtString;
    studfee.IpAddress = this.IPAddress;
    studfee.MISB = this.MISB;
    studfee.YesOpDueBal = this.YesOpDueBal;
    if (this.RefreshClicked == false) {

      
      Swal.fire({
        //position: 'top-end',        
        html: "<strong><H3>Do you want to Save the Record?</H3></strong>",
        showCancelButton: true,
        icon: 'question',
        //iconHtml: '<img src="https://picsum.photos/100/100">',
        width: 400,
        padding: 50,
        //className: "green-bg",
        confirmButtonColor: "#338921",
        confirmButtonText: 'Yes',
        cancelButtonColor: "#F6122E",
        cancelButtonText: 'No'
      }).then((result: { value: any; }) => {
        if (result.value) {
          //window.alert("hello");          
            this.tranService.AddStduentTranFeesDet1(studfee).subscribe((retval) => {
              Swal.fire({
                html: "<strong>Submitted successfully</strong>",
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: "#338921",
                confirmButtonText: 'OK'
                //background: '#fff url(//bit.ly/1Nqn9HU)',
                //timer: 300000
              }).then((result) => {
                if (result.value) {
                  this.StudentShift = "";
                  this.ByPaymentMode = 1;
                  this.NextDateString = "";
                  this.Amtinputdisabled = "";
                  this.isOnlyArrear = false;
                  this.isFeeAndArrear = false;
                  this.FeeMessage = "";
                  this.STUDADMNO = "";
                  this.StudInstallDet = "";
                  this.Student = "";
                  this.StudentDues = "";
                  this.StudentH = "";
                  this.StudentH2 = "";
                  this.AmountToPay = 0;
                  this.AmountToPayC = 0;
                  this.AmountToPayCD = 0;
                  this.AmountToPayCC = 0;
                  this.AmountToPayCM = 0;
                  this.AmountLFine = 0;
                  this.router.navigate(['/transfees'])
                    .then(() => {
                      window.location.reload();
                    });  
                }
              })
              //Thread.sleep()
              //window.alert('Submitted successfully');
                          
            }, (error) => {
              Swal.fire('Some error:' + error, 'error');
              //console.log(error);
            });          
        }
      });
    }
    this.GetLastFiveTransactions();
  }

  public SetGetNullValueStr(parametername: string) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return "NULL";
    }
  }

  public SetGetNullValueInt(parametername: any) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return Number(0);
    }
  }

  public SetGetNullValueDate(parametername: any) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return '1900-01-01';
    }
  }

  //onSubmit(admno: string, amt2: number, AmountToPayC: bigint, bankname: string, creditdebitno: string, _receivedamt: bigint, processdate: Date, transdate: Date, chequedrawn: string, branchaddress: string, cheqdraftdate: Date, cheqdraftno: string, userInput: any[], userInputW: any[]) {
  //  chequedrawn = this.SetGetNullValueStr(chequedrawn);
  //  creditdebitno = this.SetGetNullValueStr(creditdebitno);
  //  bankname = this.SetGetNullValueStr(bankname);
  //  branchaddress = this.SetGetNullValueStr(branchaddress);
  //  cheqdraftno = this.SetGetNullValueStr(cheqdraftno);
  //  cheqdraftdate = this.SetGetNullValueDate(cheqdraftdate);
  //  processdate = this.SetGetNullValueDate(processdate);
  //  transdate = this.SetGetNullValueDate(transdate);
  //  _receivedamt = this.SetGetNullValueInt(_receivedamt);
  //  amt2 = this.SetGetNullValueInt(amt2);     
  //  this.tranService.AddStduentTranFeesDet1(admno, amt2, AmountToPayC, bankname, creditdebitno, _receivedamt, processdate, transdate, chequedrawn, branchaddress, cheqdraftdate, cheqdraftno, userInput, userInputW, this.GLBappFormType, this.GLBappUserCode).subscribe((Student) => {
  //    window.alert('Submitted successfully');
  //  }, (error) => {
  //    window.alert('Some Error');
  //  });
  //}

  //onSubmit(UserForm) {
  //  //debugger;
  //  const feedet = UserForm.value;
  //  //chequedrawn = this.SetGetNullValueStr(chequedrawn);
  //  //creditdebitno = this.SetGetNullValueStr(creditdebitno);
  //  //bankname = this.SetGetNullValueStr(bankname);
  //  //branchaddress = this.SetGetNullValueStr(branchaddress);
  //  //cheqdraftno = this.SetGetNullValueStr(cheqdraftno);
  //  //cheqdraftdate = this.SetGetNullValueDate(cheqdraftdate);
  //  //processdate = this.SetGetNullValueDate(processdate);
  //  //transdate = this.SetGetNullValueDate(transdate);
  //  //_receivedamt = this.SetGetNullValueInt(_receivedamt);
  //  //amt2 = this.SetGetNullValueInt(amt2);
  //  this.tranService.AddStduentTranFeesDet1(feedet).subscribe((Student) => {
  //    window.alert('Submitted successfully');
  //  }, (error) => {
  //    console.log(error);
  //  });
  //}


  DeleteEmployee(data) {
    if (window.confirm('Are you sure, you want to Delete?')) {
      //this.catService.DeleteEmployeeDET(data.id).subscribe((data) => {
      //  //this.EMPID = id;
      //  window.alert('Record updated successfully')
      //  console.log('data id' + data.id);
      //}, (error) => {
      //  console.log(error);
      //});
    }
  }

  onChange(event: any) {
    debugger;
    this.RefreshClicked = false;
    this.StudentShift = "";
    this.ByPaymentMode = 1;
    this.iHiddenOpBal = true;
    this.YesOpDueBal = false;
    this.NextDateString = "";
    this.Amtinputdisabled = "";
    this.isOnlyArrear = false;
    this.isFeeAndArrear = false;
    this.FeeMessage = "";
    this.STUDADMNO = event.target.value;
    this.admno = this.STUDADMNO;
    this.StudInstallDet = "";
    this.Student = "";
    const studfee = this.UserForm.value;
    //this.UserForm.controls['admno'].disable();
    studfee.AId = "";
    studfee.Admno = "";
    this.StudentDD = "";
    this.StudentDues = "";
    this.StudentH = "";
    //this.inputAdmno.nativeElement.disabled = true;
    //this.element = document.getElementById('admno') as HTMLElement;
    //this.element.setAttribute('disabled', 'disabled');
    //this.UserForm.get('state').reset();
    //document.getElementById('admno').setAttribute('disabled', 'true');
    //this.UserForm.get('admno').disable();
    this.Admnoinputdisabled = "";
    this.Student = [];
    this.leftOn = "";
    this.StudentH2 = "";
    this.AmountToPay = 0;
    this.AmountToPayC = 0;
    this.AmountToPayCD = 0;
    this.AmountToPayCC = 0;
    this.AmountToPayCM = 0;
    this.AmountLFine = 0;
    this.ShiftIdN = 1;
    this.DataReced = 0;
    this.chequeChecked = false;
    this.ChDts = this.todayDate;
    //this.todayDate = event.target.value;
    //console.log(this.STUDADMNO);
    //window.alert(sessionStorage.getItem('NbaseURLs'));
    //window.alert("1");
    if (this.STUDADMNO.length != 0) {
      this.tranService.GetStudentFeeDETN(this.STUDADMNO.toString(), this.todayDate.toString(), parseInt(sessionStorage.getItem('YCode')), parseInt(sessionStorage.getItem('YearCode'))).then((_Stud) => {
        this.DataReced = _Stud;
        //window.alert(this.DataReced);
        //Start
        //window.alert(this.DataReced);
        if (this.DataReced == 1) {
          this.tranService.GetStudentFeeDETAdmnoN(this.STUDADMNO, parseInt(sessionStorage.getItem('YearCode'))).subscribe((Student) => {
            //window.alert("3");
            //window.alert(sessionStorage.getItem('NbaseURLs'));
            this.Student = Student;
            this.Admnoinputdisabled = "readonly";
            if (Number(this.Student[0].iShiftId) == 1) {
              this.StudentShift = "Morning Student";
              this.ShiftIdN = parseInt(this.Student[0].iShiftId)
            } else {
              this.StudentShift = "Evening Student";
              this.ShiftIdN = parseInt(this.Student[0].iShiftId)
            }
            if (Number(this.Student[0].ctr10) == 1) {
              this.leftOn = "(Left On: " + this.pipe.transform(this.Student[0].cDate8, 'dd-MM-yyyy') + ")";
            }
            this.GetLastFiveTransactions();
            this.tranService.GetPDCTranDet(this.STUDADMNO, parseInt(sessionStorage.getItem('YearCode'))).subscribe((PDCTranDet: any) => {
              this.PDCTranDet = PDCTranDet;
              this.DrawnAt = this.PDCTranDet[0].bankName
              this.ChDts = this.pipe.transform(this.PDCTranDet[0].chequeDate, 'yyyy-MM-dd');
              this.ChequeNo = this.PDCTranDet[0].chequeNo;
              this.chequeChecked = true;            
              this.ByPaymentMode = 1;
            }, (error) => {
              console.log(error);
            });

            
            if (this.Student[0].amt5 == 1) { this.Amtinputdisabled = "disabled"; }
            if (Number(this.Student[0].amt5) == 9) {
              this.tranService.ViewGetStudinstallmentDetails(this.STUDADMNO, parseInt(sessionStorage.getItem('YearCode'))).subscribe((StudInstDet: any) => {
                this.StudInstallDet = StudInstDet;
                this.FeeMessage = "This Student will pay in Installments. Details given above.";
              }, (error) => {
                console.log(error);
              });
            }
            //console.log('Student', this.Student);
            this.AmountToPay = Number(this.Student[0].amt10);
            this.AmountToPayC = Number(this.Student[0].amt10);
            this.AmountToPayCD = Number(this.Student[0].amt10);
            if (this.AmountToPayC == 0) { this.FeeMessage = "Fee Pending = Nil"; }
            this.AmountToPayCC = Number(this.Student[0].amt8);
            this.AmountToPayCM = Number(this.Student[0].amt8);
            this.AmountLFine = Number(this.Student[0].amt1);
            this.tranService.GetStudentFeeDETAdmnoH(this.STUDADMNO).subscribe((StudentH: any) => {
              //window.alert("5");
              this.StudentH = StudentH;
              this.StudentH2 = StudentH;
              //let userInput11 = new Array(StudentH).length;
              if (this.FeeMessage.length != 0) { this.StudentH = ""; this.StudentH2 = ""; this.AmountToPayCM = 0; }
              let StudentHHH: any[] = this.StudentH;
              let count = StudentHHH.length;
              this.userInput = new Array(count);
              this.userInputD = new Array(count);
              if (StudentH)
                //console.log('StudentH', this.StudentH);
                //if (this.Student[0].amt10 != 0 && this.Student[0].amt8 != 0) {
                //} else {
                //  window.alert('Previous Amount Balance, Please goto Fee Arrears');
                //}
                this.tranService.GetStudentFeeDETAdmnoDues(this.STUDADMNO, parseInt(sessionStorage.getItem('YearCode'))).subscribe((StudentDues: any) => {
                  //window.alert("6");
                  this.StudentDues = StudentDues;
                  if (this.FeeMessage.length != 0) { this.StudentDues = ""; Student[0].amt9 = 0; }
                  //console.log('StudentDues', this.StudentDues);
                }, (error) => {
                  //window.alert("01");
                  //window.alert(error);
                  console.log(error);
                });

            }, (error) => {
              //window.alert("02");
              //window.alert(error);
              console.log(error);
            });
          }, (error) => {
            //window.alert("03");
            //window.alert(error);
            console.log(error);
          });
        }

    //End
      }).catch ((error) => {
        //window.alert("04");
        //window.alert(error);
        console.log(error);
        //this.router.navigate(['student-master']);
        //this.ngOnInit();
        //window.location.reload();
      });
    }


  }
  keyPresseditamt(index: any, event: any, event2: any, event3: any, event4: any, event5: any) {
    debugger;
    this.LessAmoutPaidBool = false;
    this.NextDateString = "";
    this.LessAmtHeadWise = "";

    const CheckAmt = Number(event4) - Number(this.userInputW[index]);
    if (Number(event2.target.value) > CheckAmt) {
      event2.target.value = Number(CheckAmt);
    }
    if (Number(event2.target.value) <= Number(event4)) {
      this.userInput[index] = Number(event2.target.value);
      this.LessAmtHeadWise = this.LessAmtHeadWise + '(' + event5 + ')' + Number(event2.target.value) + ",";
    } else {
      event2.target.value = Number(event4);
      this.userInput[index] = Number(event2.target.value);
      this.LessAmtHeadWise = this.LessAmtHeadWise + '(' + event5 + ')' + Number(event2.target.value) + ",";
    }

    this.LessPaidAmt = this.userInput.reduce<number>((accumulator, current) => {
      return accumulator + current;
    }, 0);
    this.ChkHeadCodes = 0;
    this.ChkHeadCode2 = Number(event5);
    this.tranService.UpdateTemps2(this.Student[0].aid, Number(event5), Number(event2.target.value)).subscribe((sumofamount) => {
      this.ChkHeadCodes = sumofamount;
      if (Number(this.ChkHeadCodes) != 1) {
        this.ChkHeadCode2 = 0;
        this.LessPaidAmt = 0;
      }
    });

    this.WaivedOffAmt = this.userInputW.reduce<number>((accumulator, current) => {
      return accumulator + current;
    }, 0);

    if (Number(event2.target.value) <= Number(event4)) {
      this.AmountToPayC = Number(this.AmountToPayCD);
      //this.AmountToPayC = Number(this.AmountToPayC) - (Number(this.LessPaidAmt) + Number(event3) + Number(this.WaivedOffAmt));
      this.AmountToPayC = Number(this.AmountToPayC) - (Number(this.LessPaidAmt) + Number(this.LessPaidAmtD) + Number(event3) + Number(this.WaivedOffAmt) + + Number(this.WaivedOffAmtD));
      this.AmountToPayCC = this.AmountToPayC;
    } else {
      event.target.value = Number(event4);
    }
    this.LessPaidAmtF = 0;
    this.LessPaidAmtF = Number(this.LessPaidAmt) + Number(this.LessPaidAmtD);
    //this.inputName.nativeElement.value = 0;
    if (Number(this.LessPaidAmt) != 0) { this.LessAmoutPaidBool = true; }

    //console.log(result);
    return true;
  }

  keyPresseditamtW(index: any, event: any, event2: any, event3: any, event4: any, event5: any) {
    this.WaivedAmtHeadWise = "";
    this.DrawnAt = "";
    this.ChequeNo = "";
    this.tranService.UpdateTempsW2(this.Student[0].aid, Number(event5), Number(event2.target.value)).subscribe((sumofamount) => {
      this.LessPaidAmtF = sumofamount.sumduesamt;
      //console.log("Done");
    });

    const CheckAmt = Number(event4) - Number(this.userInput[index]);
    if (Number(event2.target.value) > CheckAmt) {
      event2.target.value = Number(CheckAmt);
    }

    if (Number(event2.target.value) <= Number(event4)) {
      this.userInputW[index] = Number(event2.target.value);
      this.WaivedAmtHeadWise = this.WaivedAmtHeadWise + '(' + event5 + ')' + Number(event2.target.value) + ",";
    } else {
      event2.target.value = Number(event4);
      this.userInputW[index] = Number(event2.target.value);
      this.WaivedAmtHeadWise = this.WaivedAmtHeadWise + '(' + event5 + ')' + Number(event2.target.value) + ",";
    }

    this.LessPaidAmt = this.userInput.reduce<number>((accumulator, current) => {
      return accumulator + current;
    }, 0);

    //this.userInputW[index] = Number(event2.target.value);
    this.WaivedOffAmt = this.userInputW.reduce<number>((accumulator, current) => {
      return accumulator + current;
    }, 0);

    if (Number(event2.target.value) <= Number(event4)) {
      this.AmountToPayC = Number(this.AmountToPayCD);
      //this.AmountToPayC = Number(this.AmountToPayC) - (Number(this.WaivedOffAmt) + Number(event3) + (this.LessPaidAmt));
      this.AmountToPayC = Number(this.AmountToPayC) - (Number(this.LessPaidAmt) + Number(this.LessPaidAmtD) + Number(event3) + Number(this.WaivedOffAmt) + + Number(this.WaivedOffAmtD));
      this.AmountToPayCC = this.AmountToPayC;
    } else {
      event.target.value = Number(event4);
    }
    this.WaivedOffAmtF = 0;
    this.WaivedOffAmtF = Number(this.WaivedOffAmt) + Number(this.WaivedOffAmtD);
    if (Number(this.AmountToPayC == 0)) {
      this.DrawnAt = "NIL";
      this.ChequeNo = "000000";
    }
    //console.log(result);
    return true;
  }

  keyPresseditamtD(index: any, event: any, event02: any, event03: any, event04: any, event05: any) {
    debugger;
    //this.NextDateString = "";
    this.tranService.UpdateTempsW3(this.StudentDues[index].aid, Number(event05), Number(event02.target.value)).subscribe((sumofamount) => {
      this.LessPaidAmtF = sumofamount.sumduesamt;
      //console.log("Done");
    });
    this.LessAmtHeadWiseD = "";
    //window.alert(Number(event02.target.value));
    const CheckAmt = Number(event04) - Number(this.userInputWD[index]);
    if (Number(event02.target.value) > CheckAmt) {
      event02.target.value = Number(CheckAmt);
    }
    if (Number(event02.target.value) <= Number(event04)) {
      this.userInputD[index] = Number(event02.target.value);
      this.LessAmtHeadWiseD = this.LessAmtHeadWiseD + '(' + event05 + ')' + Number(event02.target.value) + ",";
    } else {
      event02.target.value = Number(event04);
      this.userInputD[index] = Number(event02.target.value);
      this.LessAmtHeadWiseD = this.LessAmtHeadWiseD + '(' + event05 + ')' + Number(event02.target.value) + ",";
    }

    this.LessPaidAmtD = this.userInputD.reduce<number>((accumulator, current) => {
      return accumulator + current;
    }, 0);

    this.WaivedOffAmtD = this.userInputWD.reduce<number>((accumulator, current) => {
      return accumulator + current;
    }, 0);

    if (Number(event02.target.value) <= Number(event04)) {
      //if (this.isOnlyArrear == false) { this.AmountToPayC = Number(this.AmountToPay); }
      //else { this.AmountToPay = Number(this.AmountToPayC); }
      this.AmountToPayC = Number(this.AmountToPayCD);
      this.AmountToPayC = Number(this.AmountToPayC) - (Number(this.LessPaidAmt) + Number(this.LessPaidAmtD) + Number(event03) + Number(this.WaivedOffAmt) + + Number(this.WaivedOffAmtD));
      this.AmountToPayCC = this.AmountToPayC;
    } else {
      event.target.value = Number(event04);
    }
    this.LessPaidAmtF = 0;
    this.LessPaidAmtF = Number(this.LessPaidAmt) + Number(this.LessPaidAmtD);
    //console.log(result);
    return true;
  }

  keyPresseditamtWD(index: any, event: any, event2: any, event3: any, event4: any, event5: any) {
    debugger;
    this.WaivedAmtHeadWiseD = "";
    this.DrawnAt = "";
    this.ChequeNo = "";
    this.tranService.UpdateTempsWD3(this.StudentDues[index].aid, Number(event5), Number(event2.target.value)).subscribe((sumofamount) => {
      //this.LessPaidAmtF = sumofamount.sumduesamt;
      //console.log("Done");
    });

    const CheckAmt = Number(event4) - Number(this.userInputD[index]);
    if (Number(event2.target.value) > CheckAmt) {
      event2.target.value = Number(CheckAmt);
    }

    if (Number(event2.target.value) <= Number(event4)) {
      this.userInputWD[index] = Number(event2.target.value);
      this.WaivedAmtHeadWiseD = this.WaivedAmtHeadWiseD + '(' + event5 + ')' + Number(event2.target.value) + ",";
    } else {
      event2.target.value = Number(event4);
      this.userInputWD[index] = Number(event2.target.value);
      this.WaivedAmtHeadWiseD = this.WaivedAmtHeadWiseD + '(' + event5 + ')' + Number(event2.target.value) + ",";
    }

    this.LessPaidAmtD = this.userInputD.reduce<number>((accumulator, current) => {
      return accumulator + current;
    }, 0);

    //this.userInputW[index] = Number(event2.target.value);
    this.WaivedOffAmtD = this.userInputWD.reduce<number>((accumulator, current) => {
      return accumulator + current;
    }, 0);

    if (Number(event2.target.value) <= Number(event4)) {
      this.AmountToPayC = Number(this.AmountToPayCD);
      //this.AmountToPayC = Number(this.AmountToPayC) - (Number(this.WaivedOffAmtD) + Number(event3) + (this.LessPaidAmtD));
      this.AmountToPayC = Number(this.AmountToPayC) - (Number(this.LessPaidAmt) + Number(this.LessPaidAmtD) + Number(event3) + Number(this.WaivedOffAmt) + + Number(this.WaivedOffAmtD));
      this.AmountToPayCC = this.AmountToPayC;
    } else {
      event.target.value = Number(event4);
    }
    this.WaivedOffAmtF = 0;
    this.WaivedOffAmtF = Number(this.WaivedOffAmt) + Number(this.WaivedOffAmtD);
    if (Number(this.AmountToPayC == 0)) {
      this.DrawnAt = "NIL";
      this.ChequeNo = "000000";
    }
    //console.log(result);
    return true;
  }

  keyPressNumbers(event: any) {
    this.NextDateString = "";
    this.WaivedOffAmt = this.userInputW.reduce<number>((accumulator, current) => {
      return accumulator + current;
    }, 0);

    this.LessPaidAmt = this.userInput.reduce<number>((accumulator, current) => {
      return accumulator + current;
    }, 0);

    if (Number(event.target.value) <= Number(this.AmountLFine)) {
      this.AmountToPayC = Number(this.AmountToPay);
      this.AmountToPayC = Number(this.AmountToPayC) - (Number(event.target.value) + Number(this.LessPaidAmt) + Number(this.WaivedOffAmt));
      this.AmountToPayCC = this.AmountToPayC;
    } else {
      event.target.value = Number(this.AmountLFine);
    }
    return true;
  }
  //keyPresseditamt(index: any, event: any, event2: any, event3: any, event4: any, event5: any) {
  //  debugger;
  //  this.LessAmoutPaidBool = false;
  //  this.NextDateString = "";
  //  this.LessAmtHeadWise = "";

  //  const CheckAmt = Number(event4) - Number(this.userInputW[index]);
  //  if (Number(event2.target.value) > CheckAmt) {
  //    event2.target.value = Number(CheckAmt);
  //  }
  //  if (Number(event2.target.value) <= Number(event4)) {
  //    this.userInput[index] = Number(event2.target.value);
  //    this.LessAmtHeadWise = this.LessAmtHeadWise + '(' + event5 + ')' + Number(event2.target.value) + ",";
  //  } else {
  //    event2.target.value = Number(event4);
  //    this.userInput[index] = Number(event2.target.value);
  //    this.LessAmtHeadWise = this.LessAmtHeadWise + '(' + event5 + ')' + Number(event2.target.value) + ",";
  //  }

  //  this.LessPaidAmt = this.userInput.reduce<number>((accumulator, current) => {
  //    return accumulator + current;
  //  }, 0);
  //  this.ChkHeadCodes = 0;
  //  this.ChkHeadCode2 = Number(event5);
  //  this.tranService.UpdateTemps2(this.Student[0].aid, Number(event5), Number(event2.target.value)).subscribe((sumofamount) => {
  //    this.ChkHeadCodes = sumofamount;
  //    if (Number(this.ChkHeadCodes) != 1) {
  //      this.ChkHeadCode2 = 0;
  //      this.LessPaidAmt = 0;
  //    }
  //  });

  //  this.WaivedOffAmt = this.userInputW.reduce<number>((accumulator, current) => {
  //    return accumulator + current;
  //  }, 0);

  //  if (Number(event2.target.value) <= Number(event4)) {
  //    this.AmountToPayC = Number(this.AmountToPayCD);
  //    //this.AmountToPayC = Number(this.AmountToPayC) - (Number(this.LessPaidAmt) + Number(event3) + Number(this.WaivedOffAmt));
  //    this.AmountToPayC = Number(this.AmountToPayC) - (Number(this.LessPaidAmt) + Number(this.LessPaidAmtD) + Number(event3) + Number(this.WaivedOffAmt) + + Number(this.WaivedOffAmtD));
  //    this.AmountToPayCC = this.AmountToPayC;
  //  } else {
  //    event.target.value = Number(event4);
  //  }
  //  this.LessPaidAmtF = 0;
  //  this.LessPaidAmtF = Number(this.LessPaidAmt) + Number(this.LessPaidAmtD);
  //  //this.inputName.nativeElement.value = 0;
  //  if (Number(this.LessPaidAmt) != 0) { this.LessAmoutPaidBool = true; }

  //  //console.log(result);
  //  return true;
  //}

  //keyPresseditamtW(index: any, event: any, event2: any, event3: any, event4: any, event5: any) {
  //  this.WaivedAmtHeadWise = "";
  //  this.DrawnAt = "";
  //  this.ChequeNo = "";
  //  this.tranService.UpdateTempsW2(this.Student[0].aid, Number(event5), Number(event2.target.value)).subscribe((sumofamount) => {
  //    this.LessPaidAmtF = sumofamount.sumduesamt;
  //    //console.log("Done");
  //  });

  //  const CheckAmt = Number(event4) - Number(this.userInput[index]);
  //  if (Number(event2.target.value) > CheckAmt) {
  //    event2.target.value = Number(CheckAmt);
  //  }

  //  if (Number(event2.target.value) <= Number(event4)) {
  //    this.userInputW[index] = Number(event2.target.value);
  //    this.WaivedAmtHeadWise = this.WaivedAmtHeadWise + '(' + event5 + ')' + Number(event2.target.value) + ",";
  //  } else {
  //    event2.target.value = Number(event4);
  //    this.userInputW[index] = Number(event2.target.value);
  //    this.WaivedAmtHeadWise = this.WaivedAmtHeadWise + '(' + event5 + ')' + Number(event2.target.value) + ",";
  //  }

  //  this.LessPaidAmt = this.userInput.reduce<number>((accumulator, current) => {
  //    return accumulator + current;
  //  }, 0);

  //  //this.userInputW[index] = Number(event2.target.value);
  //  this.WaivedOffAmt = this.userInputW.reduce<number>((accumulator, current) => {
  //    return accumulator + current;
  //  }, 0);

  //  if (Number(event2.target.value) <= Number(event4)) {
  //    this.AmountToPayC = Number(this.AmountToPayCD);
  //    //this.AmountToPayC = Number(this.AmountToPayC) - (Number(this.WaivedOffAmt) + Number(event3) + (this.LessPaidAmt));
  //    this.AmountToPayC = Number(this.AmountToPayC) - (Number(this.LessPaidAmt) + Number(this.LessPaidAmtD) + Number(event3) + Number(this.WaivedOffAmt) + + Number(this.WaivedOffAmtD));
  //    this.AmountToPayCC = this.AmountToPayC;
  //  } else {
  //    event.target.value = Number(event4);
  //  }
  //  this.WaivedOffAmtF = 0;
  //  this.WaivedOffAmtF = Number(this.WaivedOffAmt) + Number(this.WaivedOffAmtD);
  //  if (Number(this.AmountToPayC == 0)) {
  //    this.DrawnAt = "NIL";
  //    this.ChequeNo = "000000";
  //  }
  //  //console.log(result);
  //  return true;
  //}

  //keyPresseditamtD(index: any, event: any, event02: any, event03: any, event04: any, event05: any) {
  //  debugger;
  //  //this.NextDateString = "";
  //  this.tranService.UpdateTempsW3(this.StudentDues[index].aid, Number(event05), Number(event02.target.value)).subscribe((sumofamount) => {
  //    this.LessPaidAmtF = sumofamount.sumduesamt;
  //    //console.log("Done");
  //  });
  //  this.LessAmtHeadWiseD = "";
  //  //window.alert(Number(event02.target.value));
  //  const CheckAmt = Number(event04) - Number(this.userInputWD[index]);
  //  if (Number(event02.target.value) > CheckAmt) {
  //    event02.target.value = Number(CheckAmt);
  //  }
  //  if (Number(event02.target.value) <= Number(event04)) {
  //    this.userInputD[index] = Number(event02.target.value);
  //    this.LessAmtHeadWiseD = this.LessAmtHeadWiseD + '(' + event05 + ')' + Number(event02.target.value) + ",";
  //  } else {
  //    event02.target.value = Number(event04);
  //    this.userInputD[index] = Number(event02.target.value);
  //    this.LessAmtHeadWiseD = this.LessAmtHeadWiseD + '(' + event05 + ')' + Number(event02.target.value) + ",";
  //  }

  //  this.LessPaidAmtD = this.userInputD.reduce<number>((accumulator, current) => {
  //    return accumulator + current;
  //  }, 0);

  //  this.WaivedOffAmtD = this.userInputWD.reduce<number>((accumulator, current) => {
  //    return accumulator + current;
  //  }, 0);

  //  if (Number(event02.target.value) <= Number(event04)) {
  //    //if (this.isOnlyArrear == false) { this.AmountToPayC = Number(this.AmountToPay); }
  //    //else { this.AmountToPay = Number(this.AmountToPayC); }
  //    this.AmountToPayC = Number(this.AmountToPayCD);
  //    this.AmountToPayC = Number(this.AmountToPayC) - (Number(this.LessPaidAmt) + Number(this.LessPaidAmtD) + Number(event03) + Number(this.WaivedOffAmt) + + Number(this.WaivedOffAmtD));
  //    this.AmountToPayCC = this.AmountToPayC;
  //  } else {
  //    event.target.value = Number(event04);
  //  }
  //  this.LessPaidAmtF = 0;
  //  this.LessPaidAmtF = Number(this.LessPaidAmt) + Number(this.LessPaidAmtD);
  //  //console.log(result);
  //  return true;
  //}

  //keyPresseditamtWD(index: any, event: any, event2: any, event3: any, event4: any, event5: any) {
  //  this.WaivedAmtHeadWiseD = "";
  //  this.DrawnAt = "";
  //  this.ChequeNo = "";
  //  this.tranService.UpdateTempsWD3(this.StudentDues[index].aid, Number(event5), Number(event2.target.value)).subscribe((sumofamount) => {
  //    this.LessPaidAmtF = sumofamount.sumduesamt;
  //    //console.log("Done");
  //  });

  //  const CheckAmt = Number(event4) - Number(this.userInputD[index]);
  //  if (Number(event2.target.value) > CheckAmt) {
  //    event2.target.value = Number(CheckAmt);
  //  }

  //  if (Number(event2.target.value) <= Number(event4)) {
  //    this.userInputWD[index] = Number(event2.target.value);
  //    this.WaivedAmtHeadWiseD = this.WaivedAmtHeadWiseD + '(' + event5 + ')' + Number(event2.target.value) + ",";
  //  } else {
  //    event2.target.value = Number(event4);
  //    this.userInputWD[index] = Number(event2.target.value);
  //    this.WaivedAmtHeadWiseD = this.WaivedAmtHeadWiseD + '(' + event5 + ')' + Number(event2.target.value) + ",";
  //  }

  //  this.LessPaidAmtD = this.userInputD.reduce<number>((accumulator, current) => {
  //    return accumulator + current;
  //  }, 0);

  //  //this.userInputW[index] = Number(event2.target.value);
  //  this.WaivedOffAmtD = this.userInputWD.reduce<number>((accumulator, current) => {
  //    return accumulator + current;
  //  }, 0);

  //  if (Number(event2.target.value) <= Number(event4)) {
  //    this.AmountToPayC = Number(this.AmountToPayCD);
  //    //this.AmountToPayC = Number(this.AmountToPayC) - (Number(this.WaivedOffAmtD) + Number(event3) + (this.LessPaidAmtD));
  //    this.AmountToPayC = Number(this.AmountToPayC) - (Number(this.LessPaidAmt) + Number(this.LessPaidAmtD) + Number(event3) + Number(this.WaivedOffAmt) + + Number(this.WaivedOffAmtD));
  //    this.AmountToPayCC = this.AmountToPayC;
  //  } else {
  //    event.target.value = Number(event4);
  //  }
  //  this.WaivedOffAmtF = 0;
  //  this.WaivedOffAmtF = Number(this.WaivedOffAmt) + Number(this.WaivedOffAmtD);
  //  if (Number(this.AmountToPayC == 0)) {
  //    this.DrawnAt = "NIL";
  //    this.ChequeNo = "000000";
  //  }
  //  //console.log(result);
  //  return true;
  //}

  //keyPressNumbers(event: any) {
  //  this.NextDateString = "";
  //  this.WaivedOffAmt = this.userInputW.reduce<number>((accumulator, current) => {
  //    return accumulator + current;
  //  }, 0);

  //  this.LessPaidAmt = this.userInput.reduce<number>((accumulator, current) => {
  //    return accumulator + current;
  //  }, 0);

  //  if (Number(event.target.value) <= Number(this.AmountLFine)) {
  //    this.AmountToPayC = Number(this.AmountToPay);
  //    this.AmountToPayC = Number(this.AmountToPayC) - (Number(event.target.value) + Number(this.LessPaidAmt) + Number(this.WaivedOffAmt));
  //    this.AmountToPayCC = this.AmountToPayC;
  //  } else {
  //    event.target.value = Number(this.AmountLFine);
  //  }
  //  return true;
  //}

  GetLastFiveTransactions() {
    debugger;
    this.tranService.GetLastTranDateFive(parseInt(sessionStorage.getItem('YearCode')), this.ShiftIdN).subscribe((response: any) => {
      this.StudentLastFive = response;
      console.log("Done");
    }, (error) => {
      console.log(error);
    });
  }
}
