<h3>Student Prev.Dues Status {{GLBappFormStarEnd}}</h3>
<div class="container">
  <div *ngIf="loading!=''" class="cssload-container">
    <div class="cssload-speeding-wheel"></div>
  </div>
  <div *ngIf="loading!=''" class='fullscreenDiv'>
    <div class="center">Loading...Please Wait</div>
  </div>
  <mat-form-field appearance="standard">
    <!--<mat-label>Search</mat-label>-->
    <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
  </mat-form-field>
  <mat-radio-group [(ngModel)]="selectedRow" (change)="radioSelected()">
    <mat-radio-button [value]="1">Morning</mat-radio-button>
    <mat-radio-button [value]="2">Evening</mat-radio-button>
  </mat-radio-group>
  <br />
  <label>Filter Amount >=</label>
  <input type="number" placeholder="0" (change)="ChangeFilter($event)" [(ngModel)]="filerAmt" style="font-weight: bold;" width="100px" />
  <br /><b>Filter Year</b>
  <select id="itemDescFormId" name="itemDescFormId" (change)="onYearSelect($event.target.value)">
    <option *ngFor="let years of yearSet" value="{{years}}">{{years}}</option>
  </select>
  <br /><b>Select Course</b>
  <ng-multiselect-dropdown [placeholder]="'Courses'"
                           [settings]="dropdownSettings"
                           [data]="Courses"
                           [(ngModel)]="selectedItems"
                           (change)="onItemSelect($event)"
                           (onSelect)="onItemSelect($event)"
                           (onSelectAll)="onSelectAll($event)"
                           (onDeSelect)="onDeSelect($event)">
  </ng-multiselect-dropdown>
  <div class="export-container">
    <button mat-raised-button color="primary" (click)="CreateExcelExport()">Export (Excel)</button>
  </div>
  <div class="mat-elevation-z8">
    <div class="cf" id="pdfTable" #pdfTable>
      <table id="StudPrevDuesDet" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="admNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enrolment# </th>
          <td mat-cell *matCellDef="let element"> {{element.admNo}} </td>
        </ng-container>
        <ng-container matColumnDef="studentName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.studentName}} </td>
        </ng-container>
        <ng-container matColumnDef="course">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Course </th>
          <td mat-cell *matCellDef="let element"> {{element.course}} </td>
        </ng-container>
        <ng-container matColumnDef="headName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Head Name </th>
          <td mat-cell *matCellDef="let element"> {{element.headName}} ({{element.yearCodePrev}}) </td>
        </ng-container>
        <ng-container matColumnDef="trndt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Trn.Dt. </th>
          <td mat-cell *matCellDef="let element"> {{element.trnDt}} </td>
        </ng-container>
        <ng-container matColumnDef="bankno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Receipt No. </th>
          <td mat-cell *matCellDef="let element"> {{element.bankNo}} </td>
        </ng-container>
        <ng-container matColumnDef="duesPending">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Opening/PrevYr. Dues </th>
          <td mat-cell *matCellDef="let element"> {{element.duesPending}} </td>
        </ng-container>
        <ng-container matColumnDef="feeAdjusted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Amt. Adjusted </th>
          <td mat-cell *matCellDef="let element"> {{element.feeAdjusted}} </td>
        </ng-container>
        <ng-container matColumnDef="paidAmt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fee Paid </th>
          <td mat-cell *matCellDef="let element"> {{element.paidAmt}} </td>
        </ng-container>
        <ng-container matColumnDef="balanceAmt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Bal.Amt </th>
          <td mat-cell *matCellDef="let element"> {{element.balanceAmt}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row [ngClass]="{'make-gold': row.headName == 'PrevDues Bal.'}" *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[15, 45, 60, 75, 90, 105, 120]" showFirstLastButtons></mat-paginator>
  </div>
</div>
