export class Student {
  aid: number;
  admno: string;
  name: string;
  clsname: string;
  feemode: string;
  maxRno: number;
  catdesc: string;
  //c_Code: string;
  constructor() {
    this.aid = 0;
    this.admno ="";
    this.name ="";
    this.clsname = "";
    this.feemode = "";
    this.maxRno = 0;
    this.catdesc = "";
  }
}
