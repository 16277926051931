<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<head>
  <style>
    table, th, td {
      border: 1px solid grey;
      border-collapse: collapse;
      padding: 5px;
    }

      table tr:last-child {
        font-weight: bold;
      }

    table {
      table-layout: auto;
      width: auto !important;
    }
  </style>
  <!--<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.2.0/css/bootstrap.min.css" />
  <link rel="stylesheet" href="https://cdn.datatables.net/1.13.4/css/dataTables.bootstrap5.min.css" />-->
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@3.0.0-alpha2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp" crossorigin="anonymous" />
</head>
<body>
  <div class="container">
    <h3>Fee Collection Report Date Wise ({{ShiftStr}}) A/Y {{GLBappFormStarEnd}}</h3>
    <table id="data" class="table table-bordered" cellspacing="0" style="width:100%">
      <tr>
        <th> S.No</th>
        <th> Name</th>
        <th> Enrolment No./Period/Date</th>
        <th> Branch</th>
        <th> Tuition Fees</th>
        <th> Student Activity Fees</th>
        <th> University Charges</th>
        <th> Book Bank</th>
        <th> Caution Money</th>
        <th> Re-Check</th>
        <th> Re-Appear</th>
        <th> Inurance ICard</th>
        <th> Other Charges</th>
        <th> Grand Total</th>
        <th> Banker's Name'</th>
        <th> Mode of Payment</th>
        <th> Remarks</th>
      </tr>
      <tr *ngFor="let feedet of feedetails| paginate: { itemsPerPage: 16, currentPage: p, totalItems: total }; let i=index">
        <td>{{feedet.fee11}}</td>
        <td> {{ feedet.studentname }}</td>        
        <td>{{ feedet.trnno }}<br />{{ feedet.trndt | date:'dd-MM-yyyy'}}</td>
        <td width="50%">{{ feedet.csdesc }}</td>
        <td>{{ feedet.tuitFee }}</td>
        <td>{{ feedet.stdActFee }}</td>
        <td>{{ feedet.univCharges }}</td>
        <td>{{ feedet.bookBank }}</td>
        <td>{{ feedet.cauMon }}</td>
        <td>{{ feedet.reChk }}</td>
        <td>{{ feedet.reApp }}</td>
        <td>{{ feedet.insuIcard }}</td>
        <td>{{ feedet.othChrg }}</td>
        <td>{{ feedet.grandtotal }}</td>
        <td>{{ feedet.drawnAt }}</td>
        <td>{{ feedet.cashchqddno }}</td>
        <td>{{ feedet.remarks }}</td>
      </tr>      
    </table>
    <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
  </div>
  <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
  <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossorigin="anonymous"></script>
  <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js" integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV" crossorigin="anonymous"></script>
</body>
</html>
