export class GlobalComponent {

  public static appYearCode: number = Number(sessionStorage.getItem('YearCode'));
  public static appYCode: number = Number(sessionStorage.getItem('YCode'));
  public static appShiftId: number = Number(sessionStorage.getItem('iShiftId'));
  public static appClientId: number = Number(sessionStorage.getItem('ClientId'));
  public static appUserId: number = Number(sessionStorage.getItem('usercode'));

  //public static GLBappUserCode: number = 0;
  //public static GLBappInstCode: number = 0;
  //public static GLBappUserName: string = "";
  //public static GLBappInstName: string = "";
  //public static GLBappFYearCode: number = 0;
  //public static GLBappFYearDesc: string = "";
  //public static GLBappFormType: number = 0;
  //public static GLBappFormVersion: string = "";
  //public static GLBappServerAddress: string = "";


  //public static GLBappUrl: string = "https://www.example.com/";
  //public static appLogo: string = "assets/images/logo.png";
  //public static appEmail: string = "johndoe@example.com";

}
