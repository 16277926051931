import { Component, OnInit, ViewChild } from '@angular/core';
import { DateChangeSetService } from '../datechangeset/datechangeset.service';
import { formatDate, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Pipe } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { exit } from 'process';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'datechangeset',
  templateUrl: `datechangeset.component.html`,  
})
export class DateChangeSetComponent implements OnInit {
  @ViewChild('noofinstallments') inputName;
  pipe = new DatePipe('en-US');
  now = Date.now();
  admno: any;
  StudentShift: any;
  GLBappFormStarEnd: string = "";
  todayDate: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  Admdate: any;
  Efdate: any;
  Student: any = [];
  StudentDD: any;
  Fathermail: string = "";
  Mobile2: string = "";
  Mobile1: string = "";
  Father: string = "";
  Mother: string = "";
  SName: string = "";
  RollNo: string = "";
  
  public Courses: any;
  Category: any[];
  CheckBool: boolean;
  checkedM: string;
  checkedF: string;
  checkedO: string;
  checkedNew: string;
  checkedShiftM: string;
  checkedShiftE: string;
  checkedOld: string;
  UserForm: FormGroup;
  STUDADMNO: string = "";
  STUDADMNONEW: string = "";
  GLBappFormType: string = "";
  GLBappUserCode: string = "";
  UserName: any;
  date: string = '';
  GLBappFormName: string = "";
  isChecked: boolean = false;
  ByGender: string = '';
  ByShift: number = 0;
  ByType: string = '';
  IPAddress: string = "";
  constructor(private studService: DateChangeSetService, private fb: FormBuilder, private router: Router) {
    this.todayDate = this.pipe.transform(sessionStorage.getItem('currentdate'), 'yyyy-MM-dd');
    this.IPAddress = sessionStorage.getItem('ipaddress');
    this.Courses = "";
    this.GLBappFormName = "Enrolment Change";
    this.ByGender = 'M';
    this.ByType = 'F';
    this.StudentShift = "";
  }

  onChangeDate(event: any) {
    //this.pipe.transform(this.now, 'yyyy-MM-dd');
    sessionStorage.setItem('currentdate', this.pipe.transform(event.target.value, 'yyyy-MM-dd'));    
  }

  ngOnInit() {
    //debugger;
    this.GLBappFormStarEnd = sessionStorage.getItem('FYYear');
    if (parseInt(sessionStorage.getItem('usercode')) == 0 || sessionStorage.getItem('usercode') == null) {
      this.router.navigate(['/logoutuser'])
        .then(() => {
          window.location.reload();
        });
      return;
    }    
  }


  onFormSubmit() {
    //Admno: 431915
    debugger;
    this.CheckBool = true;      
    this.studService.DateSetChanged(this.pipe.transform(sessionStorage.getItem('currentdate'), 'yyyy-MM-dd')).subscribe((response) => {      
          //window.alert('New Date Updated Successfully');
          Swal.fire({
            html: "<strong>New Date Updated Successfully.</strong>",
            icon: 'info',
            showConfirmButton: true,
            confirmButtonColor: "#338921",
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.value) {
              this.router.navigate(['/student-master'])
                .then(() => {
                  window.location.reload();
                });
              this.ngOnInit();
            }
          })          
      }, (error) => {
        console.log(error);
      });
    //}
  }

  public SetGetNullValueStr(parametername: string) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return "NULL";
    }
  }

  public SetGetNullValueInt(parametername: any) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return Number(0);
    }
  }

  public SetGetNullValueDate(parametername: any) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return '1900-01-01';
    }
  }
   
  
}
