import { Component, OnInit } from '@angular/core';
import { FeecollectionService } from './feecollection.service';

@Component({
  selector: 'fee-collection',
  templateUrl: './feecollection.component.html'
})
export class FeecollectionComponent implements OnInit {
  feedetails: any;  
  totalcolumnssum: Array<9>;
  p: number = 1;
  total: number = 0;
  total1: number = 0;
  GLBappFormStarEnd: string = "";
  ShiftStr: string = sessionStorage.getItem('ShiftStr');
  constructor(private service: FeecollectionService) {
    this.total1 = 0;
    this.GLBappFormStarEnd = sessionStorage.getItem('FYYear');
  }

  /*------------------------------------------
  --------------------------------------------
  About 
  --------------------------------------------
  --------------------------------------------*/
  ngOnInit() {
    debugger;
    this.getFeeCollectionDWise();
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  getFeeCollectionDWise() {
    debugger;
    this.service.getFeeCollectionDWise()
      .subscribe((response: any) => {
        this.feedetails = response;
        this.total = response.length;
      }, (error) => {
        console.log(error);
      });
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  pageChangeEvent(event: number) {
    this.p = event;
    this.getFeeCollectionDWise();
  }
}
