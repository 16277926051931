<!doctype html>
<html lang="en">
<head>
  <title>Drop</title>
  <meta charset="UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.5.0/css/all.css">
</head>
<body>
      <article class="card-body mx-auto" style="max-width: 400px;">
        <h4 class="card-title mt-3 text-center">Create Account</h4>
        <p class="text-center">Get started with your free account</p>
        <p>
          <a href="#" class="btn btn-block btn-twitter"> <i class="fab fa-twitter"></i>   Login via Twitter</a>
          <a href="#" class="btn btn-block btn-facebook"> <i class="fab fa-facebook-f"></i>   Login via facebook</a>
        </p>
        <p class="divider-text">
          <span class="bg-light">OR</span>
        </p>
        <form>
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-user"></i> </span>
            </div>
            <input name="" class="form-control" placeholder="Full name" type="text">
          </div> <!-- form-group// -->
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-envelope"></i> </span>
            </div>
            <input name="" class="form-control" placeholder="Email address" type="email">
          </div> <!-- form-group// -->
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-phone"></i> </span>
            </div>
            <select class="custom-select" style="max-width: 120px;">
              <option selected="">+91</option>
              <option value="1">+001</option>
              <option value="2">+020</option>
              <option value="3">+011</option>
            </select>
            <input name="" class="form-control" placeholder="Phone number" type="text">
          </div> <!-- form-group// -->
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-building"></i> </span>
            </div>
            <select class="form-control">
              <option selected=""> Select job type</option>
              <option>Web Developer</option>
              <option>Full Stack Developer</option>
              <option>Mean Stack</option>
            </select>
          </div> <!-- form-group end.// -->
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
            </div>
            <input class="form-control" placeholder="Create password" type="password">
          </div> <!-- form-group// -->
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
            </div>
            <input class="form-control" placeholder="Repeat password" type="password">
          </div> <!-- form-group// -->
          <div class="form-group">
            <button type="button" class="btn btn-primary btn-block"> Create Account</button>
          </div> <!-- form-group// -->
          <br /><br />
          <p class="text-center">Have an account? <a href="#">Log In</a> </p>
        </form>
      </article>    
</body>
</html>
<!--<h1>Create</h1>
<h4>Student_Master</h4>
<hr />
<div class="row">
  <div class="col-md-4">
    <form asp-action="Create">
      <div asp-validation-summary="ModelOnly" class="text-danger"></div>
      <div class="form-group">
        <label asp-for="Adm_No" class="control-label">Adm No/Enrol No</label>
        <input asp-for="Adm_No" class="form-control" />
        <span asp-validation-for="Adm_No" class="text-danger"></span>
      </div>
      <div class="form-group">
        <label asp-for="Student_Name" class="control-label">Student Name</label>
        <input asp-for="Student_Name" class="form-control" />
        <span asp-validation-for="Student_Name" class="text-danger"></span>
      </div>
      <div class="form-group">
        <label asp-for="DOB" class="control-label">Date of Birth</label>
        <input asp-for="DOB" class="form-control" />
        <span asp-validation-for="DOB" class="text-danger"></span>
      </div>
      <div class="form-group">
        <input type="submit" value="Create" class="btn btn-primary" />
      </div>
    </form>
  </div>
</div>
<div>
  <a asp-action="Index">Back to List</a>
</div>-->
<!--<div class="container">
  <h1>Fees</h1>
</div>-->
<!--<div class="container">

  <button mat-button (click)="openDialog('Add',{})" mat-flat-button color="primary">Add Employee</button>

  <table mat-table [dataSource]="dataSource" #mytable class="my-table mat-elevation-z8">
- Note that these columns can be defined in any order.
    The actual rendered columns are set as a property on the row definition"
 Name Column
<ng-container matColumnDef="name">
  <th mat-header-cell *matHeaderCellDef> NewName </th>
  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
</ng-container>

<ng-container matColumnDef="age">
  <th mat-header-cell *matHeaderCellDef> NewAge </th>
  <td mat-cell *matCellDef="let element"> {{element.age}} </td>
</ng-container>

<ng-container matColumnDef="designation">
  <th mat-header-cell *matHeaderCellDef> NewDesignation </th>
  <td mat-cell *matCellDef="let element"> {{element.designation}} </td>
</ng-container>


<ng-container matColumnDef="mobileNumber">
  <th mat-header-cell *matHeaderCellDef> NewMobileNumber </th>
  <td mat-cell *matCellDef="let element"> {{element.mobileNumber}} </td>
</ng-container>
 Action Column
<ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element" class="action-link">
        <a (click)="openDialog('Update',element)">Edit</a> |
        <a (click)="openDialog('Delete',element)">Delete</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>-->
