import { Component } from '@angular/core';
import { AppService } from './app.service';
import { Pipe } from '@angular/core';
import { formatDate, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
/*import { GlobalComponent } from 'global-component';*/

//interface LoggedDetails {
//  id: number;
//  name: string;
//  nickname: string;
//  description: string;
//}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./css/radiostyle.scss', './css/style.css', './css/style_o.css']
})
export class AppComponent {
  title = 'Fee Management System';
  subtitle = 'Student Fees Dashboard';
  pipe = new DatePipe('en-US');
  public getFyYear: any;
  now = Date.now();
  clickedOrNot: boolean;
  checked2024: string;
  checked2023: string;
  UserDD: any;
  selectedOrder: number;
  UserDet: any = [];
  KeyAny: any = [];
  todayDate: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  GLBappUserCode: string = "";
  GLBappInstCode: number = 0;
  GLBappUserName: string = "";
  GLBappInstName: string = "";
  GLBappFYearCode: number = 0;
  GLBappFYearCodeC: number = 0;
  GLBappFYearDesc: string = "";
  GLBappFormType: string = "";
  GLBappFormName: string = "";
  GLBappFormVersion: string = "";
  GLBappServerAddress: string = "";
  IPAddress: string = "";

  constructor(private appService: AppService, private router: Router) {
    this.selectedOrder = 0;
    this.checked2024 = "checked";
    this.checked2023 = "";
    this.clickedOrNot = false;
    //sessionStorage.setItem('YCode', "15");
    //sessionStorage.setItem('YearCode', "2024");    
    sessionStorage.setItem('ipaddress', sessionStorage.getItem('ipaddress2'));
    this.IPAddress = sessionStorage.getItem('ipaddress2');
    //sessionStorage.setItem('firsttime', "0");    
    //sessionStorage.setItem('NbaseURLs', 'https://localhost:5001/api/NStud');
    sessionStorage.setItem('NbaseURLs', 'https://www.eduinst.in/api/NStud');
    sessionStorage.setItem('NbaseURLMail', 'https://www.eduinst.in/api/Mail');
    //sessionStorage.setItem('NbaseURLs', 'http://eduinsterp1699-001-site1.ftempurl.com/api/NStud');    
  }

  ngOnInit() {
    debugger;
    if (parseInt(sessionStorage.getItem('firsttime')) == 0 || sessionStorage.getItem('firsttime') == null) {
      sessionStorage.setItem('selectedYesFY', "0");
      sessionStorage.setItem('selectedFY', "0");
      this.appService.GetIPAddressPC().subscribe((ipaddress) => {
        this.IPAddress = ipaddress;
        sessionStorage.setItem('ipaddress', this.IPAddress);
        sessionStorage.setItem('ipaddress2', this.IPAddress);
        sessionStorage.setItem('firsttime', "2");
        this.router.navigate(['/loginuser'])
          .then(() => {
            //window.location.reload();
          });
        return;

      }, (error) => {
        console.log(error);
      });      
    }
    //if (parseInt(sessionStorage.getItem('selectedYesFY')) == 0 || sessionStorage.getItem('selectedYesFY') == null) {
    //  this.checked2024 = "checked";
    //  this.checked2023 = "";
    //  this.appService.GetFYDet().subscribe((getFydetails: any) => {
    //    this.getFyYear = getFydetails;
    //    sessionStorage.setItem('YearCode', this.getFyYear[0].yearCode);
    //    sessionStorage.setItem('FYYear', this.getFyYear[0].startEnd);
    //    sessionStorage.setItem('FileName2', this.getFyYear[0].fileName2);
    //    sessionStorage.setItem('YesTran', this.getFyYear[0].yesTran);
    //  }, (error) => {
    //    console.log(error);
    //  });
    //} else if (parseInt(sessionStorage.getItem('selectedYesFY')) == 1) {
    //  if (parseInt(sessionStorage.getItem('selectedFY')) == 0) {
    //    this.checked2024 = "checked";
    //    this.checked2023 = "";
    //  } else {
    //    this.checked2024 = "";
    //    this.checked2023 = "checked";
    //  }
    //}

    this.checked2024 = "checked";
    this.checked2023 = "";
    this.appService.GetFYDet().subscribe((getFydetails: any) => {
      this.getFyYear = getFydetails;
      sessionStorage.setItem('YCode', this.getFyYear[0].yCode);
      sessionStorage.setItem('YearCode', this.getFyYear[0].yearCode);
      sessionStorage.setItem('FYYear', this.getFyYear[0].startEnd);
      sessionStorage.setItem('FileName2', this.getFyYear[0].fileName2);
      sessionStorage.setItem('YesTran', this.getFyYear[0].yesTran);
      sessionStorage.setItem('ShiftStr', 'Morning');
      sessionStorage.setItem('iShiftId', "1");
      sessionStorage.setItem('Startfyr', this.getFyYear[0].startfyr);
      sessionStorage.setItem('Endfyr', this.getFyYear[0].endfyr);
      if (parseInt(sessionStorage.getItem('selectedYesFY')) == 1) {
        this.selectedOrder = parseInt(sessionStorage.getItem('selectedFY'));
        sessionStorage.setItem('YCode', this.getFyYear[this.selectedOrder].yCode);
        sessionStorage.setItem('YearCode', this.getFyYear[this.selectedOrder].yearCode);
        sessionStorage.setItem('FYYear', this.getFyYear[this.selectedOrder].startEnd);
        sessionStorage.setItem('FileName2', this.getFyYear[this.selectedOrder].fileName2);
        sessionStorage.setItem('YesTran', this.getFyYear[this.selectedOrder].yesTran);
        sessionStorage.setItem('ShiftStr', 'Morning');
        sessionStorage.setItem('iShiftId', "1");
        sessionStorage.setItem('Startfyr', this.getFyYear[this.selectedOrder].startfyr);
        sessionStorage.setItem('Endfyr', this.getFyYear[this.selectedOrder].endfyr);
        if (parseInt(sessionStorage.getItem('selectedFY')) == 0) {
          this.checked2024 = "checked";
          this.checked2023 = "";
        } else {
          this.checked2024 = "";
          this.checked2023 = "checked";
        }
        if (this.clickedOrNot) {
          this.clickedOrNot = false;
          this.router.navigate(['/transfees'])
            .then(() => {
              window.location.reload();
            });
        }
      }
    }, (error) => {
      console.log(error);
    });

    

    //sessionStorage.setItem('ipaddress', this.IPAddress);
    this.GLBappUserCode = sessionStorage.getItem('usercode');
    this.GLBappUserName = sessionStorage.getItem('uname');
    this.GLBappInstName = sessionStorage.getItem('instname');
    this.GLBappFormName = sessionStorage.getItem('formname');
    this.GLBappFYearCode = parseInt(sessionStorage.getItem('YearCode'));
    this.GLBappFYearCodeC = this.GLBappFYearCode + 1;

  }
  

  radioSelectedOrder(value) {
    debugger;
    this.selectedOrder = value;
    this.clickedOrNot = true;
    sessionStorage.setItem('selectedFY', value);
    sessionStorage.setItem('selectedYesFY', "1");
    this.appService.GetFYDet().subscribe((getFydetails: any) => {
      this.getFyYear = getFydetails;
      sessionStorage.setItem('YCode', this.getFyYear[this.selectedOrder].yCode);
      sessionStorage.setItem('YearCode', this.getFyYear[this.selectedOrder].yearCode);
      sessionStorage.setItem('FYYear', this.getFyYear[this.selectedOrder].startEnd);
      sessionStorage.setItem('FileName2', this.getFyYear[this.selectedOrder].fileName2);
      sessionStorage.setItem('YesTran', this.getFyYear[this.selectedOrder].yesTran);
      sessionStorage.setItem('ShiftStr', 'Morning');
      sessionStorage.setItem('iShiftId', "1");
      sessionStorage.setItem('Startfyr', this.getFyYear[this.selectedOrder].startfyr);
      sessionStorage.setItem('Endfyr', this.getFyYear[this.selectedOrder].endfyr);
      this.ngOnInit();
      //sessionStorage.setItem('YCode', this.getFyYear[0].yCode);
      //sessionStorage.setItem('YearCode', this.getFyYear[0].yearCode);
      //sessionStorage.setItem('FYYear', this.getFyYear[0].startEnd);
      //sessionStorage.setItem('FileName2', this.getFyYear[0].fileName2);
      //sessionStorage.setItem('YesTran', this.getFyYear[0].yesTran);
      //sessionStorage.setItem('ShiftStr', 'Morning');
      //sessionStorage.setItem('iShiftId', "1");
      //sessionStorage.setItem('Startfyr', this.getFyYear[0].startfyr);
      //sessionStorage.setItem('Endfyr', this.getFyYear[0].endfyr);
      
    }, (error) => {
      console.log(error);
    });    
  }

}
