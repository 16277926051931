import { Component } from '@angular/core';

@Component({
  selector: 'student-fees',
  templateUrl: `fees.component.html`,
  styleUrls: ['fees.component.css']
})

export class FeesComponent {
  constructor() { }
}
