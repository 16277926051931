<!-- Default form grid -->
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  <script type="text/javascript">
    const input = document.querySelector('input');
    input.valueAsDate = new Date();
  </script>
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>
  <script src="./js/Script.js"></script>
  <script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/angularjs/1.3.9/angular.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.all.min.js"></script>
  <link href="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.min.css" rel="stylesheet" />
  <style>
    @media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    }

    .div1 {
      width: 60px;
      height: 30px;
      border: 1px solid blue;
    }
  </style>
</head>
<body>
  <div class="container">
    <form (ngSubmit)="onFormSubmit()">
      <div class="row">
        <div class="col">
          <h3>{{GLBappFormName}} ({{GLBappFormStarEnd}})</h3>
        </div>
      </div>
      <div class="row">
        <div class="col">
          Set New Date
          <input tabindex="1" type="date" (change)="onChangeDate($event)" [(ngModel)]="todayDate" formControlName="TodayDate" class="form-control" required=required style="font-weight: bold;" />
        </div>
        <br />
        <div class="col">
          <div class="form-group">
            <input type="submit" name="submit" value="Submit/Update" class="btn btn-success btn-lg btn-block" />
          </div>
        </div>
      </div>
      <br /><br />
      <div class="row">
        <div class="col">
          <p style="color:purple;font-size:26px;font-weight:bold">Last Updated Date Set: {{ todayDate | date: 'dd/MM/yyyy' }}</p>
        </div>
      </div>
    </form>    
    <script type="text/javascript">
      $(document).on('keypress', 'input,select', function (e) {
        if (e.which == 13) {
          e.preventDefault();
          var $next = $('[tabIndex=' + (+this.tabIndex + 1) + ']');
          if (!$next.length) {
            $next = $('[tabIndex=1]');
          }
          $next.focus().click();
        }
      });
    </script>
  </div>
</body>
</html>
