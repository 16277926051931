import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeecollectionDatesService {

  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  baseURL_2 = 'https://localhost:44386/api';

  constructor(private httpClient: HttpClient) { }

  getFeeCollectionDWise(datefrom: any, dateto: any, YearCode: number, ShiftId: number, OrderBy: number, PayType: number, ByBank: number) {
    //debugger;
    return this.httpClient.get(this.baseURL + '/GetFeeCollectionDates?DFrom=' + datefrom + '&&DTo=' + dateto + '&&YearCode=' + YearCode + '&&ShiftId=' + ShiftId + '&&OrderNo=' + OrderBy + '&&PayType=' + PayType + '&&ByBank=' + ByBank)
    .pipe(retry(2), catchError(this.handleError));
  }
  GetFeeCollectionDatesYearShift(YearCode: number, ShiftId: number, OrderBy: number, PayType: number, ByBank: number): Observable<any> {
    //debugger;
    return this.httpClient.get(this.baseURL + '/GetFeeCollectionDatesYearShift?YearCode=' + YearCode + '&&ShiftId=' + ShiftId + '&&OrderNo=' + OrderBy + '&&PayType=' + PayType + '&&ByBank=' + ByBank)
      .pipe(retry(2), catchError(this.handleError));
  }

  GetFeeCollectionSummary(datefrom: any, dateto: any, YearCode: number, ShiftId: number, iCode: number, BRNO: number) {
    //debugger;
    return this.httpClient.get(this.baseURL + '/GetFeeCollectionSummary?DFrom=' + datefrom + '&&DTo=' + dateto + '&&YearCode=' + YearCode + '&&ShiftId=' + ShiftId + '&&iCode=' + iCode + '&&BRNO=' + BRNO);
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }  

}
