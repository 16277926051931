import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class FeeReConcilePrevService {
  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  constructor(private http: HttpClient) { }

  //ViewStudLedgerDet(Admno: string, YearCode: Number): Observable<any> {
  ViewStudLedgerDet(ShiftId: number, YearCode: number,CSCode: string): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/ViewStudFeeDetailsDetAlls?ShiftId=' + ShiftId + '&&YearCode=' + YearCode + '&&CSCode=' + CSCode)
    .pipe(retry(1), catchError(this.handleError));
  }

  ViewPrevDuesReconcile(ShiftId: number, YearCode: number): Observable<any> {
    debugger;    
    return this.http.get<any>(this.baseURL + '/ViewPrevDuesReconcile?ShiftId=' + ShiftId + '&&YearCode=' + YearCode);
      //.pipe(retry(1), catchError(this.handleError));
  }

  ViewStudLedgerDetSum(ShiftId: number, YearCode: number, CSCode: string): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/ViewStudFeeDetailsDetAllSum?ShiftId=' + ShiftId + '&&YearCode=' + YearCode + '&&CSCode=' + CSCode)
      .pipe(retry(1), catchError(this.handleError));
  }

  GetCoursesFilter(): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/GetCoursesFilter?YearCode=' + parseInt(sessionStorage.getItem('YearCode')))
      .pipe(retry(1), catchError(this.handleError));
  }

  ViewStudFeeDetailsDet(Admno: string,YearCode: Number): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/ViewStudFeeDetailsDet?Admno=' + Admno + '&&YearCode=' + YearCode);
  }
  ViewStudFeeDetailsDetAll(): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/ViewStudFeeDetailsDetAll')
    .pipe(retry(1), catchError(this.handleError));
  }
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
