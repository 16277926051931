import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class LogOutUserService {
  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  //resolved(captchaResponse: string) {
  //  console.log(`Resolved captcha with response: ${captchaResponse}`);
  //}
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token'
    }),
  };
  constructor(private formBuilder: FormBuilder, private http: HttpClient) { }

  LogOutCheck(UserId: number,IpAddress: string): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/LogOutCheck?UserId=' + UserId + '&&IpAddress=' + IpAddress)
      .pipe(retry(1), catchError(this.handleError));
  }


  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
