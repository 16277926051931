<h3>New Student BBank/Caution Amount Received {{GLBappFormStarEnd}}</h3>
<div class="container">
  <div *ngIf="loading!=''" class="cssload-container">
    <div class="cssload-speeding-wheel"></div>
  </div>
  <div *ngIf="loading!=''" class='fullscreenDiv'>
    <div class="center">Loading...Please Wait</div>
  </div>
  <!--<mat-form-field appearance="standard">-->
    <!--<mat-label>Search</mat-label>-->
    <!--<input matInput (keyup)="applyFilter($event)" placeholder="" #input>
  </mat-form-field>-->
  <mat-radio-group [(ngModel)]="selectedRow" (change)="radioSelected()">
    <mat-radio-button [value]="1">Morning</mat-radio-button>
    <mat-radio-button [value]="2">Evening</mat-radio-button>
  </mat-radio-group>
  <br /><b>Select Course</b>
  <ng-multiselect-dropdown [placeholder]="'Courses'"
                           [settings]="dropdownSettings"
                           [data]="Courses"
                           [(ngModel)]="selectedItems"
                           (change)="onItemSelect($event)"
                           (onSelect)="onItemSelect($event)"
                           (onSelectAll)="onSelectAll($event)"
                           (onDeSelect)="onDeSelect($event)">
  </ng-multiselect-dropdown>
  <div class="export-container">    
    <button mat-raised-button color="primary" (click)="createPdfNewStudBBCM()">Export Pending Fee (Pdf + Excel)</button>    
  </div>
  <!--<div class="mat-elevation-z8">
    <div class="cf" id="pdfTable" #pdfTable>
      <table id="ExampleTable" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="rno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> SNo </th>
          <td mat-cell *matCellDef="let element"> {{element.rNo}}  </td>
        </ng-container>
        <ng-container matColumnDef="admno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enrolment# </th>
          <td mat-cell *matCellDef="let element"> {{element.admNo}}  </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.studentName}}  </td>
        </ng-container>
        <ng-container matColumnDef="course">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Course </th>
          <td mat-cell *matCellDef="let element"> {{element.course}}  </td>
        </ng-container>
        <ng-container matColumnDef="bookbank">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Book Bank </th>
          <td mat-cell *matCellDef="let element"> {{element.bookBank}} </td>
        </ng-container>
        <ng-container matColumnDef="cautionmoney">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Caution Money </th>
          <td mat-cell *matCellDef="let element"> {{element.cautionMoney}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row [ngClass]="{'make-gold': row.sno == 'Subtotal:'}" *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>      
    </div>
    <mat-paginator [pageSizeOptions]="[15, 45, 60, 75, 90, 105, 120]" showFirstLastButtons></mat-paginator>
  </div>-->
</div>
<div class="table">
  <table class="table" #tableT>
    <thead class="thead-dark">
      <tr><td style="text-align:center;font-weight:bold;font-size:18px" colspan="9"><b>New Student Book Bank/Caution Money Received {{WhichShift}}</b></td></tr>
      <tr><td style="text-align:center;font-weight:bold;font-size:18px" colspan="9"><b>A/Year:{{GLBappFormStarEnd}}</b></td></tr>
      <tr>
        <th>SNo</th>
        <th>Enrolment#</th>
        <th>Student Name</th>
        <th>Course</th>
        <th>Book Bank</th>
        <th>Caution Money</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of listDataC;let i=index">
        <td>{{i+1}}</td>
        <td t="s"><b>{{item.admNo}}</b></td>
        <td data-f-bold="true">{{item.studentName}}</td>
        <td>{{item.course}}</td>
        <td>{{item.bookBank}}</td>
        <td>{{item.cautionMoney}}</td>        
      </tr>
    </tbody>
  </table>
</div>
