//04176803121
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Data, Router } from '@angular/router';
import { SearchStudentService } from './searchstudent.service'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { formatDate, DatePipe } from '@angular/common';
import { map } from 'rxjs/operators';
import { UserOptions } from 'jspdf-autotable'
import autoTable from 'jspdf-autotable'
import { Clipboard } from "@angular/cdk/clipboard";

@Component({
  selector: 'searchstudent',
  templateUrl: './searchstudent.component.html',
  styleUrls: ['./searchstudent.component.scss']
})

export class SearchStudentComponent implements OnInit {
  pipe = new DatePipe('en-US');
  selectedRow: number;
  loading: string = '';
  now = Date.now();
  GLBappFormStarEnd: string = "";
  prntOnce: number = 0;
  dataSource: any;
  linescount: number;
  WhichShift: string;
  TodayDate: any;
  PageNo: number;
  SD: string = "";
  PageYPoistion: number = 0;
  //data: any[] = [];
  YearCode: string = "";
  public listData = [];
  public listDataV = [];
  public listDataVIst = [];
  public rows = [];
  public rowsF = [];
  Student: any = [];
  SearchName: string;
  Amount1stYear: string = "";
  amountspace: string = "";
  AmountRs: string = "";
  TotalAmt: number = 0;
  FileName: string = "";
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  //@ViewChild('pdfTable1') pdfTable1!: ElementRef;
  @ViewChild('pdfTable') pdfTable!: ElementRef;
  StudFeeReceiptDet: any;
  PaymentMode: string = "";
  TranNo: string = "";
  NotesForIstYear: string = "";
  todayDate: any = this.pipe.transform(this.now, 'dd-MM-yyyy');
  constructor(public clipboard: Clipboard, private searchstudentservice: SearchStudentService, private router: Router) {
    this.rowsF = [];
    this.SearchName = "";
    this.linescount = 0;
    this.PageNo = 0;
    this.selectedRow = 1;
  }
  displayedColumns: string[] = ['admno', 'studentName', 'gender', 'fathername', 'course', 'admdt', 'leftdt', 'shift', 'feepaid', 'copy1'];

  CopyAdmno(admno: string) {        
    this.clipboard.copy(admno);
  }

  applyFilterSNE(event: Event) {
    this.prntOnce = 0;
    this.Student = "";
    const filterValue = (event.target as HTMLInputElement).value;
    this.SearchName = filterValue.trim();
    if (this.SearchName.length != 0) {
      this.TodayDate = this.pipe.transform(this.now, 'dd/MM/yyyy hh:mm:ss a');
      this.searchstudentservice.SearchByNameAdmnoEN(this.SearchName).subscribe((response) => {
        this.Student = response;
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.listData = response as Data[];
        //if (response[0].cscode == 1) {
        //  this.NotesForIstYear = "Fee Paid at GGSIPU During the time of Provisional Admission<br />TUITION FEE:40,000<br />UNIVERSITY OTHER DUES:20,000";
        //}
        //for (var k = 0; k < this.listData.length; k++) {
        //  this.rows.push([
        //    this.listData[k].admno,
        //    this.listData[k].studentName,
        //    this.listData[k].course,
        //    this.listData[k].bankno,
        //    this.listData[k].yearcode,
        //    this.listData[k].trndt,
        //    this.listData[k].paidAmt,
        //    this.listData[k].paymentMode
        //  ])
        //}
      }, (error) => {
        console.log(error);
      });


      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  applyFilterSN(event: Event) {
    this.prntOnce = 0;
    const filterValue = (event.target as HTMLInputElement).value;
    this.SearchName = filterValue.trim();
    if (this.SearchName.length != 0) {
      this.TodayDate = this.pipe.transform(this.now, 'dd/MM/yyyy hh:mm:ss a');
      this.searchstudentservice.SearchByNameAdmno(this.SearchName).subscribe((response) => {
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.listData = response as Data[];
        //if (response[0].cscode == 1) {
        //  this.NotesForIstYear = "Fee Paid at GGSIPU During the time of Provisional Admission<br />TUITION FEE:40,000<br />UNIVERSITY OTHER DUES:20,000";
        //}
        //for (var k = 0; k < this.listData.length; k++) {
        //  this.rows.push([
        //    this.listData[k].admno,
        //    this.listData[k].studentName,
        //    this.listData[k].course,
        //    this.listData[k].bankno,
        //    this.listData[k].yearcode,
        //    this.listData[k].trndt,
        //    this.listData[k].paidAmt,
        //    this.listData[k].paymentMode
        //  ])
        //}
      }, (error) => {
        console.log(error);
      });


      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }    
  }

  ngOnInit(): void {
    debugger;
    this.GLBappFormStarEnd = sessionStorage.getItem('FYYear');
    if (parseInt(sessionStorage.getItem('usercode')) == 0 || sessionStorage.getItem('usercode') == null) {
      this.router.navigate(['/logoutuser'])
        .then(() => {
          window.location.reload();
        });
      return;
    }
    this.loading = '';
  }
}
