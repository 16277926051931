<!-- Default form grid -->
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  <script type="text/javascript">
    const input = document.querySelector('input');
    input.valueAsDate = new Date();
  </script>
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>
  <script src="./js/Script.js"></script>
  <script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/angularjs/1.3.9/angular.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.all.min.js"></script>
  <link href="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.min.css" rel="stylesheet" />
  <style>
    @media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    }

    .div1 {
      width: 60px;
      height: 30px;
      border: 1px solid blue;
    }
  </style>
</head>
<body>
  <div class="container">
    <form [formGroup]="UserForm" (ngSubmit)="onFormSubmit()">
      <div class="row">
        <div class="col">
          <h3>{{GLBappFormName}} {{GLBappFormStarEnd}}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col">
          Enrol/Admn No.
          <input class="form-control" tabindex="2" type="text" name="admno" formControlName="Admno" id="userIdFirstWay" list="dynmicUserIds" placeholder="Enrol/Admn No." (change)="onChange($event)" required=required style="font-weight: bold;" />
          <datalist id="dynmicUserIds">
            <option *ngFor="let stud of StudentDD" [value]="stud.admno" style="font-weight: bold;">{{stud.name}}</option>
          </datalist>
          <h5 style="color:purple;font-weight:bold;">{{StudentShift}}</h5>
          <h5 style="color: red;font-weight:bold;">{{leftOn}}</h5>          
        </div>
        <div class="col">
          Student Name
          <input type="text" style="font-weight:bold" class="form-control" value="{{Student.name}}" [(ngModel)]="Student.name" formControlName="SName" />
        </div>
        <div class="col">
          Roll No
          <input type="text" style="font-weight:bold" class="form-control" value="{{Student.rollNo}}" [(ngModel)]="Student.rollNo" formControlName="RollNo" />
        </div>
        <div class="col">
          Adm. Date
          <input tabindex="1" type="date" class="form-control" [(ngModel)]="Admdate" formControlName="Admdate" required=required style="font-weight: bold;" />
        </div>
        <div class="col">
          Eff. Date
          <input tabindex="1" type="date" class="form-control" [(ngModel)]="Efdate" formControlName="Efadmdate" required=required style="font-weight: bold;" />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col">
          <b>Student Type </b>          
          <!--<input style="font-weight:bold" formControlName="StudType" />-->
          <div class="form-check form-check-inline">
            <input mdbRadio=mdbRadio
                   class="form-check-input"
                   type="radio"
                   name="StudTypes"
                   id="New"
                   value="F"
                   formControlName="StudTypes" checked={{checkedNew}} (change)="onTypesChange($event.target.value)" />
            <label class="form-check-label" for="New"> New</label>
          </div>
          <div class="form-check form-check-inline">
            <input mdbRadio=mdbRadio
                   class="form-check-input"
                   type="radio"
                   name="StudTypes"
                   id="Old"
                   value="R"
                   formControlName="StudTypes" checked={{checkedOld}} (change)="onTypesChange($event.target.value)" />
            <label class="form-check-label" for="Old"><b> Old</b></label>
          </div>
        </div>
        <div class="col">
          <b>Gender </b>
          <div class="form-check form-check-inline">
            <input mdbRadio=mdbRadio
                   class="form-check-input"
                   id="male"
                   type="radio"
                   value="M"
                   name="Sex"
                   formControlName="Sex" checked={{checkedM}} (change)="onGenderChange($event.target.value)" />
            <label class="custom-control-label" for="male"> Male</label>
          </div>
          <div class="form-check form-check-inline">
            <input mdbRadio=mdbRadio
                   class="form-check-input"
                   id="female"
                   type="radio"
                   value="F"
                   name="Sex"
                   formControlName="Sex" checked={{checkedF}} (change)="onGenderChange($event.target.value)" />
            <label class="custom-control-label" for="female"> Female</label>
          </div>
          <div class="form-check form-check-inline">
            <input mdbRadio=mdbRadio
                   class="form-check-input"
                   id="other"
                   type="radio"
                   value="O"
                   name="Sex"
                   formControlName="Sex" checked={{checkedO}} (change)="onGenderChange($event.target.value)" />
            <label class="custom-control-label" for="other"> Other</label>
          </div>
        </div>
        <div class="col">
          <b>Shift </b>
          <div class="form-check form-check-inline">
            <input mdbRadio=mdbRadio
                   class="form-check-input"
                   id="morning"
                   type="radio"
                   value="1"
                   name="Shift"
                   formControlName="Shift" checked={{checkedShiftM}} (change)="onShiftChange($event.target.value)" />
            <label class="custom-control-label" for="morning"> Morning</label>
          </div>
          <div class="form-check form-check-inline">
            <input mdbRadio=mdbRadio
                   class="form-check-input"
                   id="evening"
                   type="radio"
                   value="2"
                   name="Shift"
                   formControlName="Shift" checked={{checkedShiftE}} (change)="onShiftChange($event.target.value)" />
            <label class="custom-control-label" for="evening"> Evening</label>
          </div>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col">
          Course
          <select id="itemDescFormId" name="itemDescFormId" formControlName="Cscode">
            <option *ngFor="let course of Courses; let i = index" value="{{course.cscode}}" [selected]="course.cscode==Student.cscode">{{course.cldesc}}</option>
          </select>
        </div>
        <div class="col">
          Category
          <input type="text" style="font-weight:bold" value="General" readonly />
          <!--<select id="dynamicCategory" formControlName="Catcode">
    <option *ngFor="let cat of Category; let i = index" value="{{cat.catcode}}" [selected]="i == 1" style="font-weight: bold;">{{cat.catdesc}}</option>
  </select>-->
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col">
          Father's Name
          <input type="text" style="font-weight:bold" class="form-control" value="{{Student.father}}" [(ngModel)]="Student.father" formControlName="Father" />
        </div>
        <div class="col">
          Mother's Name
          <input type="text" style="font-weight:bold" class="form-control" value="{{Student.mother}}" [(ngModel)]="Student.mother" formControlName="Mother" />
        </div>
        <div class="col">
          WhatsApp No.(1)
          <input type="text" style="font-weight:bold" class="form-control" value="{{Student.mobile1}}" [(ngModel)]="Student.mobile1" formControlName="Mobile1" />
        </div>
        <div class="col">
          WhatsApp No.(2)
          <input type="text" style="font-weight:bold" class="form-control" value="{{Student.mobile2}}" [(ngModel)]="Student.mobile2" formControlName="Mobile2" />
        </div>
        <div class="col">
          E-mail
          <input type="text" style="font-weight:bold" class="form-control" value="{{Student.fathermail}}" [(ngModel)]="Student.fathermail" formControlName="Fathermail" />
        </div>
      </div>
      <br />
      <div class="col">
        <div class="form-group">
          <h5 style="color: purple;font-weight:bold;">{{StudentShift}}</h5>
          <h5 style="color: red;font-weight:bold">{{leftOn}}</h5>
          <input type="submit" name="submit" value="Submit/Update" class="btn btn-success btn-lg btn-block" />
        </div>
      </div>
    </form>
    <script type="text/javascript">
      $(document).on('keypress', 'input,select', function (e) {
        if (e.which == 13) {
          e.preventDefault();
          var $next = $('[tabIndex=' + (+this.tabIndex + 1) + ']');
          if (!$next.length) {
            $next = $('[tabIndex=1]');
          }
          $next.focus().click();
        }
      });
    </script>
  </div>
</body>
</html>
