<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<head>
  <script type="text/javascript">
    const input = document.querySelector('input');
    input.valueAsDate = new Date();
  </script>
  <style>
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #dddddd;
    }
  </style>
  <!--<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.2.0/css/bootstrap.min.css" />
  <link rel="stylesheet" href="https://cdn.datatables.net/1.13.4/css/dataTables.bootstrap5.min.css" />-->
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@3.0.0-alpha2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp" crossorigin="anonymous" />
</head>
<body>
  <div class="container">
    <div id="pdfContent" #pdfContent>
      <form [formGroup]="UserForm">
        <h4>Fee Re-Conciliation A/Y {{GLBappFormStarEnd}} ({{ShiftStr}})</h4>
        <mat-radio-group [(ngModel)]="selectedRow" (change)="radioSelected($event)">
          <mat-radio-button [checked]="true" [value]="1">Morning</mat-radio-button>
          <mat-radio-button [value]="2">Evening</mat-radio-button>
        </mat-radio-group>        
        <br />        
        <div class="export-container">
          <button mat-raised-button=mat-raised-button color="primary" (click)="CreateExcelExport()">Export (Excel)</button>
        </div>
        <table style="background-color:white; border-collapse: collapse; width: 100%; height: 244px;" border="1">
          <ng-container *ngFor="let feedet of feedetails | filter:searchText | paginate: { itemsPerPage: 50, currentPage: p, totalItems: total }; let i=index">
            <tr *ngIf="i==0">
              <td style="width: 9%; height: 18px; text-align: center;"><strong>S.No</strong></td>
              <td style="width: 15%; height: 18px; text-align: center;"><strong>Course Name</strong></td>
              <td style="width: 12%; height: 18px; text-align: center;"><strong>Count to Pay</strong></td>
              <td style="width: 6%; height: 18px; text-align: center;"><strong>Count to Left</strong></td>
              <td style="width: 6%; height: 18px; text-align: center;"><strong>Count Paid</strong></td>
              <td style="width: 15%; height: 18px; text-align: center;"><strong>Head Name</strong></td>
              <td style="width: 12%; height: 18px; text-align: center;"><strong>Fee to Pay</strong></td>
              <td style="width: 12%; height: 18px; text-align: center;"><strong>Fee Paid</strong></td>
              <td style="width: 12%; height: 18px; text-align: center;"><strong>Balance</strong></td>
            </tr>
            <tr style="border-style: solid;height: 18px;">
              <td style="background-color: white; width: 9%; height: 108px; text-align: center; vertical-align: middle;" rowspan="6"><strong>{{i+1}}</strong></td>
              <td style="background-color: white; width: 15%; height: 108px; text-align: center; vertical-align: middle;" rowspan="6"><strong>{{ feedet.coursename }}</strong></td>
              <td style="background-color: white; width: 12%; height: 108px; text-align: center; vertical-align: middle;" rowspan="6"><strong>{{ feedet.studentcount }}</strong></td>
              <td style="background-color: white; width: 6%; text-align: center; vertical-align: middle; height: 108px;" rowspan="6"><strong>{{ feedet.leftstudent }}</strong></td>
              <td style="background-color: white; width: 6%; height: 108px; text-align: center; vertical-align: middle;" rowspan="6"><strong>{{ feedet.paidstudentcount }}</strong></td>
              <td style="width: 15%; height: 18px;"><strong>Tuition Fee</strong></td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.tutfee }}</td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.tutfeeP }}</td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.tutfee - feedet.tutfeeP }}</td>
            </tr>
            <tr style="border-style: solid;height: 18px;">
              <td style="width: 15%; height: 18px;"><strong>Student Activity Fee</strong></td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.actfee }}</td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.actfeeP }}</td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.actfee - feedet.actfeeP }}</td>
            </tr>
            <tr style="border-style: solid;height: 18px;">
              <td style="width: 15%; height: 18px;"><strong>Book Bank</strong></td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.bookbank }}</td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.bookbankP }}</td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.bookbank - feedet.bookbankP }}</td>
            </tr>
            <tr style="border-style: solid;height: 18px;">
              <td style="width: 15%; height: 18px;"><strong>Caution Money</strong></td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.cautionmoney }}</td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.cautionmoneyP }}</td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.cautionmoney - feedet.cautionmoneyP }}</td>
            </tr>
            <tr style="border-style: solid;height: 18px;">
              <td style="width: 15%; height: 18px;"><strong>University Dues</strong></td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.unidues }}</td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.uniduesP }}</td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.unidues - feedet.uniduesP }}</td>
            </tr>
            <tr style="border-style: solid;height: 18px;">
              <td style="width: 15%; height: 18px;"><strong>Insurance ID Card</strong></td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.insicard }}</td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.insicardP }}</td>
              <td style="width: 12%; height: 18px; text-align: right;">{{ feedet.insicard - feedet.insicardP }}</td>
            </tr>
            <!--<tr>
      <td style="width: 9%; height: 10px; border-style: solid; border-color: black; background-color: darkgrey; text-align: center;" colspan="9">&nbsp;</td>
    </tr>-->
          </ng-container>
          <tr>
            <td></td>
            <td style="background-color: grey;color: #ffffff; text-align: center;"><h3><b> SUMMARY </b></h3></td>
            <td style="text-align:center">{{tot1}}</td>
            <td style="text-align:center">{{ft1}}</td>
            <td style="text-align:center">{{totp1}}</td>
            <td><b>Tuition Fee</b></td>
            <td style="text-align:right">{{t1}}</td>
            <td style="text-align:right">{{tp1}}</td>
            <td style="text-align:right">{{t1 - tp1}}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>Student Activity Fee</b></td>
            <td style="text-align:right">{{s1}}</td>
            <td style="text-align:right">{{sp1}}</td>
            <td style="text-align:right">{{s1 - sp1}}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>Book Bank</b></td>
            <td style="text-align:right">{{b1}}</td>
            <td style="text-align:right">{{bp1}}</td>
            <td style="text-align:right">{{b1 - bp1}}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>Caution Money</b></td>
            <td style="text-align:right">{{c1}}</td>
            <td style="text-align:right">{{cp1}}</td>
            <td style="text-align:right">{{c1 - cp1}}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>University Dues</b></td>
            <td style="text-align:right">{{u1}}</td>
            <td style="text-align:right">{{up1}}</td>
            <td style="text-align:right">{{u1 - up1}}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>Insurance ID Card</b></td>
            <td style="text-align:right">{{i1}}</td>
            <td style="text-align:right">{{ip1}}</td>
            <td style="text-align:right">{{i1 - ip1}}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>Total</b></td>
            <td style="text-align:right">{{gtot1}}</td>
            <td style="text-align:right">{{gtotp1}}</td>
            <td style="text-align:right">{{gtot1 - gtotp1}}</td>
          </tr>
        </table>
      </form>
    </div>
    <div class="col-md-4">
      <button type="button" class="btn btn-secondary btn-lg btn-block"
              (click)="generatePDF('download')">
        Download Report
      </button>
      <button type="button" class="btn btn-secondary btn-lg btn-block"
              (click)="generatePDF('print')">
        Print Report
      </button>
      <button type="submit" class="btn btn-secondary btn-lg btn-block"
              (click)="generatePDF('open')">
        Open Report
      </button>
    </div>
    <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
    <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js" integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV" crossorigin="anonymous"></script>
  </div>
</body>
</html>
