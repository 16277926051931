<nav class="navbar navbar-expand-sm navbar-dark bg-secondary d-flex justify-content-between">
  <a class="navbar-brand" href="#">INVOICE GENERATOR</a>
  <div>
    <a class="text-white" href="https://github.com/ngdevelop-tech/angular-pdfmake-invoice-generator" target="_blank"><i class="fa fa-github fa-2x" aria-hidden="true"></i></a>
  </div>
</nav>
<form #invoiceForm="ngForm">
  <div class="container-fluid pt-2 mb-5">
    <div class="row">
      <div class="col-md-8">
        <div class="card border-secondary">
          <div class="card-body">
            <h4 class="card-title">Customer Details</h4>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input type="text" class="form-control" name="name" id="name"
                         [ngClass]="{'is-invalid': invoiceForm.submitted && CustName.invalid}"
                         [(ngModel)]="invoice.customerName" #CustName="ngModel" required=required />
                </div>
                <div class="form-group">
                  <label for="address">Address</label>
                  <textarea class="form-control" name="address" id="address" row="3"
                            [ngClass]="{'is-invalid': invoiceForm.submitted && Address.invalid}"
                            [(ngModel)]="invoice.address" #Address="ngModel" required=required></textarea>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="email">Email ID</label>
                  <input type="email" class="form-control" name="email" id="email"
                         [(ngModel)]="invoice.email" required=required />
                </div>
                <div class="form-group">
                  <label for="contactNo">Contact No.</label>
                  <input type="number" class="form-control" name="contactNo" id="contactNo"
                         [ngClass]="{'is-invalid': invoiceForm.submitted && ContactNo.invalid}"
                         [(ngModel)]="invoice.contactNo" #ContactNo="ngModel" required=required />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-secondary mt-2">
          <div class="card-body">
            <h4 class="card-title d-flex justify-content-between">
              Order Details <button type="button"
                                    class="btn btn-secondary" (click)="addProduct()">
                +
              </button>
            </h4>
            <div class="row">
              <table class="table">
                <thead>
                  <tr>
                    <th width="25%">Product</th>
                    <th width="25%">Price</th>
                    <th width="25%">Quantity</th>
                    <th width="25%">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let product of invoice.products; let i=index">
                    <td scope="row">
                      <input type="text" class="form-control" name="productName{{i}}"
                             [ngClass]="{'is-invalid': invoiceForm.submitted && ProductName.invalid}"
                             id="productName" [(ngModel)]="product.name" #ProductName="ngModel"
                             required=required />
                    </td>
                    <td>
                      <input type="number" class="form-control" name="price{{i}}" id="price"
                             [ngClass]="{'is-invalid': invoiceForm.submitted && ProductPrice.invalid}"
                             [(ngModel)]="product.price" #ProductPrice="ngModel" required=required />
                    </td>
                    <td>
                      <input type="number" class="form-control" name="quantity{{i}}" id="quantity"
                             [ngClass]="{'is-invalid': invoiceForm.submitted && ProductQty.invalid}"
                             [(ngModel)]="product.qty" #ProductQty="ngModel" required=required />
                    </td>
                    <td>
                      {{product.price * product.qty || ''}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card border-secondary mt-2">
          <div class="card-body">
            <h4 class="card-title">Additional Details</h4>
            <div class="form-group">
              <textarea class="form-control" name="additionalDetails" rows="3"
                        [(ngModel)]="invoice.additionalDetails"></textarea>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-4">
        <button type="button" class="btn btn-secondary btn-lg btn-block"
                (click)="invoiceForm.valid ? generatePDF('download') : ''">
          Download Invoice
        </button>
        <button type="button" class="btn btn-secondary btn-lg btn-block"
                (click)="invoiceForm.valid ? generatePDF('print') : ''">
          Print
          Invoice
        </button>
        <button type="submit" class="btn btn-secondary btn-lg btn-block"
                (click)="invoiceForm.valid ? generatePDF() : ''">
          Open
          Invoice
        </button>
      </div>
    </div>
  </div>
</form>
<footer class="fixed-bottom">
  <div class="bg-light text-center p-2">
    <h6>
      Made with 💗 by
      <a class="twitter-follow-button" href="https://twitter.com/ankit_ngdevelop" target="_blank">
        <i class="fa fa-twitter"
           aria-hidden="true"></i>Ankit_NgDevelop
      </a>.
    </h6>
  </div>
</footer>
