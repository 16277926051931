import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SearchStudentService {
  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  constructor(private http: HttpClient) { }

  //getAllArticles() {
  //  return All_ARTICLES;
  //ViewStudBalAmtEve}

  SearchByNameAdmno(StudName: string): Observable<any> {
    debugger;    
    return this.http.get<any>(this.baseURL + '/SearchByNameAdmno?StudName=' + StudName + '&&YearCode=' + parseInt(sessionStorage.getItem('YearCode')));
  }

  SearchByNameAdmnoEN(StudENo: string): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/SearchByNameAdmnoEN?StudENo=' + StudENo + '&&YearCode=' + parseInt(sessionStorage.getItem('YearCode')));
  }

  ViewFeeReceipt(Admno: string, BankNo: string, YearCode: number): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/ViewFeeReceipt?Admno=' + Admno + '&&BankNo=' + BankNo + '&&YearCode=' + YearCode);      
  }
  
}
