import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LeftCreatedPdfService {

  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  baseURL_2 = 'https://localhost:44386/api';

  constructor(private httpClient: HttpClient) { }
  //sessionStorage.setItem('Paymode', 'T');
  //      sessionStorage.setItem('Paymodestr', 'RGTS/IMPS/NEFT');
  GetViewStudLeftDet(YearCode: number,ShiftId: number) {
    debugger;
    return this.httpClient.get(this.baseURL + '/GetViewStudLeftDet?YearCode=' + YearCode + '&&ShiftId=' + ShiftId);
  }

  DeleteLeftCreated(TId: string, Yearcode: number, Admno: string)
  {
    debugger;
    return this.httpClient.delete(this.baseURL + '/DeleteLeftCreated?TId=' + TId + '&&YearCode=' + Yearcode + '&&Admno=' + Admno);
  }

}
