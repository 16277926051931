import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class FeecollectionService {

  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  baseURL_2 = 'https://localhost:44386/api';

  constructor(private httpClient: HttpClient) { }
  //sessionStorage.setItem('Paymode', 'T');
  //      sessionStorage.setItem('Paymodestr', 'RGTS/IMPS/NEFT');
  getFeeCollectionDWise(forDate: Date, ShiftId: number, UserId: number): Observable<any> {
    debugger;
    sessionStorage.setItem('firstPrccoess', '2');
    return this.httpClient.get(this.baseURL + '/GetFeeCollectionDateWise?forDate=' + forDate + '&&ShiftId=' + ShiftId + '&&Paymodestr=' + sessionStorage.getItem('Paymodestr') + '&&ByBank=' + sessionStorage.getItem('Bank') + '&&UserId=' + UserId)
      .pipe(retry(1), catchError(this.handleError));    
  }

  getFeeCollectionDWiseZero(forDate: Date, ShiftId: number): Observable<any> {
    sessionStorage.setItem('firstPrccoess', '2');
    return this.httpClient.get(this.baseURL + '/GetFeeCollectionDateWiseZero?forDate=' + forDate + '&&ShiftId=' + ShiftId + '&&Paymodestr=' + sessionStorage.getItem('Paymodestr') + '&&ByBank=' + sessionStorage.getItem('Bank'))
      .pipe(retry(1), catchError(this.handleError));
  }

  spChangeDateUpdate(TrnNo: string, BankNo: string, DateChange: string, YearCode: number): Observable<any> {
    return this.httpClient.get<any>(this.baseURL + '/spChangeDateUpdate?TrnNo=' + TrnNo.toString() + '&&BankNo=' + BankNo.toString() + '&&DateChange=' + DateChange.toString() + '&&YearCode=' + YearCode + '&&IpAddressN=' + sessionStorage.getItem('ipaddress2').toString())
  }

  spChangeDateUpdateAll(fromDateChange: string, NewDateChange: string, YearCode: number): Observable<any> {
    return this.httpClient.get<any>(this.baseURL + '/spChangeDateUpdateAll?fromDateChange=' + fromDateChange.toString() + '&&NewDateChange=' + NewDateChange.toString() + '&&YearCode=' + YearCode + '&&IpAddressN=' + sessionStorage.getItem('ipaddress2').toString() + '&&UserCode=' + sessionStorage.getItem('usercode').toString())
  }

  DeleteStudentFeeTran(MaxRNo: string, Yearcode: number, TrnNo: string)
  {
    return this.httpClient.delete(this.baseURL + '/DeleteStudentFeeTran?MaxRNo=' + MaxRNo + '&&Yearcode=' + Yearcode + '&&TrnNo=' + TrnNo);
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
