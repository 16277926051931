<!-- Default form grid -->
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width,initial-scale=1.0" />
  <script type="text/javascript">
    const input = document.querySelector('input');
    input.valueAsDate = new Date();
  </script>
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>
  <script src="./js/Script.js"></script>
  <script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/angularjs/1.3.9/angular.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.all.min.js"></script>
  <link href="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.min.css" rel="stylesheet" />
  <script type="text/javascript">
    function keyPresseditamt() {
      for (i = 0; i < event.length; i++) {
        window.alert(event[i]);
      }
    }
    function keyPresseditamtD() {
      for (i = 0; i < event.length; i++) {
        window.alert(event[i]);
      }
    }
  </script>
  <style>
    @media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    }

    .div1 {
      width: 60px;
      height: 30px;
      border: 1px solid blue;
    }
  </style>
</head>
<body>
  <div class="container">
    <!-- <form #form="ngForm" formControlName="" name="ngForm" (ngSubmit)="onSubmit($event)" [formGroup]="imageForm">-->
    <!--<form #form="ngForm" formControlName="" name="ngForm" (ngSubmit)="onSubmit(admno,Student[0].amt2,AmountToPayC,bankname,creditdebitno,receivedamt,processdate,transdate,chequedrawn,branchaddress,cheqdraftdate,cheqdraftno,userInput,userInputW)">-->
    <form [formGroup]="UserForm" (ngSubmit)="onFormSubmit()">
      <!-- Grid row
  <br />
  <div class="row">
    <!-- Grid column -->
      <div class="row">
        <div class="col">
          <h3>{{GLBappFormName}} {{GLBappFormStarEnd}}</h3>
        </div>
      </div>
      <div class="row">
        <!-- Grid column -->
        <div class="col">
          <!-- Default input -->
          Date
          <input tabindex="1" type="date" [(ngModel)]="todayDate" formControlName="TodayDate" class="form-control" style="font-weight: bold;" disabled=disabled />
        </div>
        <!-- Grid column -->
        <div class="col">
          <!-- Default input -->
          Enrol/Admn No.
          <input class="form-control" tabindex="2" type="text" name="admno" id="userIdFirstWay" list="dynmicUserIds" placeholder="Enrol/Admn No." (change)="onChange($event)" required=required [(ngModel)]="Student[0].admno" style="font-weight: bold;" readonly="{{Admnoinputdisabled}}" />
          <!--<input tabindex="2" type="text" (keyup)="getUserIdsFirstWay($event)" id="userIdFirstWay" list="dynmicUserIds" placeholder="Enrol/Admn No." required />-->
          <datalist id="dynmicUserIds">
            <option *ngFor="let stud of StudentDD" [value]="stud.admno" style="font-weight: bold;">{{stud.name}}</option>
          </datalist>
          <h5 style="color:purple;font-weight:bold;">{{StudentShift}}</h5><button style="background-color: #4CAF50;color:white;border-radius: 8px; font-size: 14px; height: 30px; width: 70px " (click)="RefreshPage()">Refresh</button>
          <h5 style="color: red;font-weight:bold;">{{leftOn}}</h5>
          <!--<input tabindex="2" type="text" (keyup)="getUserIdsFirstWay($event)" id="userIdFirstWay" list="dynmicUserIds" placeholder="Enrol/Admn No." required />
      <datalist id="dynmicUserIds">
        <option *ngFor="let item of userList1" [value]="item">{{item}}</option>
      </datalist>-->
          <!--<input id="usr" tabindex="2" type="text" class="form-control" placeholder="Enrol/Admn No." required />-->
        </div>
        <div class="col">
          <!-- Default input -->
          Student Name
          <input style="font-weight:bold" value="{{Student[0].name}}" readonly="readonly" />
          <!--<input id="name" tabindex="3" type="text" [(ngModel)]="UserName" class="form-control" placeholder="First name" formControlName="" name="UserName" required />-->
        </div>
        <div class="col">
          <!-- Default input -->
          Fee Period
          <input style="font-weight:bold" value="A/Y {{GLBappFormStarEnd}}" readonly="readonly" />
          <!--<input style="font-weight:bold" value="{{Student[0].feemode}}" readonly="readonly" />-->
          <!--<input id="feemode" type="text" class="form-control" [(ngModel)]="Student[0].feemode" placeholder="Fee Period" name="feemode" style="font-weight: bold;" readonly=readonly />-->
        </div>
        <div class="col">
          <!-- Default input -->
          Receipt No.
          <input style="font-weight:bold" value="{{Student[0].maxRno}}" />
          <!--<input id="maxRno" type="number" class="form-control" [(ngModel)]="Student[0].maxRno" placeholder="Receipt No." name="maxRno" style="font-weight: bold;" readonly=readonly />-->
        </div>
        <!-- Grid column -->
      </div>
      <br />
      <div class="row">
        <div class="col">
          <!-- Default input -->
          Student Type
          <input style="font-weight:bold" value="{{Student[0].oldnew}}" readonly="readonly" />
          <!--<input id="oldnew" type="text" class="form-control" [(ngModel)]="Student[0].oldnew" placeholder="Old/New" name="oldnew" style="font-weight: bold;" readonly=readonly />-->
        </div>
        <div class="col">
          <!-- Default input -->
          Gender
          <input style="font-weight:bold" value="{{Student[0].gender}}" readonly="readonly" />
          <!--<input id="gender" type="text" class="form-control" [(ngModel)]="Student[0].gender" placeholder="Gender" name="gender" style="font-weight: bold;" readonly=readonly />-->
        </div>
        <!-- Grid column -->
        <div class="col">
          <!-- Default input -->
          Course/Class Name
          <input style="font-weight:bold" value="{{Student[0].clsname}}" readonly="readonly" />
          <!--<input id="clsname" type="text" class="form-control" [(ngModel)]="Student[0].clsname" placeholder="Course Name" name="clsname" style="font-weight: bold;" readonly=readonly />-->
        </div>
        <div class="col">
          <!-- Default input -->
          Category
          <input style="font-weight:bold" value="{{Student[0].catdesc}}" readonly="readonly" />
          <!--<input id="catdesc" type="text" class="form-control" [(ngModel)]="Student[0].catdesc" placeholder="Category" name="catdesc" style="font-weight: bold;" readonly=readonly />-->
        </div>
        <div class="col">
          <!-- Default input -->
          Previous Amount
          <input style="font-weight:bold" value="{{Student[0].duesbalance}}" readonly="readonly" />
          <!--<input id="catdesc" type="text" class="form-control" [(ngModel)]="Student[0].duesbalance" placeholder="Previous Amount" name="duesbalance" style="font-weight: bold;" readonly=readonly />-->
        </div>
        <!-- Grid column -->
      </div>
      <br />
      <!--<div>{{LessPaidAmt}}</div>-->
      <div *ngIf='LessAmoutPaidBool'>
        <label>
          <b>Set Next Installment Date</b>
          Next Installment after <input class="div1" (input)="onChangeNoofdays(noofdays.value, noofinstallments.value)" type="number" name="noofdays" value="0" minlength="1" maxlength="3" #noofdays=#noofdays /> days. No. of
          <input class="div1" type="number" value="0" (input)="onChangeInstallments(noofdays.value, noofinstallments.value)" name="noofinstallments" #noofinstallments=#noofinstallments /> Installments
          <!--<input type="checkbox" (change)="onCheckboxChange(noofdays.value, noofinstallments.value,$event)" [checked]="isChecked" />Click to Confirm-->
          <br /><label style="color: darkred; font-weight: bold;"> {{NextDateString}}</label>
        </label>
      </div>
      <div class="row" style="width:100%">
        <div class="col">
          <h6 style="background-color:SlateBlue;color:White;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Academic Year Fee {{GLBappFormStarEnd}}</h6>
          <table id="table1" style="width:60%" name="table1" class="table1">
            <thead>
              <tr>
                <th>#</th>
                <th>Head Name</th>
                <th style="text-align: right;">Amount</th>
                <th style="text-align: right;">Less Paid</th>
                <th style="text-align: right;">Adjustment</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of StudentH; let i=index">
                <th scope="row">{{ i+1 }}</th>
                <th>{{item.headdesc}}</th>
                <td style="text-align: right; font-weight: bold;">{{ item.headamount }}.00</td>
                <td><input id="userInput[i]" type="text" class="form-control" placeholder="0" name="userInput[i]" (keyup)="keyPresseditamt(i,userInput,$event,Student[0].amt2,item.headamount,item.headcode)" style="width: 85px;font-weight: bold;" disabled="disabled" /></td>
                <td><input id="userInputW[i]" type="text" class="form-control" placeholder="0" name="userInputW[i]" (keyup)="keyPresseditamtW(i,userInputW,$event,Student[0].amt2,item.headamount,item.headcode)" style="width: 85px;font-weight: bold;" disabled="{{Amtinputdisabled}}" /></td>
              </tr>
              <tr>
                <th></th>
                <th>Total Fees:</th>
                <td style="text-align: right; font-weight: bold;">{{AmountToPayCM}}.00</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="StudInstallDet!=''">
            <table id="table1" style="width:60%" name="table1" class="table1">
              <thead>
                <tr>
                  <th>#</th>
                  <th style="text-align: right;">Created</th>
                  <th style="text-align: right;">Created Date</th>
                  <th style="text-align: right;">Installment Date</th>
                  <th style="text-align: right;">Install Amt</th>
                  <th style="text-align: right;">Receipt</th>
                  <th style="text-align: right;">Receipt Date</th>
                  <th style="text-align: right;">Paid Amt</th>
                </tr>
              </thead>
              <tbody>
                //{{ item.colorcode }}
                <tr *ngFor="let item of StudInstallDet; let i=index" style="background-color:darkmagenta">
                  <th scope="row">{{ i+1 }}</th>
                  <td style="text-align: right; font-weight: bold;">{{ item.banktranno }}</td>
                  <td style="text-align: right; font-weight: bold;">{{ item.createddate | date: 'dd/MM/yyyy' }}</td>
                  <td style="text-align: right; font-weight: bold;">{{ item.installdate | date: 'dd/MM/yyyy' }}</td>
                  <td style="text-align: right; font-weight: bold;">Rs.{{ item.installAmt }}</td>
                  <td style="text-align: right; font-weight: bold;">{{ item.bankno }}</td>
                  <td style="text-align: right; font-weight: bold;">{{ item.trndt | date: 'dd/MM/yyyy' }}</td>
                  <td style="text-align: right; font-weight: bold;">Rs.{{ item.paidAmt }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col">

          <div *ngIf="StudentDues.length !=0 ">
            <h6 style="background-color:SlateBlue;color:White;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Previous Year Outstanding Fees</h6>
            <table name="table2" style="width:35%" class="table1">
              <thead>
                <tr>
                  <th>Head Name</th>
                  <th style="text-align: right;">Opening Arrear</th>
                  <th style="text-align: right;">Less Paid</th>
                  <th style="text-align: right;">Adjustment</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let itemdues of StudentDues; let i=index">
                  <th>{{ itemdues.headdesc }}</th>
                  <td style="text-align: right; font-weight: bold;">{{ itemdues.headamount }}.00</td>
                  <td><input id="userInputD[i]" type="text" class="form-control" placeholder="0" name="userInputD[i]" (keyup)="keyPresseditamtD(i,userInputD,$event,Student[0].amt3,itemdues.headamount,itemdues.headcode)" style="width: 85px;font-weight: bold;" disabled="disabled" /></td>
                  <td><input id="userInputWD[i]" type="text" class="form-control" placeholder="0" name="userInputWD[i]" (keyup)="keyPresseditamtWD(i,userInputWD,$event,Student[0].amt3,itemdues.headamount,itemdues.headcode)" style="width: 85px;font-weight: bold;" /></td>
                </tr>
                <tr>
                  <th>Total Arrears:</th>
                  <td style="text-align: right; font-weight: bold;">{{ Student[0].amt9 }}.00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col">
          <h6 style="background-color:SlateBlue;color:White;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Final Amount To Pay</h6>
          <table class="table1" style="width:60%">
            <tbody>
              <tr>
                <td>
                  Late Fine
                  <input type="number" class="form-control" value="{{Student[0].amt1}}" placeholder="0" style="font-weight: bold;" readonly=readonly />
                </td>
                <td>
                  Fine Waive Off
                  <input type="number" class="form-control" formControlName="LateFine" placeholder="0" (keyup)="keyPressNumbers($event)" style="font-weight: bold;" />
                </td>
              </tr>
              <tr>
                <td>
                  Total Amount
                  <input type="number" class="form-control" style="font-weight:bold" value="{{Student[0].amt10}}" readonly=readonly />
                </td>
                <td>
                  Less Paid
                  <input type="number" class="form-control" style="font-weight:bold" value="{{LessPaidAmtF}}" placeholder="0" readonly=readonly />
                </td>
                <td>
                  Adjustment Fees
                  <input type="number" class="form-control" value="{{WaivedOffAmtF}}" placeholder="0" style="font-weight: bold;" readonly=readonly />
                </td>
              </tr>
              <tr>
                <td>
                  <h5>Final Amount To Pay</h5>
                  <label class="label1">Rs.{{AmountToPayC}}/-</label>
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="Student[0].amt8!=0 && Student[0].amt9==0 " class="form-check form-check-inline">
            <input mdbRadio=mdbRadio
                   class="form-check-input"
                   type="radio"
                   name="flexRadioDefault6"
                   id="flexRadioDefault7"
                   checked="checked" />
            <label class="form-check-label" for="flexRadioDefault6"><b>Only Fees</b></label>
          </div>
          <div *ngIf="Student[0].amt9!=0" class="form-check form-check-inline">
            <input mdbRadio=mdbRadio
                   class="form-check-input"
                   type="radio"
                   name="flexRadioDefault6"
                   id="flexRadioDefault8"
                   checked="checked"
                   (click)="onClickedArrear($event)" />
            <label class="form-check-label" for="flexRadioDefault6"><b>Only Arrear</b></label>
          </div>
          <div *ngIf="Student[0].amt8!=0 && Student[0].amt9!=0" class="form-check form-check-inline">
            <input mdbRadio=mdbRadio
                   class="form-check-input"
                   type="radio"
                   name="flexRadioDefault6"
                   id="flexRadioDefault6"
                   checked="checked"
                   (click)="onClickedFeeArrear($event)" />
            <label class="form-check-label" for="flexRadioDefault6"><b>Fees & Arrear</b></label>
          </div>
          <label class="labelRs1"><span>{{AmountToPayC | amountToWord}}</span></label>
        </div>
      </div>
      <div><span style="font-size:xx-large;color:darkgreen;font-weight:bold">{{FeeMessage}}</span></div>
      <br />
      <tr>        
        <td>
          Remarks
          <input type="text" class="form-control" [(ngModel)]="remarks" placeholder="" formControlName="remarks" style="width:max-content;font-weight: bold;" />
        </td>
      </tr>
      <br />
      <div class="col">
        <div class="form-group">
          <h5 style="color:purple;font-weight:bold;">{{StudentShift}}</h5>
          <h5 style="color: red;font-weight:bold;">{{leftOn}}</h5>
          <div *ngIf="FeeMessage.length == 0 ">
            <input type="submit" name="submit" value="Submit" class="btn btn-success btn-lg btn-block" />
          </div>
        </div>
      </div>
      <br />
      <div class="form-group">
        <button class="btn btn-success btn-lg btn-block" (click)="RefreshPage()">Refresh</button>
      </div>
    </form>
    <form>
      <table id="table10" style="width:60%" name="table10" class="table1">
        <thead>
          <tr>
            <th>#</th>
            <th style="text-align: right;">TranNo.</th>
            <th style="text-align: right;">Tran Date</th>
            <th style="text-align: right;">Receipt No.</th>
            <th style="text-align: right;">PaidAmt</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let lastfive of StudentLastFive; let i=index">
            <th scope="row">{{ i+1 }}</th>
            <td style="text-align: right; font-weight: bold;">{{ lastfive.trnno }}</td>
            <td style="text-align: right; font-weight: bold;">{{ lastfive.trndt | date: 'dd/MM/yyyy' }}</td>
            <td style="text-align: right; font-weight: bold;">{{ lastfive.bankno }}</td>
            <td style="text-align: right; font-weight: bold;">Rs.{{ lastfive.nAmt }}.00</td>
          </tr>
        </tbody>
      </table>
      <!-- Grid row -->
    </form>
    <script type="text/javascript">
      $(document).on('keypress', 'input,select', function (e) {
        if (e.which == 13) {
          e.preventDefault();
          var $next = $('[tabIndex=' + (+this.tabIndex + 1) + ']');
          if (!$next.length) {
            $next = $('[tabIndex=1]');
          }
          $next.focus().click();
        }
      });
    </script>
  </div>
</body>
</html>
