//04176803121
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PdcTransService } from '../pdctrans/pdctrans.service'
import { formatDate, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Pipe } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { GlobalComponent } from 'global-component';
import { isEmpty } from 'rxjs';
import { Clipboard } from "@angular/cdk/clipboard";
import Swal from 'sweetalert2/dist/sweetalert2.js'
//import { exit } from 'process';

@Component({
  selector: 'pdctrans',
  templateUrl: './pdctrans.component.html',
  styleUrls: ['css/dp-light.css', 'pdctrans.component.css'],
})

export class PdcTransComponent implements OnInit {
  @ViewChild('noofinstallments') inputName: ElementRef;
  //@ViewChild('IntAdmno') inputAdmno: ElementRef;
  element: HTMLElement;
  pipe = new DatePipe('en-US');
  now = Date.now();
  admno: any;
  MISB: number;
  iHiddenOpBal: boolean; true;
  DataReced: number = 0;
  ShiftIdN: number = 1;
  DrawnAt: string = '';
  RefreshClicked: boolean; false;
  BankAddress: string = 'NEW DELHI';
  ChequeAmt: number;
  StudentShift: any;
  GLBappFormStarEnd: string = "";
  //noofdays: number = 0;
  //noofinstallments: number = 0;
  //ArrearTotal: Number;
  //FeesTotal: Number;
  EntryDate: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  BankDD: any;
  leftOn: string;
  EmployeeDD: any;
  Student: any = [];
  Ycode: number = 0;
  YearCode: number = 0;
  StudInstallDet: any;
  StudentH: any;
  StudentH2: any;
  ChequeNo: string;
  FeeMessage: string = '';
  BankTransfer: string = '';
  Amtinputdisabled: string = '';
  Admnoinputdisabled: string = '';
  AllBoxes: number = 0;
  ChkHeadCodes: number = 0;
  ChkHeadCode2: number = 0;
  StudentLastTran: any;
  WaivedOffAmt: number = 0;
  WaivedOffAmtD: number = 0;
  LessPaidAmt: number = 0;
  LessPaidAmtD: number = 0;
  LessPaidAmtF: number = 0;
  WaivedOffAmtF: number = 0;
  LessAmtHeadWise: string;
  LessAmtHeadWiseD: string;
  WaivedAmtHeadWise: string;
  WaivedAmtHeadWiseD: string;
  StudentDues: any;
  UserData01: any[];
  UserForm: FormGroup;
  userData: string[] = new Array();
  userData3: any[] = new Array();
  userData2: any[];
  userInput: any[]; // = new Array(15);
  userInputW: any[] = new Array(15);
  userInputD: any[]; // = new Array(15);
  userInputWD: any[] = new Array(15);
  //userInput = Array<{ id: number; name: string }>;
  lastkeydown1: number = 0;
  subscription: any;
  IndexR: number = 0;
  AmountToPay: number = 0;
  AmountToPayC: number = 0;
  AmountToPayCC: number = 0;
  AmountToPayCM: number = 0;
  AmountToPayCD: number = 0;
  AmountLFine: number = 0;
  userList1: any[15];
  stringforward: any[];
  STUDADMNO: string = "";
  GLBappFormType: string = "";
  GLBappUserCode: string = "";
  UserName: any;
  public EmployeeList: Array<any> = [];
  date: string = '';
  GLBappFormName: string = "";
  isChecked: boolean = false;
  //NextDate1: any[];
  public NextDate1: Array<any> = [];
  public NextPayment1: Array<any> = [];
  NextDateString: string = "";
  isOnlyArrear: boolean = false;
  isFeeAndArrear: boolean = false;
  LessPaitAmtInstall: number;
  LessAmoutPaidBool: boolean = false;
  InstallmentDateString: string;
  InstallmentAmtString: string;
  ByPaymentMode: number = 0;
  IPAddress: string = "";
  DDYes: number = 0;
  ChDts: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  constructor(public clipboard: Clipboard, private pdctransService: PdcTransService, private fb: FormBuilder, private router: Router) {
    this.EntryDate = this.pipe.transform(sessionStorage.getItem('currentdate'), 'yyyy-MM-dd');
    this.ChDts = this.pipe.transform(sessionStorage.getItem('currentdate'), 'yyyy-MM-dd');
    this.ShiftIdN = 1;
    this.DataReced = 0;
    this.leftOn = "";
    this.ChequeAmt = 0;
    this.Ycode = 0;
    this.YearCode = 0;
    this.iHiddenOpBal = true;
    this.DDYes = 0;
    this.StudentShift = "";
    this.LessPaidAmtF = 0;
    this.WaivedOffAmtF = 0;
    this.LessPaidAmt = 0;
    this.AllBoxes = 0;
    this.MISB = 1;
    this.admno = '';
    this.LessPaidAmtD = 0;
    this.StudInstallDet = "";
    this.WaivedAmtHeadWise = "";
    this.WaivedAmtHeadWiseD = "";
    this.STUDADMNO = "";
    this.ByPaymentMode = 0;
    this.LessAmtHeadWise = "";
    this.LessAmtHeadWiseD = "";
    this.isOnlyArrear = false;
    this.InstallmentDateString = "";
    this.InstallmentAmtString = "";
    this.RefreshClicked == false;
    this.IPAddress = sessionStorage.getItem('ipaddress');
  }

  RefreshPage() {
    this.RefreshClicked = true;
    this.router.navigate(['/pdctrans'])
      .then(() => {
        window.location.reload();
      });
    this.Admnoinputdisabled = "readonly";
    this.ngOnInit();
  }

  //onClickedArrear(event: Event) {
  //  debugger;
  //  this.AllBoxes = 0;
  //  this.LessPaidAmt = 0;
  //  //if (this.inputName.nativeElement == 'undefined') { }
  //  //this.inputName.nativeElement.value = 0;
  //  this.clipboard.copy(this.STUDADMNO);
  //  if (this.InstallmentAmtString != "") { this.RefreshPage(); return true; }
  //  this.InstallmentAmtString = "";
  //  this.InstallmentDateString = "";
  //  this.NextDateString = "";
  //  this.LessAmtHeadWise = '';
  //  this.WaivedAmtHeadWise = '';
  //  this.InstallmentDateString = '';
  //  this.InstallmentAmtString = '';
  //  let StudentHHH: any[] = this.StudentH;
  //  this.StudentH = new Array();
  //  let count = StudentHHH.length;
  //  this.userInput = new Array(count);
  //  const isChecked: boolean = event.target['checked'];
  //  this.isOnlyArrear = true;
  //  this.isFeeAndArrear = false;
  //  this.AmountToPayCM = Number(this.Student[0].amt8);
  //  this.AmountToPayCD = this.AmountToPayC - this.AmountToPayCM
  //  this.AmountToPayC = this.AmountToPayCD;
  //  this.AmountToPayCM = 0;
  //  if (isChecked == true) { this.isOnlyArrear = true; }
  //}

  onClickedPayment(event: any) {
    debugger;
    this.ByPaymentMode = 0;
    //const paymentchecked = event.returnValue;
    // FOR CHEQUE (1), FOR CASH (2), FOR UPI (3), FOR DRAFT (4), FOR TRANSFER (5)
    this.DrawnAt = '';
    this.BankAddress = '';
    this.BankTransfer = 'DUL';
    switch (event) {
      case 'bysbcportal':
        {
          this.ByPaymentMode = 10;
          this.BankTransfer = 'DUL';
          this.DrawnAt = 'SBI';
          this.BankAddress = 'New Delhi';
          this.ChDts = this.EntryDate;
          break;
        }
      case 'bycashgtbit':
        {
          this.ByPaymentMode = 9;
          this.BankTransfer = '';
          this.DrawnAt = 'Cash@GTBIT';
          this.BankAddress = 'New Delhi';
          this.ChDts = this.EntryDate;
          break;
        }
      case 'bycash':
        {
          this.ByPaymentMode = 2;
          this.BankTransfer = '';
          this.DrawnAt = 'PNB-Cash';
          this.BankAddress = 'New Delhi';
          this.ChDts = this.EntryDate;
          break;
        }
      case 'byupi':
        {
          this.ByPaymentMode = 3;
          this.ChDts = this.EntryDate;
          this.BankTransfer = 'UPI';
          this.DrawnAt = 'SBI';
          this.BankAddress = 'New Delhi';
          break;
        }
      case 'bydraft':
        {
          this.ByPaymentMode = 4;
          this.BankTransfer = '';
          this.ChDts = this.EntryDate;
          this.BankAddress = 'New Delhi';
          break;
        }
      case 'bytransfer':
        {
          this.BankTransfer = 'NEFT';
          this.ByPaymentMode = 5;
          this.ChDts = this.EntryDate;
          this.DrawnAt = 'SBIC';
          this.BankAddress = 'New Delhi';
          break;
        }
      default:
        this.ByPaymentMode = 1;
        //this.BankTransfer = '';
        this.BankAddress = 'New Delhi';
        this.ChDts = this.EntryDate;
        break;
    }
  }

  onClickedAtBank(event: any) {
    debugger;
    this.MISB = 1;
    switch (event) {
      case 'atpnb':
        {
          this.MISB = 2;
          break;
        }
      case 'bycashgtbit':
        {
          this.MISB = 9;
          break;
        }
      default:
        this.MISB = 1;
        break;
    }
  }

  onClickedArrear(event: string) {
    debugger;
    //window.alert(event);
    this.DDYes = Number(event);
    //const isChecked: boolean = event.target['checked'];    
  }

  onChangeDate(event: any) {
    //this.pipe.transform(this.now, 'yyyy-MM-dd');
    //sessionStorage.setItem('currentdate', this.pipe.transform(event.target.value, 'yyyy-MM-dd'));
    //sessionStorage.setItem('currentdate', formatDate(event.target.value, 'dd-MM-yyyy', 'en-US'));
    this.ChDts = formatDate(event.target.value, 'dd-MM-yyyy', 'en-US');
  }

  onChangeNoofdays(evt1: number, evt2: number) {
    debugger;
    this.isChecked = false;
    this.NextDateString = "";
    this.InstallmentDateString = "";
    this.InstallmentAmtString = "";
    this.LessPaitAmtInstall = this.LessPaidAmt / evt2;
    let NextDate2: Date = new Date();
    for (let n = 0; n < evt2; ++n) {
      //date.setDate(date.getDate() + 27);
      //NextDate2.setDate(NextDate2.getDate() + evt1);
      //this.InstallmentDateString = "";
      //this.InstallmentAmtString = "";
      NextDate2 = new Date(NextDate2.getTime() + (evt1 * 24 * 60 * 60 * 1000));
      this.date = formatDate(NextDate2, 'dd-MM-yyyy', 'en-US');
      this.NextDate1[n] = this.date.toString();
      this.NextPayment1[n] = this.LessPaitAmtInstall;
      this.NextDateString = this.NextDateString + this.date.toString() + "(Rs." + this.LessPaitAmtInstall + "), ";
      this.InstallmentDateString = this.InstallmentDateString + this.date.toString() + ",";
      this.InstallmentAmtString = this.InstallmentAmtString + this.LessPaitAmtInstall.toString() + ",";
      //NextDate2.setDate(this.date.toString());
    }
    if (this.NextDateString.length != 0) {
      this.NextDateString = "--- Installments Date: " + this.NextDateString;
      this.InstallmentDateString = this.NextDateString + "###" + this.ChkHeadCode2;
    }
  }

  onChangeInstallments(evt1: number, evt2: number) {
    debugger;
    this.isChecked = false;
    this.NextDateString = "";
    this.InstallmentDateString = "";
    this.InstallmentAmtString = "";
    this.LessPaitAmtInstall = this.LessPaidAmt / evt2;
    let NextDate2: Date = new Date();
    for (let n = 0; n < evt2; ++n) {
      //date.setDate(date.getDate() + 27);   
      //NextDate2.setDate(NextDate2.getDate() + evt1);
      NextDate2 = new Date(NextDate2.getTime() + (evt1 * 24 * 60 * 60 * 1000));
      this.date = formatDate(NextDate2, 'dd-MM-yyyy', 'en-US');
      this.NextDate1[n] = this.date.toString();
      this.NextPayment1[n] = this.LessPaitAmtInstall;
      this.NextDateString = this.NextDateString + this.date.toString() + "(Rs." + this.LessPaitAmtInstall + "), ";
      this.InstallmentDateString = this.InstallmentDateString + this.date.toString() + ",";
      this.InstallmentAmtString = this.InstallmentAmtString + this.LessPaitAmtInstall.toString() + ",";
      //NextDate2.setDate(this.date.toString());
    }
    if (this.NextDateString.length != 0) {
      this.NextDateString = "--- Installments Date: " + this.NextDateString;
      this.InstallmentDateString = this.NextDateString + "###" + this.ChkHeadCode2;
    }
  }

  //onCheckboxChange(evt1: number, evt2: number,event: Event) {
  //  debugger;
  //  const isChecked: boolean = event.target['checked'];
  //  //console.log(evt1);
  //  //console.log(evt2);

  //  //pipe = new DatePipe('en-US');
  //  this.NextDateString = "";
  //  if (isChecked == true) {
  //    this.LessPaitAmtInstall = this.LessPaidAmt / evt2;
  //    let NextDate2: Date = new Date();
  //    for (let n = 0; n < evt2; ++n) {
  //      //date.setDate(date.getDate() + 27);   
  //      //NextDate2.setDate(NextDate2.getDate() + evt1);
  //      NextDate2 = new Date(NextDate2.getTime() + (evt1 * 24 * 60 * 60 * 1000));
  //      this.date = formatDate(NextDate2, 'dd-MM-yyyy', 'en-US');
  //      this.NextDate1[n] = this.date.toString();
  //      this.NextDateString = this.NextDateString + this.date.toString() + "(Rs." + this.LessPaitAmtInstall + "), ";
  //      //NextDate2.setDate(this.date.toString());
  //    }
  //    //console.log(this.NextDate1);
  //  }
  //}

  ngOnInit() {
    this.GLBappFormStarEnd = sessionStorage.getItem('FYYear');
    //window.alert(parseInt("32121321"));
    //window.alert(sessionStorage.getItem('usercode'));
    //if (parseInt(sessionStorage.getItem('usercode')) == 0 || sessionStorage.getItem('usercode') == null) {
    //  this.router.navigate(['/logoutuser'])
    //    .then(() => {
    //      window.location.reload();
    //    });
    //  return;
    //}
    debugger;

    if (parseInt(sessionStorage.getItem('usercode')) == 0 || sessionStorage.getItem('usercode') == null) {
      this.router.navigate(['/logoutuser'])
        .then(() => {
          window.location.reload();
        });
      return;
    }
    this.LessPaidAmt = 0;
    this.LessPaidAmtD = 0;
    this.UserForm = new FormGroup({
      AId: new FormControl(null, Validators.required),
      EntryDate: new FormControl(null, Validators.required),
      Admno: new FormControl(null, Validators.required),      
      ChNo: new FormControl(null, Validators.required),
      ChDt: new FormControl(null, Validators.required),      
      DrawnAt: new FormControl(null, Validators.required),
      BankAddress: new FormControl(null, Validators.required),
      ChequeAmt: new FormControl(null, Validators.required),
      IpAddress: new FormControl(null, Validators.required),
      DDYes: new FormControl(false, Validators.required),
      IUserCode: new FormControl(false, Validators.required),
      Ycode: new FormControl(false, Validators.required),
      YearCode: new FormControl(false, Validators.required),
      ClientId: new FormControl(false, Validators.required),
      iShiftId: new FormControl(false, Validators.required),
    });
    sessionStorage.setItem('formtype', '1');
    this.GLBappFormType = sessionStorage.getItem('formtype');
    sessionStorage.setItem('formname', 'PDC Trans');
    this.GLBappFormName = sessionStorage.getItem('formname');
    this.GLBappUserCode = sessionStorage.getItem('usercode');
    this.pdctransService.GetAllBanks().subscribe((BankDD) => {
      this.BankDD = BankDD;
    }, (error) => {
      console.log(error);
    });
    this.RefreshClicked == false;    

  }

  onFormSubmit() {
    //Admno: 431915
    debugger;
    if (parseInt(sessionStorage.getItem('YesTran')) == 9) {
      Swal.fire({
        html: "<strong>Do some Action? Please change to current F/Year.</strong>",
        icon: 'info',
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
          //this.router.navigate(['/createdues'])
          //  .then(() => {
          //    window.location.reload();
          //  });
          //this.ngOnInit();
        }
      })
      return false;
    }
    const studfee = this.UserForm.value;    
    studfee.Admno = this.STUDADMNO;    
    studfee.DrawnAt = this.DrawnAt;    
    studfee.IpAddress = this.IPAddress;    
    studfee.DDYes = this.DDYes;
    studfee.ChequeAmt = this.ChequeAmt;
    studfee.BankAddress = this.BankAddress;
    studfee.ChNo = this.ChequeNo;
    studfee.ChDt = this.ChDts;    
    studfee.EntryDate = this.EntryDate;
    studfee.IUserCode = this.GLBappUserCode;
    studfee.Ycode = this.Ycode;
    studfee.YearCode = this.YearCode;
    studfee.ClientId = 0;
    studfee.iShiftId = this.ShiftIdN;
    if (Number.isInteger(Number(this.ChequeNo))) {
    } else {
      //window.alert("Cheque No. should be Numeric");
      Swal.fire({
        html: "<strong>Cheque No. should be Numeric</strong>",
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
        }
      })

      return;
    }
    if (Number.isInteger(Number(this.ChequeAmt))) {
    } else {
      //window.alert("Cheque Amount should be Numeric");
      Swal.fire({
        html: "<strong>Cheque Amount should be Numeric</strong>",
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
        }
      })
      return;
    }
    if (Number(this.ChequeAmt) <= 0) {
      //window.alert("Cheque Amount should be not be Zero");
      Swal.fire({
        html: "<strong>Cheque Amount should be not be Zero</strong>",
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
        }
      })
      return;
    }
    if (Number(this.ChequeNo) <= 0) {
      //window.alert("Cheque No. should be not be left blank");
      Swal.fire({
        html: "<strong>Cheque No. should be not be left blank</strong>",
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
        }
      })
      return;
    }
    if (this.DrawnAt.length <= 0) {
      //window.alert("Drawn At should be not be left blank");
      Swal.fire({
        html: "<strong>Drawn At should be not be left blank</strong>",
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
        }
      })
      return;
    }
    if (this.ChDts <= this.EntryDate) {
      //window.alert("Cheque Date should be not be less than or equal to Today's Date");
      Swal.fire({
        html: "<strong>Cheque Date should be not be less than or equal to Today's Date</strong>",
        icon: 'warning',
        width: 400,
        padding: 50,
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
        }
      })
      return;
    }

    Swal.fire({
      //position: 'top-end',        
      html: "<strong><H3>Do you want to Save the Record?</H3></strong>",
      showCancelButton: true,
      icon: 'question',
      //iconHtml: '<img src="https://picsum.photos/100/100">',
      width: 400,
      padding: 50,
      //className: "green-bg",
      confirmButtonColor: "#338921",
      confirmButtonText: 'Yes',
      cancelButtonColor: "#F6122E",
      cancelButtonText: 'No'
    }).then((result: { value: any; }) => {
      if (result.value) {
        //window.alert("hello");          
        this.pdctransService.AddPDCTransDet(studfee).subscribe((retval) => {
          Swal.fire({
            html: "<strong>Submitted successfully</strong>",
            icon: 'success',
            showConfirmButton: true,
            confirmButtonColor: "#338921",
            confirmButtonText: 'OK'
            //background: '#fff url(//bit.ly/1Nqn9HU)',
            //timer: 300000
          }).then((result) => {
            if (result.value) {
              this.StudentShift = "";
              this.ByPaymentMode = 1;
              this.NextDateString = "";
              this.Amtinputdisabled = "";
              this.isOnlyArrear = false;
              this.isFeeAndArrear = false;
              this.FeeMessage = "";
              this.STUDADMNO = "";
              this.StudInstallDet = "";
              this.Student = "";
              this.router.navigate(['/pdctrans'])
                .then(() => {
                  window.location.reload();
                });
            }
          })         
        }, (error) => {
          Swal.fire('Some error:' + error, 'error');
          //console.log(error);
        });
      }
    });

  
      //if (window.confirm('Do you want to Save the Record?')) {
      //  this.pdctransService.AddPDCTransDet(studfee).subscribe((retval) => {
      //    //window.alert(retval);
      //    window.alert('Submitted successfully');
      //    this.StudentShift = "";
      //    this.ByPaymentMode = 1;
      //    this.NextDateString = "";
      //    this.Amtinputdisabled = "";
      //    this.isOnlyArrear = false;
      //    this.isFeeAndArrear = false;
      //    this.FeeMessage = "";
      //    this.STUDADMNO = "";
      //    this.StudInstallDet = "";
      //    this.Student = "";
      //    this.router.navigate(['/pdctrans'])
      //      .then(() => {
      //        window.location.reload();
      //      });
      //    //this.ngOnInit();
      //  }, (error) => {
      //    console.log(error);
      //  });
      //}
       
  }

  public SetGetNullValueStr(parametername: string) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return "NULL";
    }
  }

  public SetGetNullValueInt(parametername: any) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return Number(0);
    }
  }

  public SetGetNullValueDate(parametername: any) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return '1900-01-01';
    }
  }

  

  onChangeEnrolNo(event: any) {
    debugger;
    this.RefreshClicked = false;
    this.StudentShift = "";
    this.ByPaymentMode = 1;
    this.iHiddenOpBal = true;
    this.DDYes = 0;
    this.NextDateString = "";
    this.Amtinputdisabled = "";
    this.isOnlyArrear = false;
    this.isFeeAndArrear = false;
    this.FeeMessage = "";
    this.STUDADMNO = event.target.value;
    this.admno = this.STUDADMNO;
    this.StudInstallDet = "";
    this.Student = "";
    const studfee = this.UserForm.value;
    //this.UserForm.controls['admno'].disable();
    studfee.AId = "";
    studfee.Admno = "";
    //this.BankDD = "";
    this.StudentDues = "";
    this.StudentH = "";
    this.Admnoinputdisabled = "";
    this.Student = [];
    this.leftOn = "";    
    this.ShiftIdN = 1;    
    this.ChDts = this.EntryDate;
    //this.EntryDate = event.target.value;
    //console.log(this.STUDADMNO);
    //window.alert(sessionStorage.getItem('NbaseURLs'));
    //window.alert("1");
    if (this.STUDADMNO.length != 0) {
      this.pdctransService.GetStudentFeeDETN(this.STUDADMNO.toString(), this.EntryDate.toString()).then((_Stud) => {
        this.DataReced = _Stud;
        //window.alert(this.DataReced);
        //Start
        //window.alert(this.DataReced);
        if (this.DataReced == 1) {
          this.pdctransService.GetStudentFeeDETAdmnoN(this.STUDADMNO).subscribe((Student) => {            
            this.Student = Student;
            this.Ycode = this.Student[0].yCode;
            this.YearCode = this.Student[0].yearCode;
            this.Admnoinputdisabled = "readonly";
            if (Number(this.Student[0].iShiftId) == 1) {
              this.StudentShift = "Morning Student";
              this.ShiftIdN = parseInt(this.Student[0].iShiftId)
            } else {
              this.StudentShift = "Evening Student";
              this.ShiftIdN = parseInt(this.Student[0].iShiftId)
            }
            if (Number(this.Student[0].ctr10) == 1) {
              this.leftOn = "(Left On: " + this.pipe.transform(this.Student[0].cDate8, 'dd-MM-yyyy') + ")";
            }                        
            if (this.Student[0].amt5 == 1) { this.Amtinputdisabled = "disabled"; }                      
          }, (error) => {            
            console.log(error);
          });
        }
      }).catch ((error) => {        
        console.log(error);        
      });
    }
    
  }
   
  keyPressNumbers(event: any) {
    this.NextDateString = "";
    this.WaivedOffAmt = this.userInputW.reduce<number>((accumulator, current) => {
      return accumulator + current;
    }, 0);

    this.LessPaidAmt = this.userInput.reduce<number>((accumulator, current) => {
      return accumulator + current;
    }, 0);

    if (Number(event.target.value) <= Number(this.AmountLFine)) {
      this.AmountToPayC = Number(this.AmountToPay);
      this.AmountToPayC = Number(this.AmountToPayC) - (Number(event.target.value) + Number(this.LessPaidAmt) + Number(this.WaivedOffAmt));
      this.AmountToPayCC = this.AmountToPayC;
    } else {
      event.target.value = Number(this.AmountLFine);
    }
    return true;
  }
  
}
