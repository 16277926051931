//04176803121
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
//import { AmountToWordPipe } from 'amount-to-word.pipe';
//import { amountToWord } from 'src/app/amount-to-word.pipe;'
import { Router } from '@angular/router';
import { PageSize } from 'pdfmake/interfaces';
import { ForLoanScholarService } from './forloanscholar.service'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
//import { interval, Subscription } from 'rxjs';
//import { MatProgressSpinnerModule } from '@angular/material';
import { TableUtil } from "./tableUtil";
import { Data } from "./article";
import { formatDate, DatePipe } from '@angular/common';
import jsPDF from 'jspdf';
import { map } from 'rxjs/operators';
import { UserOptions } from 'jspdf-autotable'
import autoTable from 'jspdf-autotable'
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { toDegrees } from 'ag-charts-community/dist/esm/es6/sparklines-util';
import { AmountToWordPipe } from '../../amount-to-word.pipe';
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

interface jsPDFWithPlugin extends jsPDF {
  autotable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: 'forloanscholar',
  templateUrl: './forloanscholar.component.html',
  styleUrls: ['./forloanscholar.component.scss']
})

export class ForLoanScholarComponent implements OnInit {
  pipe = new DatePipe('en-US');
  selectedRow: number;
  loading: string = '';
  getFirstYear: number = 0;
  get2ndYear: number = 0;
  indexOfFirst: number = 0;
  GLBappFormStarEnd: string = "";
  now = Date.now();
  fatherName: string;
  prntOnce: number = 0;
  dataSource: any;
  linescount: number;
  forWhich: string;
  WhichShift: string;
  strContent1: string;
  strHead: string;
  TodayDate: any;
  PageNo: number;
  TempTotalAmt: string;
  SD: string = "";
  Msg1: string;
  EnrolNo: string;
  iBankNo: string;
  iYearCode: number;
  PageYPoistion: number = 0;
  //data: any[] = [];
  YearCode: string = "";
  public listData = [];
  public listDataV = [];
  public listDataVIst = [];
  public rows = [];
  public rowsF = [];
  Amount1stYear: string = "";
  amountspace: string = "";
  AmountRs: string = "";
  TotalAmt: number = 0;
  FileName: string = "";
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  //@ViewChild('pdfTable1') pdfTable1!: ElementRef;
  @ViewChild('pdfTable') pdfTable!: ElementRef;
  StudFeeReceiptDet: any;
  PaymentMode: string = "";
  TranNo: string = "";
  NotesForIstYear: string = "";
  todayDate: any = this.pipe.transform(this.now, 'dd-MM-yyyy');
  constructor(private ForLoanScholar: ForLoanScholarService, private router: Router) {
    this.rowsF = [];
    this.fatherName = "";
    this.forWhich = "This certificate is issued on his/her own request for Education Loan purpose only.";
    this.get2ndYear = 0;
    this.getFirstYear = 0;
    this.linescount = 0;
    this.PageNo = 0;
    this.selectedRow = 1;
    this.WhichShift = "Morning Shift";
    this.Msg1 = "";

  }
  displayedColumns: string[] = ['admno', 'studentName', 'course', 'bankno', 'yearcode', 'trndt', 'paidAmt', 'paymentMode', 'view1', 'view2', 'view3', 'view4'];
  

  applyFilter(event: Event) {
    debugger;
      this.prntOnce = 0;
      this.getFirstYear = 0;
      this.get2ndYear = 0;
    const filterValue = (event.target as HTMLInputElement).value;
    //console.log(filterValue);
    if (filterValue != null || filterValue != '') {
      this.TodayDate = this.pipe.transform(this.now, 'dd/MM/yyyy hh:mm:ss a');
      this.ForLoanScholar.ViewFeeReceiptGrp(filterValue).subscribe((response: any) => {
        this.StudFeeReceiptDet = response;
        this.dataSource = new MatTableDataSource(response);
        this.iYearCode = response[0].yearcode;
        this.EnrolNo = response[0].admno;
        this.iBankNo = response[0].bankno;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.listData = response as Data[];
        this.getFirstYear = 0;
        if (response[0].course.indexOf('3rd') >= 0) { this.getFirstYear = 33; }
        if (response[0].course.indexOf('1st') >= 0) { this.getFirstYear = 1; }
        if (response[0].course.indexOf('2nd') >= 0) {
          this.getFirstYear = 22;
          if (response[0].course.indexOf('LE') >= 0) {
            this.get2ndYear = 1;
            this.getFirstYear = 2;
          }
        }
        //this.CheckStrHead();
        if (response[0].cscode == 1) {
          this.NotesForIstYear = "Fee Paid at GGSIPU During the time of Provisional Admission<br />TUITION FEE:40,000<br />UNIVERSITY OTHER DUES:20,000";
        }
        for (var k = 0; k < this.listData.length; k++) {
          this.rows.push([
            this.listData[k].admno,
            this.listData[k].studentName,
            this.listData[k].course,
            this.listData[k].bankno,
            this.listData[k].yearcode,
            this.listData[k].trndt,
            this.listData[k].paidAmt,
            this.listData[k].paymentMode
          ])
        }
      }, (error) => {
        console.log(error);
      });


      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }


  ngOnInit(): void {
    debugger;
    this.GLBappFormStarEnd = sessionStorage.getItem('FYYear');
    if (parseInt(sessionStorage.getItem('usercode')) == 0 || sessionStorage.getItem('usercode') == null) {
      this.router.navigate(['/logoutuser'])
        .then(() => {
          window.location.reload();
        });
      return;
    }
    this.loading = '';    

    this.strContent1 = '<table align="center" cellpadding="5" cellspacing="5" style="width:856px;border-color:#ffffff;">'.toString();
    //this.strContent1 = this.strContent1 + '<tbody>'.toString();
    this.strContent1 = this.strContent1 + '<tr>'.toString();
    this.strContent1 = this.strContent1 + '<td style="border-color:#ffffff;text-align:justify;vertical-align:middle;width:846px"><span style="font-size:13px">This is to certify that <u><strong>Mahinder Singh Chhabra</strong></u> S/o <u><strong>S.Gurvinder Singh Chhabra</strong></u> of <strong>B.Tech CSE</strong> Enrolment No.<u><strong>02356456256</strong></u> is a bonafide student of <strong>1st Yr. (First Year)</strong>. The paid fee details for 2 years is given below:</span></td>'.toString();
    this.strContent1 = this.strContent1 + '</tr>'.toString();
    //this.strContent1 = this.strContent1 + '</tbody>'.toString();
    this.strContent1 = this.strContent1 + '</table>'.toString();
  }

  radioSelected() {
    if (Number(this.selectedRow) == 1) {
      this.forWhich = "This certificate is issued on his/her own request for Education Loan purpose only.";
    }
    if (Number(this.selectedRow) == 2) {
      this.forWhich = "This certificate is issued on his/her own request for Scholarship purpose only.";
    }
  }

  //CheckStrHeadSpotFee() {
  //  this.strHead = "";
  //  this.strHead = '<br /><p style="text-align:justify"><span style="font-size:16px">This is to certify that <u><strong>' + this.StudFeeReceiptDet[0].studentName + '</strong></u> ' + this.SD + ' <u><strong>' + this.StudFeeReceiptDet[0].fatherName + '</strong></u> of <strong>B.Tech ' + this.StudFeeReceiptDet[0].course + '</strong> Enrolment No.<u><strong>' + this.StudFeeReceiptDet[0].admno + '</strong></u> is a bonafide student of <strong>1st Yr. (First Year)</strong>. The paid fee details for 2 years is given below:</span></p><p></p><span style="font-size:18px"><strong>FEE STRUCTURE</strong></span>'.toString();
  //  this.strHead = this.strHead + '<center>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<table align="center" border="1" cellpadding="1" cellspacing="1" style="width:850px">'.toString();
  //  this.strHead = this.strHead + '<tbody>'.toString();
  //  this.strHead = this.strHead + '<tr>'.toString();
  //  this.strHead = this.strHead + '<td colspan="1" rowspan="3" style="text-align:center; width:175px"><strong>PARTICULAR</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2<sup>nd</sup> yr.</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2<sup>nd</sup> yr.</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>3<sup>rd</sup> yr.</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>&nbsp;4<sup>th</sup> yr.</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '</tr>'.toString();
  //  this.strHead = this.strHead + '<tr>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2023-24</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2023-24</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2024-25</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2025-26</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '</tr>'.toString();
  //  this.strHead = this.strHead + '<tr>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>Paid At GGSIPU</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>Paid At GTBIT</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>Payable</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>Payable</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '</tr>'.toString();
  //  this.strHead = this.strHead + '<tr>'.toString();
  //  this.strHead = this.strHead + '<td style="width:175px"><strong>Tuition Fees</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">40,000/-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">71,000/-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">1,11,000/-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">1,11,000/-</span></td>'.toString();
  //  this.strHead = this.strHead + '</tr>'.toString();
  //  this.strHead = this.strHead + '<tr>'.toString();
  //  this.strHead = this.strHead + '<td style="width:175px"><strong>University Other Dues</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">20,000/-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">20,000/-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">20,000/-</span></td>'.toString();
  //  this.strHead = this.strHead + '</tr>'.toString();
  //  this.strHead = this.strHead + '<tr>'.toString();
  //  this.strHead = this.strHead + '<td style="width:175px"><strong>Book Bank (Refundable 4,000/-)</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">6,000/-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
  //  this.strHead = this.strHead + '</tr>'.toString();
  //  this.strHead = this.strHead + '<tr>'.toString();
  //  this.strHead = this.strHead + '<td style="width:175px"><strong>Caution Money (Refundable)</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">10,000/-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
  //  this.strHead = this.strHead + '</tr>'.toString();
  //  this.strHead = this.strHead + '<tr>'.toString();
  //  this.strHead = this.strHead + '<td style="width:175px"><strong>Spot Round Fee</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">5,000/-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
  //  this.strHead = this.strHead + '</tr>'.toString();
  //  this.strHead = this.strHead + '<tr>'.toString();
  //  this.strHead = this.strHead + '<td style="width:175px"><strong>Alumni Contribution Fund (One Time)</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">2,000/-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
  //  this.strHead = this.strHead + '</tr>'.toString();
  //  this.strHead = this.strHead + '<tr>'.toString();
  //  this.strHead = this.strHead + '<td style="width:175px"><strong>Insurance and I.Card</strong></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">100/-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">100/-</span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">100/-</span></td>'.toString();
  //  this.strHead = this.strHead + '</tr>'.toString();
  //  this.strHead = this.strHead + '<tr>'.toString();
  //  this.strHead = this.strHead + '<td style="width:175px">'.toString();
  //  this.strHead = this.strHead + '<p><strong>Total</strong></p>'.toString();
  //  this.strHead = this.strHead + '</td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><p><strong>60,000/-</strong></p></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><p><strong>94,100/-</strong></p></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><p><strong>1,31,100/-</strong></p></span></td>'.toString();
  //  this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><p><strong>1,31,100/-</strong></p></span></td>'.toString();
  //  this.strHead = this.strHead + '</tr>'.toString();
  //  this.strHead = this.strHead + '</tbody>'.toString();
  //  this.strHead = this.strHead + '</table></center>'.toString();
  //}

  CheckStrHeadSpotFee() {
    this.strHead = "";
    this.strHead = '<br /><p style="text-align:justify"><span style="font-size:16px">This is to certify that <u><strong>' + this.StudFeeReceiptDet[0].studentName + '</strong></u> ' + this.SD + ' <u><strong>' + this.StudFeeReceiptDet[0].fatherName + '</strong></u> of <strong>B.Tech ' + this.StudFeeReceiptDet[0].course + '</strong> Enrolment No.<u><strong>' + this.StudFeeReceiptDet[0].admno + '</strong></u> is a bonafide student of <strong>1st Yr. (First Year)</strong>. The paid fee details for 2 years is given below:</span></p><p></p><span style="font-size:18px"><strong>FEE STRUCTURE</strong></span>'.toString();
    this.strHead = this.strHead + '<center>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<table align="center" border="1" cellpadding="1" cellspacing="1" style="width:850px">'.toString();
    this.strHead = this.strHead + '<tbody>'.toString();
    this.strHead = this.strHead + '<tr>'.toString();
    this.strHead = this.strHead + '<td colspan="1" rowspan="3" style="text-align:center; width:175px"><strong>PARTICULAR</strong></span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2<sup>nd</sup> yr.</strong></span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2<sup>nd</sup> yr.</strong></span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>3<sup>rd</sup> yr.</strong></span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>&nbsp;4<sup>th</sup> yr.</strong></span></td>'.toString();
    this.strHead = this.strHead + '</tr>'.toString();
    this.strHead = this.strHead + '<tr>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2023-24</strong></span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2023-24</strong></span></td>'.toString();
    this.strHead = this.strHead + '</tr>'.toString();
    this.strHead = this.strHead + '<tr>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>Paid At GGSIPU</strong></span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>Paid At GTBIT</strong></span></td>'.toString();
    this.strHead = this.strHead + '</tr>'.toString();
    this.strHead = this.strHead + '<tr>'.toString();
    this.strHead = this.strHead + '<td style="width:175px"><strong>Tuition Fees</strong></span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">40,000/-</span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">71,000/-</span></td>'.toString();    
    this.strHead = this.strHead + '</tr>'.toString();
    this.strHead = this.strHead + '<tr>'.toString();
    this.strHead = this.strHead + '<td style="width:175px"><strong>University Other Dues</strong></span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">20,000/-</span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();    
    this.strHead = this.strHead + '</tr>'.toString();
    this.strHead = this.strHead + '<tr>'.toString();
    this.strHead = this.strHead + '<td style="width:175px"><strong>Book Bank (Refundable 4,000/-)</strong></span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">6,000/-</span></td>'.toString();
    
    this.strHead = this.strHead + '</tr>'.toString();
    this.strHead = this.strHead + '<tr>'.toString();
    this.strHead = this.strHead + '<td style="width:175px"><strong>Caution Money (Refundable)</strong></span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">10,000/-</span></td>'.toString();    
    this.strHead = this.strHead + '</tr>'.toString();
    this.strHead = this.strHead + '<tr>'.toString();
    this.strHead = this.strHead + '<td style="width:175px"><strong>Spot Round Fee</strong></span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">5,000/-</span></td>'.toString();    
    this.strHead = this.strHead + '</tr>'.toString();
    this.strHead = this.strHead + '<tr>'.toString();
    this.strHead = this.strHead + '<td style="width:175px"><strong>Alumni Contribution Fund (One Time)</strong></span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">2,000/-</span></td>'.toString();    
    this.strHead = this.strHead + '</tr>'.toString();
    this.strHead = this.strHead + '<tr>'.toString();
    this.strHead = this.strHead + '<td style="width:175px"><strong>Insurance and I.Card</strong></span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">100/-</span></td>'.toString();    
    this.strHead = this.strHead + '</tr>'.toString();
    this.strHead = this.strHead + '<tr>'.toString();
    this.strHead = this.strHead + '<td style="width:175px">'.toString();
    this.strHead = this.strHead + '<p><strong>Total</strong></p>'.toString();
    this.strHead = this.strHead + '</td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><p><strong>60,000/-</strong></p></span></td>'.toString();
    this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><p><strong>94,100/-</strong></p></span></td>'.toString();
    this.strHead = this.strHead + '</tr>'.toString();
    this.strHead = this.strHead + '</tbody>'.toString();
    this.strHead = this.strHead + '</table></center>'.toString();
  }

  CheckStrHead() {
    debugger;
    this.strHead = "";
    this.fatherName = this.StudFeeReceiptDet[0].fatherName;
    if (this.StudFeeReceiptDet[0].fatherName == null) { this.fatherName = "___________________________"; }
    if (this.getFirstYear == 33) {
      this.strHead = '<br /><p style="text-align:justify"><span style="font-size:16px">This is to certify that <u><strong>' + this.StudFeeReceiptDet[0].studentName + '</strong></u> ' + this.SD + ' <u><strong>' + this.fatherName + '</strong></u> of <strong>B.Tech ' + this.StudFeeReceiptDet[0].course + '</strong> Enrolment No.<u><strong>' + this.StudFeeReceiptDet[0].admno + '</strong></u> is a bonafide student of <strong>3rd Yr. (Third Year)</strong>. The payable fee details for upcoming 1 year are given below:</span></p><p></p><span style="font-size:18px"><strong>FEE STRUCTURE</strong></span>'.toString();
      this.strHead = this.strHead + '<center>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<table align="center" border="1" cellpadding="1" cellspacing="1" style="width:850px">'.toString();
      this.strHead = this.strHead + '<tbody>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td colspan="1" rowspan="3" style="text-align:center; width:175px"><strong>PARTICULAR</strong></span></td>'.toString();      
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>3<sup>rd</sup> yr.</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>&nbsp;4<sup>th</sup> yr.</strong></span></td>'.toString();
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2023-24</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2024-25</strong></span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>Paid At GTBIT</strong></span></td>'.toString();      
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>Payable</strong></span></td>'.toString();
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>Tuition Fees</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">1,13,900/-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">1,13,900/-</span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>University Other Dues</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">20,000/-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">20,000/-</span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>Insurance and I.Card</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">100/-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">100/-</span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px">'.toString();
      this.strHead = this.strHead + '<p><strong>Total</strong></p>'.toString();
      this.strHead = this.strHead + '</td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><p><strong>1,13,900/-</strong></p></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><p><strong>1,13,900/-</strong></p></span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '</tbody>'.toString();
      this.strHead = this.strHead + '</table></center>'.toString();
    }
    if (this.getFirstYear == 22) {
      this.strHead = '<br /><p style="text-align:justify"><span style="font-size:16px">This is to certify that <u><strong>' + this.StudFeeReceiptDet[0].studentName + '</strong></u> ' + this.SD + ' <u><strong>' + this.fatherName + '</strong></u> of <strong>B.Tech ' + this.StudFeeReceiptDet[0].course + '</strong> Enrolment No.<u><strong>' + this.StudFeeReceiptDet[0].admno + '</strong></u> is a bonafide student of <strong>2nd Yr. (Second Year)</strong>. The paid fee details for 2 years is given below:</span></p><p></p><span style="font-size:18px"><strong>FEE STRUCTURE</strong></span>'.toString();
      this.strHead = this.strHead + '<center>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<table align="center" border="1" cellpadding="1" cellspacing="1" style="width:850px">'.toString();
      this.strHead = this.strHead + '<tbody>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td colspan="1" rowspan="3" style="text-align:center; width:175px"><strong>PARTICULAR</strong></span></td>'.toString();      
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2<sup>nd</sup> yr.</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>3<sup>rd</sup> yr.</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>&nbsp;4<sup>th</sup> yr.</strong></span></td>'.toString();
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();      
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2023-24</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2024-25</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2025-26</strong></span></td>'.toString();
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>Paid At GTBIT</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>Payable</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>Payable</strong></span></td>'.toString();
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>Tuition Fees</strong></span></td>'.toString();      
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">1,03,700/-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">1,03,700/-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">1,03,700/-</span></td>'.toString();
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>University Other Dues</strong></span></td>'.toString();      
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">20,000/-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">20,000/-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">20,000/-</span></td>'.toString();
      this.strHead = this.strHead + '</tr>'.toString();                  
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>Insurance and I.Card</strong></span></td>'.toString();      
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">100/-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">100/-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">100/-</span></td>'.toString();
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px">'.toString();
      this.strHead = this.strHead + '<p><strong>Total</strong></p>'.toString();
      this.strHead = this.strHead + '</td>'.toString();      
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><p><strong>1,23,800/-</strong></p></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><p><strong>1,23,800/-</strong></p></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><p><strong>1,23,800/-</strong></p></span></td>'.toString();
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '</tbody>'.toString();
      this.strHead = this.strHead + '</table></center>'.toString();
    }
    if (this.getFirstYear == 1) {
      this.strHead = '<br /><p style="text-align:justify"><span style="font-size:16px">This is to certify that <u><strong>' + this.StudFeeReceiptDet[0].studentName + '</strong></u> ' + this.SD + ' <u><strong>' + this.fatherName + '</strong></u> of <strong>B.Tech ' + this.StudFeeReceiptDet[0].course + '</strong> Enrolment No.<u><strong>' + this.StudFeeReceiptDet[0].admno + '</strong></u> is a bonafide student of <strong>1st Yr. (First Year)</strong>. The paid fee details for 1st year is given below:</span></p><p></p><span style="font-size:18px"><strong>FEE STRUCTURE</strong></span>'.toString();
      this.strHead = this.strHead + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<center>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<table align="center" border="1" cellpadding="1" cellspacing="1" style="width:850px">'.toString();
      this.strHead = this.strHead + '<tbody>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td colspan="1" rowspan="3" style="text-align:center; width:175px"><strong>PARTICULAR</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>1<sup>st</sup> yr.</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>1<sup>st</sup> yr.</strong></span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2023-24</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2023-24</strong></span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>Paid At GGSIPU</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>Paid At GTBIT</strong></span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>Tuition Fees</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">40,000/-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">71,000/-</span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>University Other Dues</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">20,000/-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>Book Bank (Refundable 4,000/-)</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">6,000/-</span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>Caution Money (Refundable)</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">10,000/-</span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>Alumni Contribution Fund (One Time)</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">2,000/-</span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>Insurance and I.Card</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">100/-</span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px">'.toString();
      this.strHead = this.strHead + '<p><strong>Total</strong></p>'.toString();
      this.strHead = this.strHead + '</td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><p><strong>60,000/-</strong></p></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><p><strong>89,100/-</strong></p></span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '</tbody>'.toString();
      this.strHead = this.strHead + '</table></center>'.toString();
    } else if (this.getFirstYear == 2) {
      this.strHead = '<br /><p style="text-align:justify"><span style="font-size:16px">This is to certify that <u><strong>' + this.StudFeeReceiptDet[0].studentName + '</strong></u> ' + this.SD + ' <u><strong>' + this.fatherName + '</strong></u> of <strong>B.Tech ' + this.StudFeeReceiptDet[0].course + '</strong> Enrolment No.<u><strong>' + this.StudFeeReceiptDet[0].admno + '</strong></u> is a bonafide student of <strong>2nd Yr. (Lateral Entry)</strong>. The paid fee details for 2 years is given below:</span></p><p></p><span style="font-size:18px"><strong>FEE STRUCTURE</strong></span>'.toString();
      this.strHead = this.strHead + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<center>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<table align="center" border="1" cellpadding="1" cellspacing="1" style="width:850px">'.toString();
      this.strHead = this.strHead + '<tbody>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td colspan="1" rowspan="3" style="text-align:center; width:175px"><strong>PARTICULAR</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2<sup>nd</sup> yr.</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2<sup>nd</sup> yr.</strong></span></td>'.toString();
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2023-24</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>2023-24</strong></span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>Paid At GGSIPU</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><strong>Paid At GTBIT</strong></span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>Tuition Fees</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">40,000/-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">71,000/-</span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>University Other Dues</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">20,000/-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();            
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>Book Bank (Refundable 4,000/-)</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">6,000/-</span></td>'.toString();            
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>Caution Money (Refundable)</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">10,000/-</span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>Alumni Contribution Fund (One Time)</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">2,000/-</span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px"><strong>Insurance and I.Card</strong></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">-</span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px">100/-</span></td>'.toString();          
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '<tr>'.toString();
      this.strHead = this.strHead + '<td style="width:175px">'.toString();
      this.strHead = this.strHead + '<p><strong>Total</strong></p>'.toString();
      this.strHead = this.strHead + '</td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><p><strong>60,000/-</strong></p></span></td>'.toString();
      this.strHead = this.strHead + '<td style="text-align:center; width:135px><span style="font-size:13px"><p><strong>89,100/-</strong></p></span></td>'.toString();      
      this.strHead = this.strHead + '</tr>'.toString();
      this.strHead = this.strHead + '</tbody>'.toString();
      this.strHead = this.strHead + '</table></center>'.toString();
      //console.log(this.strHead);
    }
  }

  ViewReceipt3rdYearITR() {
    this.StudFeeReceiptDet = "";
    this.ForLoanScholar.ViewFeeReceipt2ndLE(this.EnrolNo, this.iYearCode).subscribe((response) => {
      this.StudFeeReceiptDet = response;
      if (response[0].gender == "M") { this.SD = "S/o" }
      if (response[0].gender == "F") { this.SD = "D/o" }
      for (var k = 0; k < this.StudFeeReceiptDet.length; k++) {
        if (this.StudFeeReceiptDet[k].headName == 'OTHER CHARGES') {
          this.CheckStrHeadSpotFee();
          break;
        }
      }

      this.CheckStrHead();

      var pdfMake = require("pdfmake/build/pdfmake");
      var pdfFonts = require("pdfmake/build/vfs_fonts");
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      var htmlToPdfmake = require("html-to-pdfmake");

      const doc = new jsPDF();
      //const pdfContent = this.pdfContent.nativeElement;
      var html;
      var htmlContent;
      let row: any[] = [];
      //let totpage = this.total1 - 1;
      html = htmlToPdfmake(this.strHead);
      htmlContent = htmlToPdfmake(this.strContent1);
      //row.push(htmlContent)
      //text: 'This is to certify that Mahinder Singh Chhabra S/o S.Gurvinder Singh Chhabra of B.Tech CSE Enrolment No.02356456256 is a bonafide student of 1st Yr. (First Year). The payable fee details for upcoming 3 years are given below:',
      row.push({
        text: 'Date:' + this.todayDate,
        fontSize: 12,
        bold: true,
        alignment: 'right',
        color: '#00000',
      })
      row.push({
        text: '',
        fontSize: 12,
        bold: false,
        alignment: 'justify',
        color: '#00000',
      })
      row.push({
        text: 'TO WHOM IT MAY CONCERN',
        fontSize: 14,
        bold: true,
        decoration: 'underline',
        alignment: 'center',
        color: '#00000',
      })
      //row.push({
      //  text: '',
      //  fontSize: 12,
      //  bold: false,
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: '',
      //  fontSize: 12,
      //  bold: false,
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //this.fatherName = response[0].fatherName;
      //if (response[0].fatherName == null) { this.fatherName = "___________________________"; }
      //row.push({
      //  text: 'This is to certify that ' + response[0].studentName + ' ' + this.SD + ' ' + this.fatherName + '  of B.Tech ' + response[0].course,
      //  fontSize: 12,
      //  bold: false,
      //  margin: [0, 20, 0, 5],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: 'Enrolment No.' + response[0].admno + ' is a bonafide student of 3rd Yr. The payable fee details',
      //  fontSize: 12,
      //  bold: false,
      //  margin: [0, 0, 0, 5],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: 'for upcoming 1 year are given below:',
      //  fontSize: 12,
      //  bold: false,
      //  margin: [0, 0, 0, 20],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: 'This is to certify that Mahinder Singh Chhabra S/o S.Gurvinder Singh Chhabra of B.Tech CSE Enrolment No.02356456256 is a bonafide student of 1st Yr. (First Year). The payable fee details for upcoming 3 years are given below:',
      //  fontSize: 12,
      //  bold: false,
      //  linespacing: 5,
      //  margin: [0, 20, 0, 20],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push(htmlContent)
      //row.push({
      //  text: '',
      //  fontSize: 12,
      //  bold: false,
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: 'FEE STRUCTURE',
      //  fontSize: 14,
      //  bold: true,
      //  alignment: 'left',
      //  color: '#00000',
      //})
      //row.push({
      //  text: '',
      //  fontSize: 12,
      //  bold: false,
      //  alignment: 'center',
      //  color: '#00000',
      //})
      row.push(html)
      row.push({
        text: 'Total payable fee is Rs.1,13,900/- (INR Rupees One Lac Thirteen Thousand Nine Hundred Only)',
        fontSize: 12,
        bold: true,
        margin: [0, 10, 0, 0],
        alignment: 'justify',
        color: '#00000',
      })
      row.push({
        text: this.forWhich,
        fontSize: 12,
        bold: false,
        margin: [0, 5, 0, 0],
        alignment: 'justify',
        color: '#00000',
      })
      row.push({
        text: 'Admin.Officer (A/Cs)',
        fontSize: 12,
        bold: true,
        margin: [0, 65, 0, 0],
        alignment: 'left',
        color: '#00000',
      })
      row.push({
        text: 'GTBIT',
        fontSize: 12,
        bold: true,
        margin: [0, 5, 0, 0],
        alignment: 'left',
        color: '#00000',
      })
      row.push({
        text: 'Print Date/Time:' + this.TodayDate + ' (' + sessionStorage.getItem("ipaddress") + ')',
        fontSize: 9,
        bold: false,
        margin: [0, 215, 0, 0],
        alignment: 'center',
        color: '#00000',
      })
      //"Print Date/Time: " + this.TodayDate + " (" + sessionStorage.getItem("ipaddress") + ")"
      let documentDefinition =
      {
        pageSize: 'A4' as PageSize,
        pageOrientation: 'potrait',
        content: [
          row,
        ],
        watermark: { text: 'GTBIT', color: 'grey', opacity: 0.3, bold: false, italics: false, fontSize: 72 },
        pageMargins: [30, 130, 30, 10],
        defaultStyle: {
          fontSize: 14
        }
      }

      this.FileName = response[0].studentName + "-" + response[0].admno + "-" + response[0].yearcode + ".pdf";

      //if (action === 'download') {
      //  pdfMake.createPdf(documentDefinition).download(this.FileName);
      //} else if (action === 'print') {
      //  pdfMake.createPdf(documentDefinition).print();
      //} else {
      pdfMake.createPdf(documentDefinition).open();
      //}
      //}
    }, (error) => {
      console.log(error);
    });
  }

  ViewReceipt2ndYearITR() {
    this.StudFeeReceiptDet = "";
    this.ForLoanScholar.ViewFeeReceipt2ndLE(this.EnrolNo, this.iYearCode).subscribe((response) => {
      this.StudFeeReceiptDet = response;
      if (response[0].gender == "M") { this.SD = "S/o" }
      if (response[0].gender == "F") { this.SD = "D/o" }
      for (var k = 0; k < this.StudFeeReceiptDet.length; k++) {
        if (this.StudFeeReceiptDet[k].headName == 'OTHER CHARGES') {
          this.CheckStrHeadSpotFee();
          break;
        }
      }
      this.CheckStrHead();

      var pdfMake = require("pdfmake/build/pdfmake");
      var pdfFonts = require("pdfmake/build/vfs_fonts");
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      var htmlToPdfmake = require("html-to-pdfmake");

      const doc = new jsPDF();
      //const pdfContent = this.pdfContent.nativeElement;
      var html;
      var htmlContent;
      let row: any[] = [];
      //let totpage = this.total1 - 1;
      html = htmlToPdfmake(this.strHead);
      htmlContent = htmlToPdfmake(this.strContent1);
      //row.push(htmlContent)
      //text: 'This is to certify that Mahinder Singh Chhabra S/o S.Gurvinder Singh Chhabra of B.Tech CSE Enrolment No.02356456256 is a bonafide student of 1st Yr. (First Year). The payable fee details for upcoming 3 years are given below:',
      row.push({
        text: 'Date:' + this.todayDate,
        fontSize: 12,
        bold: true,
        alignment: 'right',
        color: '#00000',
      })
      row.push({
        text: '',
        fontSize: 12,
        bold: false,
        alignment: 'justify',
        color: '#00000',
      })
      row.push({
        text: 'TO WHOM IT MAY CONCERN',
        fontSize: 14,
        bold: true,
        decoration: 'underline',
        alignment: 'center',
        color: '#00000',
      })
      //row.push({
      //  text: '',
      //  fontSize: 12,
      //  bold: false,
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: '',
      //  fontSize: 12,
      //  bold: false,
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //this.fatherName = response[0].fatherName;
      //if (response[0].fatherName == null) { this.fatherName = "___________________________"; }
      //row.push({
      //  text: 'This is to certify that ' + response[0].studentName + ' ' + this.SD + ' ' + this.fatherName + '  of B.Tech ' + response[0].course,
      //  fontSize: 12,
      //  bold: false,
      //  margin: [0, 20, 0, 5],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: 'Enrolment No.' + response[0].admno + ' is a bonafide student of 2nd Yr. The payable fee details',
      //  fontSize: 12,
      //  bold: false,
      //  margin: [0, 0, 0, 5],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: 'for upcoming 2 years are given below:',
      //  fontSize: 12,
      //  bold: false,
      //  margin: [0, 0, 0, 20],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: 'This is to certify that Mahinder Singh Chhabra S/o S.Gurvinder Singh Chhabra of B.Tech CSE Enrolment No.02356456256 is a bonafide student of 1st Yr. (First Year). The payable fee details for upcoming 3 years are given below:',
      //  fontSize: 12,
      //  bold: false,
      //  linespacing: 5,
      //  margin: [0, 20, 0, 20],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push(htmlContent)
      //row.push({
      //  text: '',
      //  fontSize: 12,
      //  bold: false,
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: 'FEE STRUCTURE',
      //  fontSize: 14,
      //  bold: true,
      //  alignment: 'left',
      //  color: '#00000',
      //})
      //row.push({
      //  text: '',
      //  fontSize: 12,
      //  bold: false,
      //  alignment: 'center',
      //  color: '#00000',
      //})
      row.push(html)
      row.push({
        text: 'Total payable fee is Rs.2,47,600/- (INR Rupees Two Lacs Forty Seven Thousand Six Hundred Only)',
        fontSize: 12,
        bold: true,
        margin: [0, 10, 0, 0],
        alignment: 'justify',
        color: '#00000',
      })
      row.push({
        text: this.forWhich,
        fontSize: 12,
        bold: false,
        margin: [0, 5, 0, 0],
        alignment: 'justify',
        color: '#00000',
      })
      row.push({
        text: 'Admin.Officer (A/Cs)',
        fontSize: 12,
        bold: true,
        margin: [0, 65, 0, 0],
        alignment: 'left',
        color: '#00000',
      })
      row.push({
        text: 'GTBIT',
        fontSize: 12,
        bold: true,
        margin: [0, 5, 0, 0],
        alignment: 'left',
        color: '#00000',
      })
      row.push({
        text: 'Print Date/Time:' + this.TodayDate + ' (' + sessionStorage.getItem("ipaddress") + ')',
        fontSize: 9,
        bold: false,
        margin: [0, 215, 0, 0],
        alignment: 'center',
        color: '#00000',
      })
      //"Print Date/Time: " + this.TodayDate + " (" + sessionStorage.getItem("ipaddress") + ")"
      let documentDefinition =
      {
        pageSize: 'A4' as PageSize,
        pageOrientation: 'potrait',
        content: [
          row,
        ],
        watermark: { text: 'GTBIT', color: 'grey', opacity: 0.3, bold: false, italics: false, fontSize: 72 },
        pageMargins: [30, 130, 30, 10],
        defaultStyle: {
          fontSize: 14
        }
      }

      this.FileName = response[0].studentName + "-" + response[0].admno + "-" + response[0].yearcode + ".pdf";

      //if (action === 'download') {
      //  pdfMake.createPdf(documentDefinition).download(this.FileName);
      //} else if (action === 'print') {
      //  pdfMake.createPdf(documentDefinition).print();
      //} else {
      pdfMake.createPdf(documentDefinition).open();
      //}
      //}
    }, (error) => {
      console.log(error);
    });
  }


  ViewReceipt2ndLEYearITR() {
    this.StudFeeReceiptDet = "";
    this.ForLoanScholar.ViewFeeReceipt2ndLE(this.EnrolNo, this.iYearCode).subscribe((response) => {
      this.StudFeeReceiptDet = response;
      if (response[0].gender == "M") { this.SD = "S/o" }
      if (response[0].gender == "F") { this.SD = "D/o" }
      for (var k = 0; k < this.StudFeeReceiptDet.length; k++) {
        if (this.StudFeeReceiptDet[k].headName == 'OTHER CHARGES') {
          this.CheckStrHeadSpotFee();
          break;
        }
      }
      
      this.CheckStrHead();
      var pdfMake = require("pdfmake/build/pdfmake");
      var pdfFonts = require("pdfmake/build/vfs_fonts");
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      var htmlToPdfmake = require("html-to-pdfmake");

      const doc = new jsPDF();
      //const pdfContent = this.pdfContent.nativeElement;
      var html;
      var htmlContent;
      let row: any[] = [];
      //let totpage = this.total1 - 1;
      html = htmlToPdfmake(this.strHead);
      htmlContent = htmlToPdfmake(this.strContent1);
      //row.push(htmlContent)
      //text: 'This is to certify that Mahinder Singh Chhabra S/o S.Gurvinder Singh Chhabra of B.Tech CSE Enrolment No.02356456256 is a bonafide student of 1st Yr. (First Year). The payable fee details for upcoming 3 years are given below:',
      row.push({
        text: 'Date:' + this.todayDate,
        fontSize: 12,
        bold: true,
        alignment: 'right',
        color: '#00000',
      })
      row.push({
        text: '',
        fontSize: 12,
        bold: false,
        alignment: 'justify',
        color: '#00000',
      })
      row.push({
        text: 'TO WHOM IT MAY CONCERN',
        fontSize: 14,
        bold: true,
        decoration: 'underline',
        alignment: 'center',
        color: '#00000',
      })
      //row.push({
      //  text: '',
      //  fontSize: 12,
      //  bold: false,
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: '',
      //  fontSize: 12,
      //  bold: false,
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      
      //row.push({
      //  text: 'This is to certify that ' + response[0].studentName + ' ' + this.SD + ' ' + this.fatherName + '  of B.Tech ' + response[0].course,
      //  fontSize: 12,
      //  bold: false,
      //  margin: [0, 20, 0, 5],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: 'Enrolment No.' + response[0].admno + ' is a bonafide student of 2nd Yr. (Lateral Entry). The payable fee details',
      //  fontSize: 12,
      //  bold: false,
      //  margin: [0, 0, 0, 5],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: 'for upcoming 2 years are given below:',
      //  fontSize: 12,
      //  bold: false,
      //  margin: [0, 0, 0, 20],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: 'This is to certify that Mahinder Singh Chhabra S/o S.Gurvinder Singh Chhabra of B.Tech CSE Enrolment No.02356456256 is a bonafide student of 1st Yr. (First Year). The payable fee details for upcoming 3 years are given below:',
      //  fontSize: 12,
      //  bold: false,
      //  linespacing: 5,
      //  margin: [0, 20, 0, 20],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push(htmlContent)
      //row.push({
      //  text: '',
      //  fontSize: 12,
      //  bold: false,
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: 'FEE STRUCTURE',
      //  fontSize: 14,
      //  bold: true,
      //  alignment: 'left',
      //  color: '#00000',
      //})
      //row.push({
      //  text: '',
      //  fontSize: 12,
      //  bold: false,
      //  alignment: 'center',
      //  color: '#00000',
      //})
      row.push(html)
      row.push({
        text: 'Total paid fee is Rs.1,49,100/- (INR Rupees One Lac Forty Nine Thousand One Hundred Only)',
        fontSize: 12,
        bold: true,
        margin: [0, 10, 0, 0],
        alignment: 'justify',
        color: '#00000',
      })
      row.push({
        text: this.forWhich,
        fontSize: 12,
        bold: false,
        margin: [0, 5, 0, 0],
        alignment: 'justify',
        color: '#00000',
      })
      row.push({
        text: 'Admin.Officer (A/Cs)',
        fontSize: 12,
        bold: true,
        margin: [0, 65, 0, 0],
        alignment: 'left',
        color: '#00000',
      })
      row.push({
        text: 'GTBIT',
        fontSize: 12,
        bold: true,
        margin: [0, 5, 0, 0],
        alignment: 'left',
        color: '#00000',
      })
      row.push({
        text: 'Print Date/Time:' + this.TodayDate + ' (' + sessionStorage.getItem("ipaddress") + ')',
        fontSize: 9,
        bold: false,
        margin: [0, 95, 0, 0],
        alignment: 'center',
        color: '#00000',
      })
      //"Print Date/Time: " + this.TodayDate + " (" + sessionStorage.getItem("ipaddress") + ")"
      let documentDefinition =
      {
        pageSize: 'A4' as PageSize,
        pageOrientation: 'potrait',
        content: [
          row,
        ],
        watermark: { text: 'GTBIT', color: 'grey', opacity: 0.3, bold: false, italics: false, fontSize: 72 },
        pageMargins: [30, 130, 30, 10],
        defaultStyle: {
          fontSize: 14
        }
      }

      this.FileName = response[0].studentName + "-" + response[0].admno + "-" + response[0].yearcode + ".pdf";

      //if (action === 'download') {
      //  pdfMake.createPdf(documentDefinition).download(this.FileName);
      //} else if (action === 'print') {
      //  pdfMake.createPdf(documentDefinition).print();
      //} else {
      pdfMake.createPdf(documentDefinition).open();
      //}
      //}
    }, (error) => {
      console.log(error);
    });
  }

  ViewReceiptIstYearITR() {
    this.StudFeeReceiptDet = "";
    this.ForLoanScholar.ViewFeeReceipt(this.EnrolNo, this.iBankNo,this.iYearCode).subscribe((response) => {
      this.StudFeeReceiptDet = response;
      if (response[0].gender == "M") { this.SD = "S/o" }
      if (response[0].gender == "F") { this.SD = "D/o" }
      var pdfMake = require("pdfmake/build/pdfmake");
      var pdfFonts = require("pdfmake/build/vfs_fonts");
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      var htmlToPdfmake = require("html-to-pdfmake");
      this.CheckStrHead();
      const doc = new jsPDF();
      //const pdfContent = this.pdfContent.nativeElement;
      var html;
      var htmlContent;
      let row: any[] = [];
      //let totpage = this.total1 - 1;
      html = htmlToPdfmake(this.strHead);
      htmlContent = htmlToPdfmake(this.strContent1);
      //row.push(htmlContent)
      //text: 'This is to certify that Mahinder Singh Chhabra S/o S.Gurvinder Singh Chhabra of B.Tech CSE Enrolment No.02356456256 is a bonafide student of 1st Yr. (First Year). The payable fee details for upcoming 3 years are given below:',
      row.push({
        text: 'Date:' + this.todayDate,
        fontSize: 12,
        bold: true,
        alignment: 'right',
        color: '#00000',
      })
      row.push({
        text: '',
        fontSize: 12,
        bold: false,
        alignment: 'justify',
        color: '#00000',
      })
      row.push({
        text: 'TO WHOM IT MAY CONCERN',
        fontSize: 14,
        bold: true,
        decoration: 'underline',
        alignment: 'center',
        color: '#00000',
      })
      row.push({
        text: '',
        fontSize: 12,
        bold: false,
        alignment: 'justify',
        color: '#00000',
      })
      row.push({
        text: '',
        fontSize: 12,
        bold: false,
        alignment: 'justify',
        color: '#00000',
      })
      //this.fatherName = response[0].fatherName;
      //if (response[0].fatherName == null) { this.fatherName = "___________________________"; }
      //row.push({
      //  text: 'This is to certify that ' + response[0].studentName + ' ' + this.SD + ' ' + this.fatherName + '  of B.Tech ' + response[0].course,
      //  fontSize: 12,
      //  bold: false,
      //  margin: [0, 20, 0, 5],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: 'Enrolment No.' + response[0].admno + ' is a bonafide student of 1st Yr. (First Year). The payable fee details',
      //  fontSize: 12,
      //  bold: false,
      //  margin: [0, 0, 0, 5],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: 'for upcoming 3 years are given below:',
      //  fontSize: 12,
      //  bold: false,
      //  margin: [0, 0, 0, 20],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push({
      //  text: 'This is to certify that Mahinder Singh Chhabra S/o S.Gurvinder Singh Chhabra of B.Tech CSE Enrolment No.02356456256 is a bonafide student of 1st Yr. (First Year). The payable fee details for upcoming 3 years are given below:',
      //  fontSize: 12,
      //  bold: false,
      //  linespacing: 5,
      //  margin: [0, 20, 0, 20],
      //  alignment: 'justify',
      //  color: '#00000',
      //})
      //row.push(htmlContent)
      row.push({
        text: '',
        fontSize: 12,
        bold: false,
        alignment: 'justify',
        color: '#00000',
      })
      //row.push({
      //  text: 'FEE STRUCTURE',
      //  fontSize: 14,
      //  bold: true,
      //  alignment: 'left',
      //  color: '#00000',
      //})
      //row.push({
      //  text: '',
      //  fontSize: 14,
      //  bold: false,
      //  alignment: 'center',
      //  color: '#00000',
      //})
      row.push(html)
      row.push({
        text: 'Total paid fee is Rs.1,49,100/- (INR Rupees One Lac Forty Nine Thousand One Hundred Only)',
        fontSize: 12,
        bold: true,
        margin: [0, 10, 0, 0],
        alignment: 'justify',
        color: '#00000',
      })
      row.push({
        text: this.forWhich,
        fontSize: 12,
        bold: false,
        margin: [0, 5, 0, 0],
        alignment: 'justify',
        color: '#00000',
      })
      row.push({
        text: 'Admin.Officer (A/Cs)',
        fontSize: 12,
        bold: true,
        margin: [0, 65, 0, 0],
        alignment: 'left',
        color: '#00000',
      })
      row.push({
        text: 'GTBIT',
        fontSize: 12,
        bold: true,
        margin: [0, 5, 0, 0],
        alignment: 'left',
        color: '#00000',
      })
      row.push({
        text: 'Print Date/Time:' + this.TodayDate + ' (' + sessionStorage.getItem("ipaddress") + ')',
        fontSize: 9,
        bold: false,
        margin: [0, 95, 0, 0],
        alignment: 'center',
        color: '#00000',
      })
      //"Print Date/Time: " + this.TodayDate + " (" + sessionStorage.getItem("ipaddress") + ")"
      let documentDefinition =
      {
        pageSize: 'A4' as PageSize,
        pageOrientation: 'potrait',
        content: [
          row,
        ],
        watermark: { text: 'GTBIT', color: 'grey', opacity: 0.3, bold: false, italics: false, fontSize: 72 },
        pageMargins: [30, 130, 30, 10],
        defaultStyle: {
          fontSize: 14
        }
      }

      this.FileName = response[0].studentName + "-" + response[0].admno + "-" + response[0].yearcode + ".pdf";

      //if (action === 'download') {
      //  pdfMake.createPdf(documentDefinition).download(this.FileName);
      //} else if (action === 'print') {
      //  pdfMake.createPdf(documentDefinition).print();
      //} else {
        pdfMake.createPdf(documentDefinition).open();
      //}
    //}
    }, (error) => {
      console.log(error);
    });    
    }
}

