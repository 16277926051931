<h3>Student Basic Detail ({{GLBappFormStarEnd}})</h3>
<div class="container">
  <div *ngIf="loading!=''" class="cssload-container">
    <div class="cssload-speeding-wheel"></div>
  </div>
  <div *ngIf="loading!=''" class='fullscreenDiv'>
    <div class="center">Loading...Please Wait</div>
  </div>
  <mat-form-field appearance="standard">
    <!--<mat-label>Search</mat-label>-->
    <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
  </mat-form-field>
  <mat-radio-group [(ngModel)]="selectedRow" (change)="radioSelected()">
    <mat-radio-button [value]="1">Morning</mat-radio-button>
    <mat-radio-button [value]="2">Evening</mat-radio-button>
  </mat-radio-group>
  <br /><b>Select Course</b>
  <ng-multiselect-dropdown [placeholder]="'Courses'"
                           [settings]="dropdownSettings"
                           [data]="Courses"
                           [(ngModel)]="selectedItems"
                           (change)="onItemSelect($event)"
                           (onSelect)="onItemSelect($event)"
                           (onSelectAll)="onSelectAll($event)"
                           (onDeSelect)="onDeSelect($event)">
  </ng-multiselect-dropdown>
  <div class="export-container">
    <button mat-raised-button color="primary" (click)="downloadPdf()">Export-Open (Pdf)</button>
    <!--<button mat-raised-button color="primary" (click)="createPdf()">Export 1</button>
    <button mat-raised-button color="primary" (click)="createPdfN()">Export 2</button>
    <button mat-raised-button color="primary" (click)="downloadPdf()">Download</button>-->
  </div>
    <div class="mat-elevation-z8">
      <div class="cf" id="pdfTable" #pdfTable>
        <table id="ExampleTable" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> SNo.</th>
            <td mat-cell *matCellDef="let element"> {{element.sno}} </td>
            <!--<td mat-cell *matCellDef="let row; let element" [ngClass]="{'whitetext': row.detail == 'C/Bal' || row.rNo!=1,'boldtext': row.rNo==1 }"> {{element.admno}}  </td>-->
            co
          </ng-container>
          <ng-container matColumnDef="admno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Enrolment# </th>
            <td mat-cell *matCellDef="let element"> {{element.admno}} </td>
            <!--<td mat-cell *matCellDef="let row; let element" [ngClass]="{'whitetext': row.detail == 'C/Bal' || row.rNo!=1,'boldtext': row.rNo==1 }"> {{element.admno}}  </td>-->
            co
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <!--<td mat-cell *matCellDef="let row; let element" [ngClass]="{'whitetext': row.detail == 'C/Bal' || row.rNo!=1,'boldtext': row.rNo==1 }"> {{element.name}}  </td>-->
            m
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
          <ng-container matColumnDef="course">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Course </th>
            <td mat-cell *matCellDef="let element"> {{element.course}} </td>
          </ng-container>
          <ng-container matColumnDef="father">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Father Name </th>
            <td mat-cell *matCellDef="let element"> {{element.father}} </td>
          </ng-container>
          <ng-container matColumnDef="mobile1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile# </th>
            <td mat-cell *matCellDef="let element"> {{element.mobile1}} </td>
          </ng-container>
          <ng-container matColumnDef="femail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.femail}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <mat-paginator [pageSizeOptions]="[50, 100, 150, 200, 250, 300, 350]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
