import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { LogOutUserService } from './logout.service';
import { Pipe } from '@angular/core';
import { formatDate, DatePipe } from '@angular/common';
import { Router } from '@angular/router';


@Component({
  selector: 'logoutuser',
  templateUrl: './logout.component.html', 
})

export class LogOutUserComponent implements OnInit {
  pipe = new DatePipe('en-US');
  now = Date.now();
  todayDate: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  UserForm: any;
  UserName: string = "ksingh";
  UserPassword: string = "abc123";
  Otpverified: number = 123456;  
  constructor(private LoginService: LogOutUserService, private router: Router) {
    sessionStorage.setItem('firsttime', "0");
  }
  ngOnInit(): void {
    sessionStorage.setItem('firsttime', "0");
    //debugger;
    this.LoginService.LogOutCheck(parseInt(sessionStorage.getItem('usercode')), sessionStorage.getItem('ipaddress').toString()).subscribe((response: any) => {
      
    });    
    sessionStorage.setItem('usercode', '0');
    sessionStorage.setItem('uname', '');
    sessionStorage.setItem('currentdate', '');    
    sessionStorage.setItem('instname', '');
    sessionStorage.setItem('formname', '');    
    sessionStorage.setItem('YCode', '0');
    sessionStorage.setItem('YearCode', '0');
    sessionStorage.setItem('ClientId', '0');
    sessionStorage.setItem('iShiftId', '0');
    sessionStorage.setItem('ShiftStr', '');
    sessionStorage.clear();    
    this.router.navigate(['/loginuser'])
      .then(() => {
        window.location.reload();
      });
    return;
  }

  //onFormSubmit() {
  //  debugger;
  //  sessionStorage.setItem('usercode', '0');
  //  sessionStorage.setItem('uname', '');
  //  const studlogin = this.UserForm.value;
  //  studlogin.UserName = this.UserName;
  //  studlogin.UserPassword = this.UserPassword;
  //  studlogin.Otpverified = this.Otpverified;
  //  this.LoginService.LoginCheck(this.UserName, this.UserPassword, this.Otpverified).subscribe((response: any) => {
  //    debugger;
  //    console.log(response);
  //    if (response.fullname != "") {
  //      sessionStorage.setItem('usercode', response[0].userId);
  //      sessionStorage.setItem('uname', response[0].fullname);
  //      sessionStorage.setItem('currentdate', this.todayDate);
  //      //sessionStorage.setItem('uname', 'R Singh');
  //      sessionStorage.setItem('instname', 'Guru Tegh Bahadur Institute of Technology');
  //      sessionStorage.setItem('formname', 'Student Master');
  //      //sessionStorage.setItem('ipaddress', '0.0.0.0');
  //      //sessionStorage.setItem('usercode', '1');
  //      sessionStorage.setItem('YCode', '14');
  //      sessionStorage.setItem('YearCode', '2023');
  //      sessionStorage.setItem('ClientId', '1');
  //      sessionStorage.setItem('iShiftId', '1');
  //      sessionStorage.setItem('ShiftStr', 'Morning');
  //      //window.alert("Login Success");
  //      this.router.navigate(['/material-dashboard'])
  //        .then(() => {
  //          window.location.reload();
  //        });
  //    } else {
  //      window.alert("Login Failed");
  //    }
  //  }, (error) => {
  //    window.alert("Login Failed");
  //    console.log(error);
  //  });    
  //}

}
