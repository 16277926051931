<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<meta charset="utf-8" />
<meta name="viewport" content="width=device-width,initial-scale=1.0" />
<script type="text/javascript">
  const input = document.querySelector('input');
  input.valueAsDate = new Date();
</script>
<script src="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.all.min.js"></script>
<link href="https://cdn.jsdelivr.net/npm/sweetalert2@11.10.6/dist/sweetalert2.min.css" rel="stylesheet" />
<head>
  <style>
    table, th, td {
      border: 1px solid grey;
      border-collapse: collapse;
      padding: 5px;
    }

      table tr:last-child {
        font-weight: bold;
      }

    table {
      table-layout: auto;
      width: auto !important;
      font-size: small;
    }
  </style>
  <style type="text/css">
    /*html, body {
      background: #e5e5e5;
      text-align: center;
    }*/

    /* start basic spinner styles*/

    div.spinner {
      position: relative;
      width: 100px;
      height: 100px;
      display: inline-block;
    }

      div.spinner div {
        width: 12%;
        height: 26%;
        background: #000;
        position: absolute;
        left: 44.5%;
        top: 37%;
        opacity: 0;
        color: #00cc00;
        -webkit-animation: fade 1s linear infinite;
        -webkit-border-radius: 50px;
        -webkit-box-shadow: 0 0 3px rgba(0,0,0,0.2);
      }

        div.spinner div.bar1 {
          -webkit-transform: rotate(0deg) translate(0, -142%);
          -webkit-animation-delay: 0s;
        }

        div.spinner div.bar2 {
          -webkit-transform: rotate(30deg) translate(0, -142%);
          -webkit-animation-delay: -0.9167s;
        }

        div.spinner div.bar3 {
          -webkit-transform: rotate(60deg) translate(0, -142%);
          -webkit-animation-delay: -0.833s;
        }

        div.spinner div.bar4 {
          -webkit-transform: rotate(90deg) translate(0, -142%);
          -webkit-animation-delay: -0.75s;
        }

        div.spinner div.bar5 {
          -webkit-transform: rotate(120deg) translate(0, -142%);
          -webkit-animation-delay: -0.667s;
        }

        div.spinner div.bar6 {
          -webkit-transform: rotate(150deg) translate(0, -142%);
          -webkit-animation-delay: -0.5833s;
        }

        div.spinner div.bar7 {
          -webkit-transform: rotate(180deg) translate(0, -142%);
          -webkit-animation-delay: -0.5s;
        }

        div.spinner div.bar8 {
          -webkit-transform: rotate(210deg) translate(0, -142%);
          -webkit-animation-delay: -0.41667s;
        }

        div.spinner div.bar9 {
          -webkit-transform: rotate(240deg) translate(0, -142%);
          -webkit-animation-delay: -0.333s;
        }

        div.spinner div.bar10 {
          -webkit-transform: rotate(270deg) translate(0, -142%);
          -webkit-animation-delay: -0.25s;
        }

        div.spinner div.bar11 {
          -webkit-transform: rotate(300deg) translate(0, -142%);
          -webkit-animation-delay: -0.1667s;
        }

        div.spinner div.bar12 {
          -webkit-transform: rotate(330deg) translate(0, -142%);
          -webkit-animation-delay: -0.0833s;
        }

    @-webkit-keyframes fade {
      from {
        opacity: 1;
      }

      to {
        opacity: 0.25;
      }
    }

    /* end basic spinner styles*/

    /*div.container {
      display: inline-block;
      padding: 1.5em 1.5em 1.25em;
      background: rgba(0,0,0,0.8);
      -webkit-box-shadow: 1px 1px 1px #fff;
      -webkit-border-radius: 1em;
      margin: 1em;
    }*/

    /*div.container.grey {
        background: rgba(0,0,0,0.2);
      }*/

    div.container.grey.blue {
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #0a2744), color-stop(1, #064483));
    }

    div.container div.spinner {
      width: 28px;
      height: 28px;
    }

    div.container.grey div.spinner {
      width: 60px;
      height: 60px;
    }

    div.container div.spinner div {
      background: #fff;
    }

    div.labeled {
      font-family: sans-serif;
      font-size: 14px;
      margin: 0 auto;
      background: #fff;
      padding: 0.5em 0.75em 0.5em 0.5em;
      display: inline-block;
      color: #c00;
      line-height: 25px;
      -webkit-box-shadow: 0 0 3px rgba(0,0,0,0.4);
      -webkit-border-radius: 1em;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(1, #ccc));
    }

      div.labeled div.spinner {
        float: left;
        vertical-align: middle;
        width: 25px;
        height: 25px;
        margin-right: 0.5em;
      }

        div.labeled div.spinner div {
          background: #c00;
        }
  </style>
  <!--<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.2.0/css/bootstrap.min.css" />
  <link rel="stylesheet" href="https://cdn.datatables.net/1.13.4/css/dataTables.bootstrap5.min.css" />-->
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@3.0.0-alpha2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp" crossorigin="anonymous" />
</head>
<body>
  <div class="container">
    <!--<div class="twitter-like">
      <mat-spinner class="background" mode="determinate" value="100"></mat-spinner>      
    </div>-->
    <div id="pdfContent" #pdfContent>
      <h3>Fee Collection Report Date Wise ({{ShiftStr}}) A/Y {{GLBappFormStarEnd}}</h3><br />
      <mat-radio-group [(ngModel)]="selectedRow" (change)="radioSelected()">
        <mat-radio-button [value]="1">Without Zero</mat-radio-button>
        <mat-radio-button [value]="2">With Zero(for Deletion)</mat-radio-button>
      </mat-radio-group>
      <input type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search..." />
      <input type="date" [(ngModel)]="todayDateAll" class="form-control" style="font-weight: bold;width:fit-content" />
      <button style="background-color: red; color: white; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;" tabindex="3" type="submit" name="deletefee" (click)="ChangeDateUpdateAll(todayDateAll,2024)">Update Date (for All)</button>
      <br />
      <!--<img src="https://www.freepnglogos.com/uploads/peacock-png/peacock-png-design-deviantart-2.png">-->
      <table id="data" class="table table-bordered" cellspacing="0" style="width:100%">
        <tr>
          <th> S.No</th>
          <th> Name</th>
          <th> Enrolment No./Period/Date</th>
          <th> Branch</th>
          <th> Tuition Fees</th>
          <th> Student Activity Fees</th>
          <th> University Charges</th>
          <th> Book Bank</th>
          <th> Caution Money</th>
          <th> Re-Check</th>
          <th> Re-Appear</th>
          <th> Inurance ICard</th>
          <th> Other Charges</th>
          <th> Grand Total</th>
          <th> Banker's Name'</th>
          <th> Mode of Payment</th>
          <th> Remarks</th>
          <th> Action</th>
          <th> Update New Date</th>
        </tr>
        <tr *ngFor="let feedet of feedetails | filter:searchText | paginate: { itemsPerPage: 16, currentPage: p, totalItems: total }; let i=index">
          <td>{{feedet.fee11}}</td>
          <td> {{ feedet.studentname }}</td>
          <td>{{ feedet.trnno }}<br />{{ feedet.trndt | date:'dd-MM-yyyy'}}/{{feedet.bankno}}</td>
          <td width="50%">{{ feedet.csdesc }}</td>
          <td style="text-align:right">{{ feedet.tuitFee }}</td>
          <td>{{ feedet.stdActFee }}</td>
          <td>{{ feedet.univCharges }}</td>
          <td>{{ feedet.bookBank }}</td>
          <td>{{ feedet.cauMon }}</td>
          <td>{{ feedet.reChk }}</td>
          <td>{{ feedet.reApp }}</td>
          <td>{{ feedet.insuIcard }}</td>
          <td>{{ feedet.othChrg }}</td>
          <td>{{ feedet.grandtotal }}</td>
          <td>{{ feedet.drawnAt }}</td>
          <td *ngIf="feedet.fee110 == 1">{{ feedet.cashchqddno }} /SBI</td>
          <td *ngIf="feedet.fee110 == 2">{{ feedet.cashchqddno }} /PNB</td>
          <td>{{ feedet.remarks }}</td>
          <td *ngIf="feedet.bankno != NULL"><button style="background-color: red; color: white; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;" tabindex="3" type="submit" name="deletefee" (click)="DeleteStudentFeeTran(feedet.bankno,feedet.yearcode,feedet.trnno)">Delete</button></td>
          <td *ngIf="feedet.bankno != NULL"><input type="date" [(ngModel)]="todayDate[i]" class="form-control" style="font-weight: bold;" /><button style="background-color: red; color: white; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;" tabindex="3" type="submit" name="deletefee" (click)="ChangeDateUpdate(feedet.trnno,feedet.bankno,todayDate[i],feedet.yearcode)">Update Date</button></td>
        </tr>
      </table>
    </div>
    <div class="col-md-4">
      <button type="button" class="btn btn-secondary btn-lg btn-block"
              (click)="generatePDF('download')">
        Download
      </button>
      <button type="button" class="btn btn-secondary btn-lg btn-block"
              (click)="generatePDF('print')">
        Print
      </button>
      <button type="submit" class="btn btn-secondary btn-lg btn-block"
              (click)="generatePDF('open')">
        Open
      </button>
    </div>
    <div *ngIf="PleaseInt ==1" class="labeled">
      <div class="spinner">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
        <div class="bar4"></div>
        <div class="bar5"></div>
        <div class="bar6"></div>
        <div class="bar7"></div>
        <div class="bar8"></div>
        <div class="bar9"></div>
        <div class="bar10"></div>
        <div class="bar11"></div>
        <div class="bar12"></div>
      </div>
      {{ pleaseWait }}
    </div>

    <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
    <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js" integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV" crossorigin="anonymous"></script>
  </div>
</body>
</html>
