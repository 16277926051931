//04176803121
import { Component, OnInit } from '@angular/core';
import { PdcTransDispService } from '../pdctransdisp/pdctransdisp.service'
import { formatDate, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Pipe } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { isEmpty } from 'rxjs';
import { Clipboard } from "@angular/cdk/clipboard";

@Component({
  selector: 'pdctransdisp',
  templateUrl: './pdctransdisp.component.html',
})

export class PdcTransDispComponent implements OnInit {
  pipe = new DatePipe('en-US');
  now = Date.now();
  PDCTranDet: any;
  haveRec: number;
  haveRec2: number;
  GLBappFormStarEnd: string = "";
  //noofdays: number = 0;
  //noofinstallments: number = 0;
  //ArrearTotal: Number;
  //FeesTotal: Number;
  todayDate: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  IPAddress: string;
  IncreVal: number;
  TodaysDate: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  TodaysDate2: any = this.pipe.transform(this.now, 'dd/MM/yyyy');
  constructor(public clipboard: Clipboard, private tranService: PdcTransDispService, private fb: FormBuilder, private router: Router) {
    debugger;
    this.GLBappFormStarEnd = sessionStorage.getItem('FYYear');
    this.todayDate = this.pipe.transform(sessionStorage.getItem('currentdate'), 'yyyy-MM-dd');        
    this.displayStyle = "none";
    this.IPAddress = sessionStorage.getItem('ipaddress');
    this.IncreVal = 0;    
    this.haveRec = 0;
    if (this.IncreVal <= Number(sessionStorage.getItem('incrementDisplay'))) { this.IncreVal = Number(sessionStorage.getItem('incrementDisplay')); }
    if (this.IncreVal <= 100) {
      this.displayStyle = "block";
      this.tranService.ViewPDCTranDet(this.TodaysDate, parseInt(sessionStorage.getItem('YearCode'))).subscribe((PDCretval: any) => {
        this.PDCTranDet = PDCretval;
        this.haveRec = 1;
      }, (error) => {
        console.log(error);
      });
    }
    this.haveRec2 = 2;
  }

  
  displayStyle = "block";

  openPopup() {
    if (sessionStorage.getItem('incrementDisplay') == "1") { this.displayStyle = "block" };
    sessionStorage.setItem('incrementDisplay', '2');
  }
  closePopup() {
    this.displayStyle = "none";
    this.router.navigate(['/transfees'])
      .then(() => {
        window.location.reload();
      });
  }   
    
  ngOnInit() {
    //if (this.IncreVal <= 100) {
    //  this.displayStyle = "block";
    //  this.tranService.ViewPDCTranDet(this.TodaysDate, parseInt(sessionStorage.getItem('YearCode'))).subscribe((PDCretval: any) => {
    //    this.PDCTranDet = PDCretval;
    //    this.haveRec = 1;
    //  }, (error) => {
    //    console.log(error);
    //  });
    //}
  }

  ngAfterContentInit() {
    debugger;
    this.IncreVal = this.IncreVal + 1;    
    sessionStorage.setItem('incrementDisplay', this.IncreVal.toString());
    if (Number(sessionStorage.getItem('yesPDChave')) == 0) {
      this.displayStyle = "none";
      this.router.navigate(['/transfees'])
        .then(() => {
          window.location.reload();
        });
      }    
  }
}
