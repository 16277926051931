import { Component, OnInit, ViewChild } from '@angular/core';
import { StudentMasterService } from '../studentmaster/studentmaster.service';
import { formatDate, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Pipe } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { exit } from 'process';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'studentmaster',
  templateUrl: `studentmaster.component.html`,  
})

export class StudentMasterComponent implements OnInit {
  @ViewChild('noofinstallments') inputName;
  pipe = new DatePipe('en-US');
  now = Date.now();
  admno: any;
  GLBappFormStarEnd: string = "";
  StudentShift: any;
  todayDate: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  Admdate: any;
  Efdate: any;
  Student: any = [];
  StudentDD: any;
  Fathermail: string = "";
  Mobile2: string = "";
  Mobile1: string = "";
  Father: string = "";
  Mother: string = "";
  SName: string = "";
  RollNo: string = "";
  
  public Courses: any;
  Category: any[];
  CheckBool: boolean;
  checkedM: string;
  checkedF: string;
  checkedO: string;
  checkedNew: string;
  checkedShiftM: string;
  leftOn: string;
  checkedShiftE: string;
  checkedOld: string;
  UserForm: FormGroup;
  STUDADMNO: string = "";
  GLBappFormType: string = "";
  GLBappUserCode: string = "";
  UserName: any;
  date: string = '';
  GLBappFormName: string = "";
  isChecked: boolean = false;
  ByGender: string = '';
  ByShift: number = 0;
  ByType: string = '';
  IPAddress: string = "";
  constructor(private studService: StudentMasterService, private fb: FormBuilder, private router: Router) {  
    this.IPAddress = sessionStorage.getItem('ipaddress');
    this.Courses = "";
    this.leftOn = "";
    this.GLBappFormName = "Student Master";
    this.ByGender = 'M';
    this.ByType = 'F';
    this.StudentShift = "";
  }

  onClickedGender(event: any) {
    debugger;
    this.ByGender = 'M';
    console.log(event);
    switch (event) {
      case 'female':
        {
          this.ByGender = 'F';
          break;
        }
      case 'other':
        {
          this.ByGender = 'O';
          break;
        }      
      default:
        //for male
        this.ByGender = 'M';
        break;
    }
  }

  onClickedType(event: any) {
    debugger;
    this.ByType = 'F';
    switch (event) {
      case 'old':
        {
          this.ByType = 'R';
          break;
        }      
      default:
        //for new
        this.ByType = 'F';
        break;
    }
  }



  ngOnInit() {
    //debugger;
    this.GLBappFormStarEnd = sessionStorage.getItem('FYYear');
    if (parseInt(sessionStorage.getItem('usercode')) == 0 || sessionStorage.getItem('usercode') == null) {
      this.router.navigate(['/logoutuser'])
        .then(() => {
          window.location.reload();
        });
      return;
    }
    this.ByGender = 'M';
    this.ByType = 'F';
    this.UserForm = new FormGroup({
      //AId: new FormControl(null, Validators.required),
      SName: new FormControl(null, Validators.required),
      Admdate: new FormControl(null, Validators.required),
      Efadmdate: new FormControl(null, Validators.required),      
      Admno: new FormControl(null, Validators.required),
      RollNo: new FormControl(null, Validators.required),
      Cscode: new FormControl(null, Validators.required),
      Feemode: new FormControl(null, Validators.required),
      Instmode: new FormControl(null, Validators.required),
      StudTypes: new FormControl(null, Validators.required),
      Sex: new FormControl(null, Validators.required),
      Catcode: new FormControl(null, Validators.required),
      Father: new FormControl(null, Validators.required),
      Mother: new FormControl(null, Validators.required),
      Mobile1: new FormControl(null, Validators.required),
      Mobile2: new FormControl(null, Validators.required),
      iShiftId: new FormControl(null, Validators.required),
      IUserCode: new FormControl(null, Validators.required),
      Ycode: new FormControl(null, Validators.required),
      YearCode: new FormControl(null, Validators.required),
      ClientId: new FormControl(null, Validators.required),
    });
    this.studService.GetCourses().subscribe((courses: any) => {
      this.Courses = courses;
    }, (error) => {
      console.log(error);
    });
    this.studService.GetCategory().subscribe((category) => {
      this.Category = category;
    }, (error) => {
      console.log(error);
    });
  }

  onTypesChange(value) {
    this.ByType = value;
    //console.log(" Value is : ", value);
  }

  onGenderChange(value) {
    this.ByGender = value;
    //console.log(" Value is : ", value);
  }

  onShiftChange(value) {
    this.ByShift = value;
    //console.log(" Value is : ", value);
  }


  onChange(event: any) {
    debugger;
    this.StudentShift = "";
    this.ByType = '';
    this.ByGender = '';
    this.ByShift = 0;
    this.Student = "";
    this.leftOn = "";
    this.STUDADMNO = event.target.value;
    //this.todayDate = event.target.value;
    //console.log(this.STUDADMNO);
    this.checkedM = "";
    this.checkedF = "";
    this.checkedO = "";
    this.checkedNew = "";
    this.checkedOld = "";
    this.checkedShiftM = "";
    this.checkedShiftE = "";
    if (this.STUDADMNO.length != 0) {
      this.studService.GetStudentDET(this.STUDADMNO, parseInt(sessionStorage.getItem('YearCode')), parseInt(sessionStorage.getItem('iShiftId'))).subscribe((Student) => {        
        this.Student = Student;
        this.ByGender = this.Student.sex;
        //console.log(this.Student.sex);
        this.ByShift = this.Student.shiftId;
        this.ByType = this.Student.studType;
        this.Admdate = this.pipe.transform(this.Student.admdate, 'yyyy-MM-dd');
        this.Efdate = this.pipe.transform(this.Student.efadmdate, 'yyyy-MM-dd');
        if (Number(this.Student.shiftId) == 1) {
          this.StudentShift = "Morning";

        } else {
          this.StudentShift = "Evening";
        }
        if (Number(this.Student.tcFlagN) == 1)
        {
          this.leftOn = "(Left On: " + this.pipe.transform(this.Student.leftDate, 'dd-MM-yyyy') + ")";
        }
        if (this.Student.sex == 'M') {
          this.ByGender = 'M';
          this.checkedM = "checked";
          this.checkedF = "";
        } else if (this.Student.sex == 'F') {
          this.ByGender = 'F';
          this.checkedM = "";
          this.checkedF = "checked";
        } else {
          this.ByGender = 'O';
          this.checkedM = "";
          this.checkedF = "";
          this.checkedO = "checked";
        }
        if (Number(this.Student.shiftId) == 1) {
          this.checkedShiftM = "checked";
          this.checkedShiftE = "";
        } else {
          this.checkedShiftM = "";
          this.checkedShiftE = "checked";
        }
        if (this.Student.studType == 'F') {          
          this.checkedNew = "checked";
          this.checkedOld = "";
        } else {
          this.checkedNew = "";
          this.checkedOld = "checked";
        }
      }, (error) => {
        console.log(error);
      });
    }
  }


  onFormSubmit() {
    //Admno: 431915
    debugger;
    if (parseInt(sessionStorage.getItem('YesTran')) == 9) {
      Swal.fire({
        html: "<strong>Do some Action? Please change to current F/Year.</strong>",
        icon: 'info',
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
          //this.router.navigate(['/createdues'])
          //  .then(() => {
          //    window.location.reload();
          //  });
          //this.ngOnInit();
        }
      })
      return false;
    }
    this.CheckBool = true;
    if (this.ByType == '')
    {
      //window.alert("Please select Student Type New/Old option.");
      Swal.fire({
        html: "<strong>Please select Student Type New/Old option.</strong>",
        icon: 'info',
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'        
      }).then((result) => {
        if (result.value) {
          
        }
      })

      this.CheckBool = false;
    }
    if (this.ByGender == '') {
      //window.alert("Please select Student Gender option.");
      Swal.fire({
        html: "<strong>Please select Student Gender option.</strong>",
        icon: 'info',
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
        }
      })
      this.CheckBool = false;
    }
    if (this.CheckBool == true) {
      const studfee = this.UserForm.value;
      studfee.Feemode = 'Y';
      //studfee.Sname = this.SName;
      //studfee.Father = this.Father;
      //studfee.Mother = this.Mother;
      //studfee.Mobile1 = this.Mobile1;
      //studfee.Mobile2 = this.Mobile2;
      //studfee.Fathermail = this.Fathermail;
      studfee.Instmode = 'Y1';
      studfee.Catcode = 'AAAAAAAAAAAA';
      studfee.IUserCode = sessionStorage.getItem('usercode');
      studfee.Ycode = sessionStorage.getItem('YCode');
      studfee.YearCode = sessionStorage.getItem('YearCode');
      studfee.ClientId = sessionStorage.getItem('ClientId');
      studfee.iShiftId = this.ByShift;
      if (this.ByGender == 'M') {
        studfee.Sex = 'M';
      } else if (this.ByGender == 'F') {
        studfee.Sex = 'F';
      } else {
        studfee.Sex = 'O';
      }
      
      this.studService.AddStudentMaster(studfee).subscribe(() => {        
        //window.alert('Submitted successfully');
        Swal.fire({
          html: "<strong>Submitted successfully</strong>",
          icon: 'success',
          showConfirmButton: true,
          confirmButtonColor: "#338921",
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['/studentmaster'])
              .then(() => {
                window.location.reload();
              }); 
          }
        })
      }, (error) => {
        console.log(error);
      });
    }
  }

  public SetGetNullValueStr(parametername: string) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return "NULL";
    }
  }

  public SetGetNullValueInt(parametername: any) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return Number(0);
    }
  }

  public SetGetNullValueDate(parametername: any) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return '1900-01-01';
    }
  }
   
  DeleteEmployee(data) {
    if (window.confirm('Are you sure, you want to Delete?')) {
     
    }
  }
}
